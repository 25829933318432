import { render, staticRenderFns } from "./tempMealType.vue?vue&type=template&id=32e73c71&scoped=true"
import script from "./tempMealType.vue?vue&type=script&lang=js"
export * from "./tempMealType.vue?vue&type=script&lang=js"
import style0 from "./tempMealType.vue?vue&type=style&index=0&id=32e73c71&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e73c71",
  null
  
)

export default component.exports