<template>
  <div>
    <div class="page-sub-title flex flex-center">
      <span class="flex-1">客户标签初始化</span>
      <div class="flex flex-align-center">
        <el-input
          class="mr-15"
          v-model="searchKey"
          placeholder="请输入标签名称搜索"
          clearable
          size="small"
        >
        </el-input>
        <el-button size="small" type="primary" @click="search">查询</el-button>
      </div>
    </div>
    <div class="flex">
      <div class="left-box">
        <el-collapse v-model="activeCollapse">
          <el-collapse-item name="1">
            <template slot="title">
              <div class="flex flex-between flex-align-center ml-20 pr-10 w100">
                <span>标签组名称</span>
                <span class="add" @click.stop="addGroup">+</span>
              </div>
            </template>
            <transition-group>
              <div
                class="l1-list"
                :class="{ current: chooseGroup.id == item.id }"
                v-for="item in startGroupList"
                :data-id="item.id"
                :key="item.id"
                @click="getTagsList(item)"
              >
                <div
                  class="l1-list-item flex flex-center"
                  :style="randerStyle(item)"
                >
                  <span class="nowrap flex-1"
                    >{{ item.customerLabelGroupName }} ·
                    {{ item.customerLabelCount }}</span
                  >
                  <el-dropdown
                    @command="handleCommand($event, item)"
                    trigger="click"
                  >
                    <div class="i-right" @click.stop>
                      <div class="point" v-for="(o, i) in 3" :key="i"></div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit">编辑</el-dropdown-item>
                      <el-dropdown-item command="stop">停用</el-dropdown-item>
                      <el-dropdown-item command="delete">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </transition-group>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <div class="flex flex-between flex-align-center ml-20 pr-10 w100">
                <span>已停用的标签组</span>
              </div>
            </template>
            <transition-group>
              <div
                class="l1-list"
                :class="{ current: chooseGroup.id == item.id }"
                v-for="item in stopGroupList"
                :data-id="item.id"
                :key="item.id"
                @click="getTagsList(item)"
              >
                <div
                  class="l1-list-item flex flex-center"
                  :style="randerStyle(item)"
                >
                  <span class="nowrap flex-1"
                    >{{ item.customerLabelGroupName }} ·
                    {{ item.customerLabelCount }}</span
                  >
                  <el-dropdown
                    @command="handleCommand($event, item)"
                    trigger="click"
                  >
                    <div class="i-right" @click.stop>
                      <div class="point" v-for="(o, i) in 3" :key="i"></div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="edit">编辑</el-dropdown-item>
                      <el-dropdown-item command="start">启用</el-dropdown-item>
                      <el-dropdown-item command="delete">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </transition-group>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="flex-1 ml-20" style="overflow: auto">
        <div>
          <el-table
            :data="tagShowList"
            style="width: 100%"
            border
            row-key="id"
            class="initTable"
            header-row-class-name="table-header"
            :span-method="spanMethod"
          >
            <el-table-column label="标签名称" min-width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.id == 'add'">
                  <div
                    class="flex flex-center"
                    v-if="addItem.sysCustomerLabelGroupId"
                  >
                    <el-input
                      class="flex-1 mr-10"
                      maxlength="8"
                      show-word-limit
                      v-model="addItem.customerLabelName"
                      size="small"
                    ></el-input>
                    <el-input
                      class="flex-2 mr-10"
                      maxlength="100"
                      show-word-limit
                      v-model="addItem.initDescribe"
                      size="small"
                    ></el-input>
                    <el-button type="primary" size="small" @click="addTag"
                      >保存</el-button
                    >
                    <el-button size="small" @click="hideAddTag">取消</el-button>
                  </div>
                  <el-button
                    v-else
                    class="border-btn"
                    size="small"
                    @click="showAddTag"
                    >+ 新增标签</el-button
                  >
                </div>
                <el-input
                  v-else
                  class="flex-1 noBorder"
                  maxlength="8"
                  show-word-limit
                  @change="updateTag(scope.row)"
                  v-model="scope.row.customerLabelName"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column align="center" label="是否初始化" min-width="100">
              <template slot-scope="scope">
                <el-switch
                  @change="updateTag(scope.row)"
                  size="small"
                  v-model="scope.row.initFlag"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column align="center" label="初始化说明" min-width="200">
              <template slot-scope="scope">
                <el-input
                  class="flex-1 noBorder"
                  maxlength="100"
                  show-word-limit
                  @change="updateTag(scope.row)"
                  v-model="scope.row.initDescribe"
                  size="small"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="创建时间"
              prop="createTime"
              min-width="160"
            >
            </el-table-column>
            <el-table-column align="center" label="操作" min-width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTag(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <edit-dialog
      :visible="editVisible"
      :info="editGroupItem"
      @close="hideEditDialog"
      @confirm="confirmEditDialog"
    ></edit-dialog>
  </div>
</template>

<script>
import {
  get_label_group_list,
  get_label_list,
  update_label_group,
  delete_label_group,
  add_label,
  update_label,
  delete_label,
  stopOrEnable_label_group,
} from "@/api/base";
import editDialog from "./add-group-dialog";
import { convertHexToRGB } from "@/utils/index";

export default {
  components: {
    editDialog,
  },
  data() {
    return {
      searchKey: "",
      tagGroupList: [],
      tagList: [],
      tagListAll: [],
      chooseGroup: {},
      editGroupItem: {},
      editVisible: false,
      addItem: {},
      groupIndex: -1,
      tagIndex: -1,
      activeCollapse: "1",
      addVisible: false,
    };
  },
  async mounted() {
    await this.getGroupList();
  },
  computed: {
    stopGroupList() {
      return this.tagGroupList.filter((o) => {
        return o.stopFlag;
      });
    },
    startGroupList() {
      return this.tagGroupList.filter((o) => {
        return !o.stopFlag;
      });
    },
    tagShowList() {
      if (!this.chooseGroup.id) return [];
      if (this.addVisible) {
        return [this.addItem].concat(this.tagList);
      }
      return [
        {
          id: "add",
        },
      ].concat(this.tagList);
    },
  },
  methods: {
    spanMethod({ row, columnIndex }) {
      if (row.id == "add") {
        if (columnIndex == 0) {
          return [1, 5];
        } else {
          return [0, 0];
        }
      }
    },
    randerStyle(item) {
      if (this.chooseGroup.id == item.id) {
        return {
          background:
            "rgba(" +
            convertHexToRGB(item.customerLabelGroupColor) +
            ", 0.06) !important",
          color: item.customerLabelGroupColor + " !important",
          "border-color": item.customerLabelGroupColor + " !important",
        };
      }
    },
    async getGroupList() {
      const res = await get_label_group_list();
      this.tagGroupList = res || [];
      if (this.chooseGroup.id) {
        this.getTagsList(this.chooseGroup);
      } else {
        this.getTagsList(this.startGroupList[0] || this.stopGroupList[0]);
      }
    },
    getTagsList(item) {
      this.searchKey = "";
      this.hideAddTag();
      if (item) this.chooseGroup = item;
      if (!this.chooseGroup.id) return;
      get_label_list({
        id: this.chooseGroup.id,
      }).then((res) => {
        this.tagList = res || [];
        this.tagListAll = this.tagList.concat();
      });
    },
    search() {
      this.hideAddTag();
      this.tagList = this.tagListAll.filter((o) => {
        return o.customerLabelName.indexOf(this.searchKey) > -1;
      });
    },
    //点击下拉菜单
    handleCommand(val, item) {
      switch (val) {
        case "edit":
          this.editGroupItem = this.$deepClone(item);
          this.editVisible = true;
          break;
        case "start":
          if (item.customerLabelCount == 0) {
            this.startTagGroup(item, false);
          } else {
            this.$confirm(
              "是否对该标签组下所有的标签进行初始化操作？",
              "启用标签组",
              {
                confirmButtonText: "是",
                cancelButtonText: "否",
                customClass: "dialog-class",
                distinguishCancelAndClose: true,
                center: true,
              }
            )
              .then(() => {
                this.startTagGroup(item, true);
              })
              .catch((action) => {
                if (action === "cancel") {
                  this.startTagGroup(item, false);
                }
              });
          }
          break;
        case "stop":
          this.stopTagGroup(item);
          break;
        case "delete":
          this.deleteTagGroup(item);
          break;
        default:
          break;
      }
    },
    addGroup() {
      this.editVisible = true;
    },
    hideEditDialog() {
      this.editGroupItem = {};
      this.editVisible = false;
    },
    confirmEditDialog() {
      this.hideEditDialog();
      this.getGroupList();
    },
    startTagGroup(item, labelInitFlag) {
      stopOrEnable_label_group({
        ...item,
        stopFlag: 0,
        labelInitFlag,
      })
        .then(() => {
          this.$message.success("启用成功");
          this.getGroupList();
          this.getTagsList();
        })
        .catch(() => {
          this.getGroupList();
          this.getTagsList();
        });
    },
    stopTagGroup(item) {
      this.$confirm(
        "若停用该标签组，该标签组及标签组下的标签将关闭初始化，新开户酒店将不显示该标签组及标签信息，确定停用？",
        "停用标签组",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "dialog-class",
          center: true,
        }
      ).then(() => {
        stopOrEnable_label_group({
          ...item,
          stopFlag: 1,
        })
          .then(() => {
            this.$message.success("停用成功");
            this.getGroupList();
            this.getTagsList();
          })
          .catch(() => {
            this.getGroupList();
            this.getTagsList();
          });
      });
    },
    updateTagGroup(params) {
      update_label_group(params)
        .then(async () => {
          this.$message.success("修改成功");
          this.getGroupList();
        })
        .catch(() => {
          this.getGroupList();
        });
    },
    deleteTagGroup(item) {
      this.$confirm(
        "若删除该标签组，新开户的酒店将不再显示该标签组及标签信息，确定删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "dialog-class",
          center: true,
        }
      ).then(() => {
        let params = new FormData();
        params.append("id", item.id);
        console.log(params);
        delete_label_group(params).then(async () => {
          this.$message.success("删除成功");
          this.getGroupList();
        });
      });
    },
    showAddTag() {
      this.addItem = {
        id: "add",
        sysCustomerLabelGroupId: this.chooseGroup.id,
        customerLabelName: "",
        initDescribe: "",
        initFlag: true,
      };
      this.addVisible = true;
    },
    hideAddTag() {
      this.addVisible = false;
      this.addItem = {};
    },
    addTag() {
      if (!this.addItem.customerLabelName) {
        return this.$message.warning("标签名称不可为空");
      }
      add_label(this.addItem).then(() => {
        this.hideAddTag();
        this.getGroupList();
        this.getTagsList();
      });
    },
    updateTag(item) {
      if (!item.customerLabelName) {
        return this.$message.warning("标签名称不可为空");
      }
      update_label(item)
        .then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getTagsList();
        })
        .catch(() => {
          this.getTagsList();
        });
    },
    deleteTag(item) {
      this.$confirm(
        "删除后，设置客户标签处将不再显示该标签，确定删除？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          customClass: "dialog-class",
          center: true,
        }
      ).then(() => {
        let params = {
          id: item.id,
        };
        delete_label(params).then(async () => {
          this.$message.success("删除成功");
          this.getGroupList();
          this.getTagsList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.left-box {
  width: 240px;
  ::v-deep {
    .el-collapse {
      .el-collapse-item__header {
        background: #f4f4f4;
        .add {
          color: #9a9a9a;
          font-size: 15px;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 10px !important;
          border-radius: 4px;
          height: auto;
          border: 2px solid #9a9a9a;
          &:hover {
            // color: #409eff;
            // border: 2px solid #409eff;
          }
        }
      }
      .el-collapse-item__content {
        // padding: 6px 0;
        padding-bottom: 0;
        line-height: 40px;
        .l1-list {
          padding: 0;
          position: relative;
          cursor: pointer;
          justify-content: space-between;
          align-items: center;
          .i-right {
            display: none;
            padding: 8px 6px;
            width: 20px;
            border-radius: 4px;
            align-items: center;
            justify-content: space-around;
            &:hover {
              background: rgba(0, 0, 0, 0.06);
            }
            .point {
              width: 4px;
              height: 4px;
              border-radius: 4px;
              background: #626872;
            }
          }
          .l1-list-item {
            padding: 0 10px 0 30px;
          }
          &.current {
            .l1-list-item:after {
              position: absolute;
              content: "";
              height: 40px;
              right: 0;
              top: 0;
              border-right: 3px solid;
              // opacity: 0;
            }
          }
          &:hover {
            .l1-list-item {
              background: #ecf5ff;
              color: #409eff;
              border-color: #409eff;
            }
            .i-right {
              display: flex;
            }
            .l1-list-item::after {
              position: absolute;
              content: "";
              height: 40px;
              right: 0;
              top: 0;
              border-right: 3px solid;
            }
          }
        }
      }
      .el-collapse-item__wrap {
        border-bottom: 0px;
      }
    }
  }
}
.w100 {
  width: 100%;
}
.initTable {
  ::v-deep {
    .el-input {
      .el-input__inner {
        border: 1px solid transparent;
        &:hover {
          border: 1px solid #409eff;
          + .el-input__suffix {
            opacity: 1;
          }
        }
      }
    }

    .el-input__suffix {
      opacity: 0;
    }
  }
}
</style>