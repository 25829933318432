<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          标签组名称
        </span>
        <el-input class="flex-1" v-model="form.customerLabelGroupName" size="small" maxlength="8" show-word-limit></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          标签组颜色
        </span>
        <div class="flex-1 flex">
          <div class="color-item mr-10 font-size-12"
            v-for="(c, i) in colorList" :key="i"
            :class="{'disabled': c.check && oldColor != c.colorName}"
            @click="chooseColor(c)"
          >
            <div class="color-span" :style="{'background': c.colorName, 'border': '1px solid ' + c.colorName}"></div>
            <span class="el-icon-check" v-show="form.customerLabelGroupColor == c.colorName"></span>
            <span class="iconfont icon-jinzhi" v-show="c.check && oldColor != c.colorName" :style="{'color': c.colorName}"></span>
          </div>
          <el-color-picker class="color-picker-class" v-model="customColor" @change="form.customerLabelGroupColor = ''"></el-color-picker>
        </div>
      </div>
      <div class="form-item flex flex-center" v-show="!form.flag">
        <span class="form-label">
          <span class="must">*</span>
          标签组状态
        </span>
        <el-select class="flex-1" v-model="form.stopFlag" placeholder="请选择" size="small">
          <el-option label="启用" :value="false"></el-option>
          <el-option label="停用" :value="true"></el-option>
        </el-select>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  add_label_group,
  update_label_group,
  get_label_color_list
} from '@/api/base'
import { FormValid } from "@/utils/formValid"

const form = {
  id: '',
  customerLabelGroupName: '',
  customerLabelGroupColor: '',
  stopFlag: false
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      loading: false,
      colorList: [],
      oldColor: '',
      customColor: ''
    }
  },
  watch: {
    visible(val) {
      console.log(this.info)
      if (val && this.info.id) {
        this.form = this.$deepClone(this.info)
        this.oldColor = this.form.customerLabelGroupColor
      } else {
        this.form = this.$deepClone(form)
        this.customColor = ''
        this.oldColor = ''
      }
      if (val) {
        this.getColors()
      }
    }
  },
  computed: {
    title() {
      return this.info.id ? '编辑标签组' : '新增标签组'
    },
    checkFormList() {
      let arr = [
        {
          label: '标签组名称',
          value: 'customerLabelGroupName',
          methods: ['notEmpty']
        }
      ]
      if (!this.customColor) {
        arr.push({
          label: '标签组颜色',
          value: 'customerLabelGroupColor',
          methods: ['notEmpty']
        })
      }
      return arr
    }
  },
  mounted() {
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    },
    getColors() {
      get_label_color_list().then(res => {
        this.colorList = res || []
        const index = this.colorList.findIndex(o => { return o.colorName == this.form.customerLabelGroupColor })
        if (index < 0) {
          this.customColor = this.form.customerLabelGroupColor
          this.form.customerLabelGroupColor = ''
        }
      })
    },
    chooseColor(item) {
      if (item.check && this.oldColor != item.colorName) return
      this.form.customerLabelGroupColor = item.colorName
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      params.stopFlag = params.stopFlag ? 1 : 0
      params.customerLabelGroupColor = params.customerLabelGroupColor || this.customColor
      this.loading = true
      if (this.info.id) {
        update_label_group(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      } else {
        add_label_group(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 100px;
    text-align: right;
    padding-right: 20px;
  }
}
.color-item{
  cursor: pointer;
  position: relative;
  .color-span{
    width: 38px;
    height: 38px;
    border-radius: 2px;
  }
  .el-icon-check{
    color: #ffffff;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .iconfont{
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &.disabled{
    cursor: not-allowed;
    .color-span{
      opacity: 0.5;
    }
  }
}
.color-picker-class{
  position: relative;
  ::v-deep .el-color-picker__trigger{
    padding: 0;
    border-radius: 0;
    .el-color-picker__icon{
      display: none;
    }
    .el-color-picker__color{
      border: 0;
    }
    .el-color-picker__empty{
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      &:before{
        content: '+';
        position: absolute;
        font-size: 30px;
        width: 100%;
        text-align: center;
        line-height: 36px;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>