let Quill = require('../quill-develop/dist/quill')
const BlockEmbed = Quill.import('blots/embed')

class BtnBlot extends BlockEmbed {
  static create(value) {
    const node = super.create()
    node.setAttribute('value', value)
    node.setAttribute('type', 'button')
    node.setAttribute('role', 'button')
    return node
  }

  static value(node) {
    return node.getAttribute('value')
  }
}
BtnBlot.blotName = 'tag'
BtnBlot.tagName = 'input'
export default BtnBlot
