<template>
  <div>
    <div class="page-sub-title flex flex-align-center">
      <span>线索展示设置</span>
      <span class="font-size-13 grey ml-10"
        >线索列表默认按顺序展示【展示状态】的线索</span
      >
    </div>
    <div class="mb-20">
      <el-transfer
        v-model="clueValue"
        :data="clueStatus"
        :props="props"
        :titles="titles"
        @change="updateHotelConfig($event, 'CLUE')"
      ></el-transfer>
    </div>
    <div class="page-sub-title flex flex-align-center">
      <span>销售机会展示设置</span>
      <span class="font-size-13 grey ml-10"
        >销售机会列表默认按顺序展示【展示状态】的销售机会</span
      >
    </div>
    <div class="mb-20">
      <el-transfer
        v-model="preOrderValue"
        :data="preOrderStatus"
        :props="props"
        :titles="titles"
        @change="updateHotelConfig($event, 'PRE_ORDER')"
      ></el-transfer>
    </div>
    <div class="page-sub-title flex flex-align-center">
      <span>订单展示设置</span>
      <span class="font-size-13 grey ml-10"
        >订单列表默认按顺序展示【展示状态】的订单</span
      >
    </div>
    <div class="mb-20">
      <el-transfer
        v-model="orderValue"
        :data="orderStatus"
        :props="props"
        :titles="titles"
        @change="updateHotelConfig($event, 'MEETING_ORDER')"
      ></el-transfer>
    </div>
  </div>
</template>

<script>
import {
  get_hotelConfigInitConfigTypeList,
  post_updateHotelConfigInitConfigType,
} from "@/api/base";
import { get_key_value } from "@/api/common";

export default {
  name: "list-show-config",
  components: {},
  data() {
    return {
      clueValue: [],
      clueStatus: [],
      preOrderValue: [],
      preOrderStatus: [],
      orderValue: [],
      orderStatus: [],
      configList: [],
      titles: ["不展示状态", "展示状态"],
    };
  },
  computed: {
    props() {
      return {
        key: "k",
        label: "v",
      };
    },
  },
  mounted() {
    this.getOption();
    this.getHotelConfigList();
  },
  methods: {
    getOption() {
      get_key_value({
        type: "CLUE_STATUS",
      }).then((res) => {
        this.clueStatus = (res ? res.records : []).filter((o) => {
          return o.flag;
        });
      });
      get_key_value({
        type: "PRE_ORDER_STATUS",
      }).then((res) => {
        this.preOrderStatus = (res ? res.records : []).filter((o) => {
          return o.flag;
        });
      });
      get_key_value({
        type: "ORDER_STATUS",
      }).then((res) => {
        this.orderStatus = (res ? res.records : []).filter((o) => {
          return o.flag;
        });
      });
    },
    getHotelConfigList() {
      get_hotelConfigInitConfigTypeList({
        configKList:
          "CLUE_STATUS_CONFIG,PREORDER_STATUS_CONFIG,ORDER_STATUS_CONFIG",
      }).then((res) => {
        this.configList = res || [];
        this.clueValue = res
          .find((o) => o.code == "CLUE_STATUS_CONFIG")
          ?.value.split(",");
        this.preOrderValue = res
          .find((o) => o.code == "PREORDER_STATUS_CONFIG")
          ?.value.split(",");
        this.orderValue = res
          .find((o) => o.code == "ORDER_STATUS_CONFIG")
          ?.value.split(",");
      });
    },
    updateHotelConfig(value, type) {
      let hotelConfig = {};
      if (type == "CLUE") {
        hotelConfig = this.configList.find(
          (o) => o.code == "CLUE_STATUS_CONFIG"
        );
      } else if (type == "PRE_ORDER") {
        hotelConfig = this.configList.find(
          (o) => o.code == "PREORDER_STATUS_CONFIG"
        );
      } else if (type == "MEETING_ORDER") {
        hotelConfig = this.configList.find(
          (o) => o.code == "ORDER_STATUS_CONFIG"
        );
      }
      post_updateHotelConfigInitConfigType({
        ...hotelConfig,
        value: value.join(","),
      }).then(() => {
        this.$message.success("保存成功");
        this.getHotelConfigList();
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
