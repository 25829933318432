<template>
  <div style="height:100%;overflow:hidden">
    <div class="flex" style="height:100%" >
      <div class="page-content" style="height:100%;overflow: hidden;" v-show="selectMenu.value !== 33">
        <div class="page-sub-title flex flex-center">
          <span class="flex-1">初始化项目</span>
        </div>
        <div class="menu-list" style="height:100%;overflow:hidden">
          <div>
            <el-input v-model="searchMenu" size="small" clearable class="flex-1 mb-16" placeholder="请输入关键词搜索" @keyup.enter.native="searchM">
              <el-button slot="append" icon="el-icon-search" @click="searchM"></el-button>
            </el-input>
          </div>
          <!-- <div
            :class="
              o.checked ? 'item cursor-point active mb-10' : 'item cursor-point mb-10'
            "
            v-for="(o, i) in reloadList"
            :key="i"
            @click="checkMenu(o)"
          >
            <span v-if="o.checked" class="jiao"></span>
            <span>{{ o.title }}</span>
          </div> -->
          <div style="height:calc(100% - 150px);;overflow:auto">
            <div class="page-sub-title item font-size-15" v-for="(o, i) in reloadList" :key="i">
              <span>{{ o.title }}</span>
              <div class="font-size-14 pl-20 mt-10 cursor-point items" :class="{'active':p.checked}" v-for="(p,q) in o.list" :key="q" @click="checkMenu(p)">
                <span>{{ p.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-column flex-auto" :class="selectMenu.value == 33?'o-hidden':'o-auto m-l-16 '"  style="height:calc(100%-100px)">
        <div v-if="(selectMenu.value === 25 || selectMenu.value === 14) && sourceType == 2" class="page-content mb-10 flex flex-align-center back font-size-14 cursor-point" @click="backList">
          <i class="el-icon-arrow-left"></i>
          返回角色列表
        </div>
        <div class="page-content" :class="selectMenu.value == 33?'p-0 o-hidden':'o-auto'" style="height:100%">
          <discount ref="discount" v-if="selectMenu.value === 1"></discount>
          <service ref="service" v-if="selectMenu.value === 2"></service>
          <organization ref="organization" v-if="selectMenu.value === 3"></organization>
          <meetingType ref="meetingType" v-if="selectMenu.value === 4"></meetingType>
          <clueInvalid ref="clueInvalid" v-if="selectMenu.value === 5"></clueInvalid>
          <payType ref="payType" v-if="selectMenu.value === 6"></payType>
          <collection ref="collection" v-if="selectMenu.value === 7"></collection>
          <preOrderClass ref="preOrderClass" v-if="selectMenu.value === 8"></preOrderClass>
          <loseReason ref="loseReason" v-if="selectMenu.value === 9"></loseReason>
          <refundReason ref="refundReason" v-if="selectMenu.value === 10"></refundReason>
          <member ref="member" v-if="selectMenu.value === 11"></member>
          <cancelOrder ref="cancelOrder" v-if="selectMenu.value === 12"></cancelOrder>
          <tags ref="tags" v-if="selectMenu.value === 13"></tags>
          <role ref="role" v-if="selectMenu.value === 14" @change="changeSource"></role>
          <mealType ref="mealType" v-if="selectMenu.value === 15"></mealType>
          <!-- <sourceList ref="source" v-if="selectMenu.value === 16"></sourceList> -->
          <meetingRoom ref="meetingRoom" v-if="selectMenu.value === 17"></meetingRoom>
          <meetingRoomTags ref="meetingRoomTags" v-if="selectMenu.value === 18"></meetingRoomTags>
          <partner ref="partner" v-if="selectMenu.value === 19"></partner>
          <clueRole ref="clueRole" v-if="selectMenu.value === 20"></clueRole>
          <salesChanceRule ref="salesChanceRule" v-if="selectMenu.value === 21"></salesChanceRule>
          <meetingTypeAttribute ref="meetingTypeAttribute" v-if="selectMenu.value === 24"></meetingTypeAttribute>
          <!-- 集团角色 -->
          <breadRole ref="breadRole" v-if="selectMenu.value === 25" @change="changeSource"></breadRole> 
          <!-- 集团组织机构 -->
          <breadOrganization ref="breadOrganization" v-if="selectMenu.value === 26"></breadOrganization>
          <dishType ref="dishType" v-if="selectMenu.value === 27"></dishType>
          <eoOrder ref="eoOrder" v-if="selectMenu.value === 28"></eoOrder>
          <marking ref="marking" v-if="selectMenu.value === 29"></marking>
          <tempResvType ref="tempResvType" v-if="selectMenu.value === 30"></tempResvType>
          <tempMealType ref="tempMealType" v-if="selectMenu.value === 31"></tempMealType>
          <tempSetting ref="tempSetting" v-if="selectMenu.value === 32"></tempSetting>
          <contractModel ref="contractModel" v-if="selectMenu.value === 33"></contractModel>
          <component
            :is="selectMenu.component"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import discount from "./component/hotel-initialization/discount.vue";
import service from "./component/hotel-initialization/service.vue";
import tags from './component/hotel-initialization/tags'
import message from './component/hotel-initialization/messageConfig'
import orderRule from './component/hotel-initialization/orderRule'
import organization from './component/hotel-initialization/organization'
import meetingType from './component/hotel-initialization/meetingType'
import clueInvalid from './component/hotel-initialization/clueInvalid'
import payType from './component/hotel-initialization/payType'
import collection from './component/hotel-initialization/collection'
import preOrderClass from './component/hotel-initialization/preOrderClass'
import loseReason from './component/hotel-initialization/loseReason'
import refundReason from './component/hotel-initialization/refundReason'
import member from './component/hotel-initialization/member'
import cancelOrder from './component/hotel-initialization/cancelOrder'
import role from './component/hotel-initialization/role'
import mealType from './component/hotel-initialization/mealType'
// import sourceList from './component/hotel-initialization/source'
import meetingRoom from './component/hotel-initialization/meetingRoom'
import meetingRoomTags from './component/hotel-initialization/meetingRoomTags'
import partner from './component/hotel-initialization/partner'
import clueRole from './component/hotel-initialization/clueRole'
import salesChanceRule from './component/hotel-initialization/salesChanceRule'
import meetingTypeAttribute from './component/hotel-initialization/meetingTypeAttribute'
import tempResvType from './component/hotel-initialization/tempResvType'
import tempMealType from './component/hotel-initialization/tempMealType'

import breadRole from './component/hotel-initialization/breadRole'
import breadOrganization from './component/hotel-initialization/breadOrganization'

import dishType from './component/hotel-initialization/dishType'
import serverType from './component/hotel-initialization/serverType'
import eoOrder from './component/hotel-initialization/eoOrder'

import marking from './component/hotel-initialization/marking'
import tempSetting from './component/hotel-initialization/tempSetting'

import contractModel from './component/hotel-initialization/contractModel'
// import workflow from './workflow/index'
import listStatusConfig from './component/hotel-initialization/listStatusConfig'

export default {
  name: "hotel-initialization",
  // mixins: [pageMixin],
  components: {
    message,
    orderRule,
    discount,
    service,
    tags,
    organization,
    meetingType,
    clueInvalid,
    payType,
    collection,
    preOrderClass,
    loseReason,
    refundReason,
    member,
    cancelOrder,
    role,
    mealType,
    // sourceList,
    meetingRoom,
    meetingRoomTags,
    partner,
    clueRole,
    salesChanceRule,
    meetingTypeAttribute,
    //零点预订
    tempResvType,
    tempMealType,
    breadRole,
    breadOrganization,
    dishType,
    eoOrder,
    marking,
    tempSetting,
    contractModel,
    serverType,
    // workflow,
    listStatusConfig,
  },
  data() {
    return {
      selectMenu: {},
      searchMenu: '',
      addType: 1,
      reloadListAll: [],
      // 优惠类型
      reloadList: [
        {
          title: '用户与权限类',
          checked: true,
          list: [
            {
              title: "门店角色初始化",
              value: 14,
              checked: true,
            },
            {
              title: "门店组织机构初始化",
              value: 3,
              checked: false,
            },
            {
              title: "集团角色初始化",
              value: 25,
              checked: false,
            },
            {
              title: "集团组织机构初始化",
              value: 26,
              checked: false,
            },
          ]
        },
        {
          title: '线索类',
          checked: false,
          list: [
            // {
            //   title: "来源初始化",
            //   value: 16,
            //   checked: false,
            // },
            {
              title: "无效原因初始化",
              value: 5,
              checked: false,
            },
            {
              title: "线索相关规则初始化",
              value: 20,
              checked: false,
            },
          ]
        },
        {
          title: '销售机会类',
          checked: false,
          list: [
            {
              title: "销售机会等级初始化",
              value: 8,
              checked: false,
            },
            {
              title: "输单原因初始化",
              value: 9,
              checked: false,
            },
            {
              title: "销售机会相关规则初始化",
              value: 21,
              checked: false,
            },
          ]
        },
        {
          title: '订单服务类',
          checked: false,
          list: [
            {
              title: "服务身份初始化",
              value: 11,
              checked: false,
            },
            {
              title: "订单取消原因初始化",
              value: 12,
              checked: false,
            },
            {
              title: "订单相关规则初始化",
              value: 22,
              checked: false,
              component: orderRule
            },
            {
              title: "EO单初始化",
              value: 28,
              checked: false,
            },
            {
              title: "合同模板初始化",
              value: 33,
              checked: false,
            },
          ]
        },
        {
          title: '客户类',
          checked: false,
          list: [
            {
              title: "客户标签初始化",
              value: 13,
              checked: false,
            },
          ]
        },
        {
          title: '宴会相关类',
          checked: false,
          list: [
            {
              title: "优惠类型初始化",
              value: 1,
              checked: false,
            },
            {
              title: "服务类型初始化",
              value: 2,
              checked: false,
            },
            {
              title: "权益类型初始化",
              value: 29,
              checked: false,
            },
            {
              title: "宴会类型初始化",
              value: 4,
              checked: false,
            },
            {
              title: "宴会类别初始化",
              value: 24,
              checked: false,
            },
            {
              title: "宴会厅初始化",
              value: 17,
              checked: false,
            },
            {
              title: "宴会厅标签初始化",
              value: 18,
              checked: false,
            },
            {
              title: "宴会餐段初始化",
              value: 15,
              checked: false,
            },
            {
              title: "同行人身份初始化",
              value: 19,
              checked: false,
            },
            {
              title: "菜品类别初始化",
              value: 27,
              checked: false,
            },
            {
              title: "服务套餐类别初始化",
              value: 34,
              checked: false,
              component: serverType
            },
          ]
        },
        {
          title: '零点业务相关类',
          checked: false,
          list: [
            {
              title: "预订类型初始化",
              value: 30,
              checked: false,
            },
            {
              title: "零点餐段初始化",
              value: 31,
              checked: false,
            },
            {
              title: "相关显示设置",
              value: 32,
              checked: false,
            }
          ]
        },
        {
          title: '其他',
          checked: false,
          list: [
            {
              title: "支付方式初始化",
              value: 6,
              checked: false,
            },
            {
              title: "收款项目初始化",
              value: 7,
              checked: false,
            },
            {
              title: "退款原因初始化",
              value: 10,
              checked: false,
            },
            {
              title: "短信模板初始化",
              value: 23,
              checked: false,
              component: message
            },
            // {
            //   title: "审批流初始化",
            //   value: 35,
            //   checked: false,
            //   component: workflow
            // },
            {
              title: "客资默认展示设置",
              value: 36,
              checked: false,
              component: listStatusConfig
            },
          ]
        } 
      ],
      discountList: [],
      addInitializationVisible: false,
      sourceType: 1
    };
  },
  watch: {
    selectMenu(val) {
      if(val.value === 1) {
        this.$nextTick(() => {
          this.$refs.discount.getList() //优惠
        })
      } else if (val.value === 2) {
        this.$nextTick(() => {
          this.$refs.service.getList() //服务
        })
      } else if (val.value === 3) {
        this.$nextTick(() => {
          this.$refs.organization.getList() //部门
        })
      } else if (val.value === 4) {
        this.$nextTick(() => {
          this.$refs.meetingType.getList() //宴会类型
        })
      } else if (val.value === 5) {
        this.$nextTick(() => {
          this.$refs.clueInvalid.getList() //无效原因
        })
      } else if (val.value === 6) {
        this.$nextTick(() => {
          this.$refs.payType.getList() //支付方式
        })
      } else if (val.value === 7) {
        this.$nextTick(() => {
          this.$refs.collection.getList() //收款项目
        })
      } else if (val.value === 8) {
        this.$nextTick(() => {
          this.$refs.preOrderClass.getList() //销售机会等级
          this.$refs.preOrderClass.getConfigTypeList()
        })
      } else if (val.value === 9) {
        this.$nextTick(() => {
          this.$refs.loseReason.getList() //输单原因
        })
      } else if (val.value === 10) {
        this.$nextTick(() => {
          this.$refs.refundReason.getList() //退款原因
        })
      } else if (val.value === 11) {
        this.$nextTick(() => {
          this.$refs.member.getList() //成员
        })
      } else if (val.value === 12) {
        this.$nextTick(() => {
          this.$refs.cancelOrder.getList() //订单取消
        })
      } else if (val.value === 14) {
        this.$nextTick(() => {
          this.$refs.role.getList() //角色初始化
        })
      } else if (val.value === 15) {
        this.$nextTick(() => {
          this.$refs.mealType.getList() //餐段初始化
        })
      } else if (val.value === 16) {
        this.$nextTick(() => {
          this.$refs.source.getList() //来源初始化
        })
      } else if (val.value === 17) {
        this.$nextTick(() => {
          this.$refs.meetingRoom.getList() //宴会厅初始化
        })
      } else if (val.value === 18) {
        this.$nextTick(() => {
          this.$refs.meetingRoomTags.getList() //标签初始化
        })
      } else if (val.value === 19) {
        this.$nextTick(() => {
          this.$refs.partner.getAllMeetingTypeList()
          this.$refs.partner.getList() //同行人初始化
        })
      } else if (val.value === 20) {
        this.$nextTick(() => {
          this.$refs.clueRole.clueHotelConfig() //无效线索初始化
          this.$refs.clueRole.get_clueTaskConfig() //无效任务初始化
          this.$refs.clueRole.getkeyvalue()
        })
      } else if (val.value === 21) {
        this.$nextTick(() => {
          this.$refs.salesChanceRule.handelInit() //销售机会初始化
        }) 
      } else if (val.value === 24) {
        this.$nextTick(() => {
          this.$refs.meetingTypeAttribute.getList() //宴会类型类别初始化
        }) 
      } else if (val.value === 25) {
        this.$nextTick(() => {
          this.$refs.breadRole.getList() //集团角色初始化
        }) 
      } else if (val.value === 26) {
        this.$nextTick(() => {
          this.$refs.breadOrganization.getList() //集团组织机构初始化
        }) 
      }else if (val.value === 27) {
        this.$nextTick(() => {
          this.$refs.dishType.getList() //菜品类别
        }) 
      } else if (val.value === 28) {
        this.$nextTick(() => {
          // this.$refs.eoOrder.getDetail() //eo单
          console.log(11)
        }) 
      } else if (val.value === 29) { 
        this.$nextTick(() => {
          this.$refs.marking.getList() //权益类型
        }) 
      } else if (val.value === 30) {
        this.$nextTick(() => {
          this.$refs.tempResvType.getList() //零点预订类型初始化
        }) 
      } else if (val.value === 31) {
        this.$nextTick(() => {
          this.$refs.tempMealType.getList() //零点预订类型初始化
        })
      } else if (val.value === 33) {
        this.$nextTick(() => {
          this.$refs.contractModel.getList() //合同模板初始化
        })
      } 
    }
  },
  mounted() {
    this.selectMenu = this.reloadList[0].list[0]
    console.log(this.reloadList[0].list[0],'this.selectMenu')
    this.reloadListAll = this.reloadList.concat()
  },
  methods: {
    //切换菜单
    checkMenu(val) {
      console.log(val)
      this.selectMenu = val;
      this.reloadList.map(o => {
        o.checked = false
        o.list.map(p => {
          p.checked = false
          if (p.value === val.value) {
            console.log(o)
            o.checked = true;
            p.checked = true;
          }
        })
      });
    },
    searchM() {
      if(this.searchMenu) {
        // let _arr = []
        // this.reloadListAll.map(o => {
        //   if(o.title.indexOf(this.searchMenu) !== -1) {
        //     _arr.push(o)
        //   }
        // })
        let _data = this.reloadListAll.map(o => {
          return {
            ...o,
            list: o.list.filter(v => { return v.title.indexOf(this.searchMenu) > -1 })
          }
        }).filter(o => o.list.length > 0)
        this.reloadList = _data
        this.checkMenu(this.reloadList[0].list[0])
      } else {
        this.reloadList = this.reloadListAll.concat()
        this.checkMenu(this.reloadList[0])
      }
      
    },
    changeSource(val) {
      this.sourceType = val
    },
    backList() {
      this.sourceType = 1
      if(this.selectMenu.value === 25) {
        this.$nextTick(() => {
          this.$refs.breadRole.sourceType = 1
        })
      } else if (this.selectMenu.value === 14) {
        this.$refs.role.sourceType = 1
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-list {
  .item {
    line-height: 30px;
    .items {
      &:hover {
        color: #409eff;
        background: #f9f9f9;
      }
    }
    // padding-left: 40px;
    // position: relative;
    // line-height: 32px;
    // color: #333333;
    // .jiao {
    //   position: absolute;
    //   left: 20px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   width: 5px;
    //   height: 5px;
    //   border-radius: 5px;
    //   background: white;
    // }
  }
  .active {
    background: #f9f9f9;
    color: #409eff;
  }
}
.initTable {
  ::v-deep {
    .el-input__inner {
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #409eff;
      }
    }
  }
}
.page-sub-title1{
  font-size: 16px;
  margin-bottom: 16px;
  &::before{
    content: '';
    width: 3px;
    height: 12px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 6px;
  }
}
.back {
  padding: 16px 24px;
  color: #666666;
}
</style>
