<template>
  <div>
    <div class="contract" id="printMe">
      <div class="title text-center font-size-26 font-bold relative">
        <span>xx酒店 Event Order</span>
        <el-button style="right:0" type="primary" class="absolute" size="small" @click="addModal">保存模板</el-button>
      </div>
      <el-descriptions class="margin-top mt-10" size="medium" :column="3" border :label-style="labelStyle">
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            客户姓名
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            客户手机
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-fork-spoon"></i>
            宴会类型
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item :span="3">
          <template slot="label">
            宴会主家
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item :span="1.5">
          <template slot="label">
            销售负责
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item :span="1.5">
          <template slot="label">
            策划负责
          </template>
          填充数据
        </el-descriptions-item>
        <el-descriptions-item label="特殊要求" :span="3">
          填充数据
        </el-descriptions-item>
      </el-descriptions>
      <div class="fake-line">
        宴会档期及餐段
      </div>
      <el-descriptions class="margin-top" size="medium" :column="5" direction="vertical" border :label-style="labelStyle">
        <el-descriptions-item label="宴会厅  "> 填充数据</el-descriptions-item>
        <el-descriptions-item label="宴会桌数 "> 填充数据</el-descriptions-item>
        <el-descriptions-item label="备用整桌数"> 填充数据</el-descriptions-item>
        <el-descriptions-item label="备用冷桌数"> 填充数据</el-descriptions-item>
        <el-descriptions-item label="备用空桌数"> 填充数据</el-descriptions-item>
      </el-descriptions>
      <div class="fake-box flex">
        <div class="left">
          <div class="f-label" style="line-height:32px">菜品套餐</div>
          <div class="l-box p-10 c-3">填充数据</div>
        </div>
        <div class="right flex flex-column" ref="right">
          <el-popover
            placement="right-start"
            title="添加栏目"
            width="150"
            trigger="hover">
            <div class="mt-20">
              <div class="list2-item" v-for="(o,i) in showList" :key="i" @click="addItem(o,i)">
                {{o.v}}
              </div>
              <div class="list2-item" @click="addItem()">其他</div>
            </div>
            <div class="dynamic-box" slot="reference">
              <div class="item" :id="`item${i}`" v-for="(o,i) in fakeDetailList" :key="i"> 
                <el-tooltip class="item" effect="dark"  :placement="i === 0?'top':'left'" :disabled="i == 0 && o.moduleType == 'OTHER'">
                  <div slot="content" class="text-center">
                    <div v-if="i !== 0">可上下拖拽改变模块高度</div>
                    <div class="mt-5" v-if="o.moduleType !== 'DEPARTMENT_SIGN' && o.moduleType !== 'OTHER'">该模块关联{{o.realName}}</div>
                  </div>
                  <div class="f-label" :id="`itemLabel${i}`" @mousedown="i !== 0?change(`itemBox${i-1}`,`itemLabel${i}`,`itemBox${i}`,$event,i):''">
                    <el-input class="text-center" size="small" v-model="o.moduleName" @change="changeItemTitle(o,i)"></el-input>
                    <span class="del-btn font-size-13 c-red" @click.stop="deleteItem(o,i)" v-if="fakeDetailList.length > 1 && o.moduleType !== 'DEPARTMENT_SIGN'" >删除</span>
                  </div>
                </el-tooltip>
                <div class="r-box o-auto" :class="o.moduleType === 'DEPARTMENT_SIGN'?'r-box2':''" :id="`itemBox${i}`"  :style="{height:o.moduleHeight+'px'}">
                  <div class="p-10 c-3">填充数据</div>
                </div>
              </div>
              <div class="add-item">
              </div>
            </div>
          </el-popover>  
        </div>
      </div>
      <el-descriptions size="medium" :column="1" border :label-style="labelStyle">
        <el-descriptions-item label="宴席合同">填充数据</el-descriptions-item>
        <el-descriptions-item label="服务合同">填充数据</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { get_key_value } from '@/api/common'
import { get_EoTemplateDetail, post_updateEoTemplateDetail } from '@/api/base'
export default {
  name: '',
  data() {
    return {
      modalList: [],
      showList: [],
      fakeDetailList: [],
      labelStyle: {
        'color': '#333333',
        'text-align': 'center',
        'font-weight': '600',
        'width': '140px',
        'word-break': 'keep-all'
      },
      departmentList: [],
      detailList: [],
      dishModel: {}
    };
  },
  computed:{

  },
  watch:{
    // fakeDetailList(val) {
    //   val.map(o => {
    //     o.moduleHeight = (700 - val.length*43)/(val.length)
    //   })
    //   this.$forceUpdate()
    // },
    detailList(val) {
      if(val) {
        this.dishModel = this.detailList.filter(o => {return o.moduleType === 'DISH_COMBO'})
        this.fakeDetailList = this.$deepClone(this.detailList.filter(o => {return o.moduleType !== 'DISH_COMBO'}))
        console.log(this.dishModel,11,this.fakeDetailList ,22)
        this.fakeDetailList.map(o => {
          o.moduleNameReal = JSON.parse(JSON.stringify(o.moduleName))
          if(!o.moduleHeight) {
            o.moduleHeight = (700 - this.fakeDetailList.length*43)/(this.fakeDetailList.length)
          }
        })
        this.getOptions()
      }
    }
  },
  methods: {
    getList() {
      get_EoTemplateDetail({
        templateId: '0'
      }).then(res => {
        this.detailList = res
        // console.log(res,111)
      }) 
    },
    //拖动栏目
    change(topBoxId,resizeId,downBoxId,e,i) {
      let _this = this
      console.log(topBoxId,resizeId,downBoxId,e,i,'topBoxId,resizeId,downBoxId,e,i')
      let resize = document.getElementById(resizeId);
      let topBox = document.getElementById(topBoxId);
      let downBox = document.getElementById(downBoxId);
      let boxHeight = topBox.clientHeight + downBox.clientHeight
      // console.log(resize,'resize')
      // console.log(topBox,'topBox')
      // console.log(downBox,'downBox')
      // console.log(boxHeight,'boxHeight')
      let startY = e.clientY;
      resize.top = topBox.clientHeight;
      // console.log(resize.top,'resize.top')
      document.onmousemove = function (e) {
        // console.log(e,222)
        let endY = e.clientY;
        let moveLen = resize.top + (endY - startY);
        // console.log(moveLen,'moveLen')
        // console.log(topBox.clientHeight,'topBox.clientHeight')
        // console.log(downBox.clientHeight,'downBox.clientHeight')
        if (moveLen < 43) moveLen = 43;
        if (moveLen > boxHeight - 43) moveLen = boxHeight - 43;
        resize.style.top = moveLen;
        topBox.style.height = moveLen + "px";
        downBox.style.height = (boxHeight - moveLen) + "px";
        // console.log(resize.style,'resize.style')
        // let _index = i-1
        _this.fakeDetailList[i].moduleHeight = downBox.clientHeight
        _this.fakeDetailList[i-1].moduleHeight = topBox.clientHeight
        console.log(_this.fakeDetailList,'this.fakeDetailList')
      }
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture();
      }
      resize.setCapture && resize.setCapture();
      return false;
    },
    //删除栏目
    deleteItem(val,index) {
      console.log(val)
      if(val.moduleType === 'OTHER') {
        this.fakeDetailList.splice(index, 1)
      } else {
        this.fakeDetailList.splice(index, 1)
        // console.log(this.showList,this.modalList,333)
        let _data = this.modalList.filter(o => {return o.k === val.moduleType || o.k  === val.type})[0]
          // console.log(_data,'_data')
          this.showList.push(_data)
      }
      this.fakeDetailList.map(o => {
        o.moduleHeight = (700 - this.fakeDetailList.length*43)/(this.fakeDetailList.length)
      })
      // console.log(index,index2,'index')
      // console.log(this.showList,'this.showList')
      this.$forceUpdate()
    },
    //添加栏目
    addItem(val,index) {
      // console.log(val,222)
      if(val) {
        this.fakeDetailList.unshift({
          moduleType: val.k,
          moduleName: val.v,
          realName: val.v,
          moduleHeight: null,
        })
        this.showList.splice(index,1)
      } else {
        this.fakeDetailList.unshift({
          moduleType: 'OTHER',
          moduleName: '其他',
          moduleHeight: null,
        })
      }
      this.fakeDetailList.map(o => {
        o.moduleHeight = (700 - this.fakeDetailList.length*43)/(this.fakeDetailList.length)
      })
      this.$forceUpdate()
    },
    getOptions() {
      get_key_value({
        type: 'EO_MODULE_TYPE'
      }).then(res => {
        this.modalList = res.records
        let _list = this.$deepClone(res.records)
        console.log(_list,'_list[i]')
        this.modalList.map(o => {
          this.fakeDetailList.map(p => {
            if(o.k === p.moduleType) {
              p.realName = o.v
            }
          })
        })
        for(let p in this.fakeDetailList) {
          _list = _list.filter(o => {
            return o.k !== this.fakeDetailList[p].moduleType
          })
        }
        this.showList = _list
        this.$forceUpdate()
        console.log(this.showList,'this.showList')
      })
    },
    addModal() {
      this.fakeDetailList.map((o,i) => {
        o.seq = i
      })
      post_updateEoTemplateDetail({
        hotelId: 0,
        templateId: 0,
        moduleList: this.fakeDetailList
      }).then(() => {
        this.$message.success('保存成功')
      })
    },
    changeItemTitle(val) {
      if(!val.moduleName) {
        this.$message.warning('栏目名称不能为空')
        val.moduleName = val.moduleNameReal
      }
      //  else {
      //   if(this.fakeDetailList.filter(o => {return o.moduleName === val.moduleName}).length > 0) {
      //     this.$message.warning('已存在相同栏目名称')
      //      val.moduleName = val.moduleNameReal
      //   }
      // }
    }
  },
  mounted() {
    this.getList()
  }
};
</script>

<style scoped lang='scss'>
.contract {
  width: 800px; 
  .fake-box {
    height: 700px;
    border: 1px solid #EBEEF5;
    border-top: 0;
    .left {
      flex: 0 0 60%;
      border-right: 1px solid #EBEEF5;
      .l-box {
        height: 100%;
      }
    }
    .right {
      flex: 0 0 40%;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      .dynamic-box {
        height: 700px;
        overflow: hidden;
        // &:hover {
        //   border: 1px solid #409eff;
        //   box-sizing: border-box;
        // }
      }
      ::v-deep {
        .el-input__inner {
          width: 80%;
          border: 0;
          background: #fafafa;
          text-align: center;
          color: rgb(51, 51, 51);
          font-size: 14px;
          font-weight: 600;
        }
        .el-textarea__inner {
          border: 0;
          padding-left: 0;
        }
      }
      .item {
        .f-label {
          border-left: 0;
          position: relative;
          &:hover {
            border: 1px solid #409eff;
            cursor: pointer;
            .del-btn {
              display: block;
            }
          }
          .del-btn {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            // color: #409eff;
            display: none;
          }
        }
        .r-box {
          // height: 100%;
          min-height: 30px;
          // max-height: 250px;
          overflow: auto;
          .label {
            width: 80px;
            margin-top: 5px;
            text-align: right;
          }
          .p-item {
            .p-del-btn {
              display: none;
            }
            &:hover {
              background: #f3f8ff;
              .p-del-btn {
                display: block;
              }
            }
          }
        }
        .r-box2 {
          .p-add-btn {
            display: none;
          }
          &:hover {
            .p-add-btn {
              display: block;
            }
          }
        }
      }
    }
    .f-label {
      position: relative;
      color: rgb(51, 51, 51);
      text-align: center;
      font-weight: 600;
      // height: 40px;
      min-width: 110px;
      word-break: keep-all;
      border: 1px solid #EBEEF5;
      // border-top: 0;
      padding: 4.5px 10px;
      line-height: 1.5;
      background: #fafafa;
      box-sizing: border-box;
    }
  }
}
.list2-item {
  padding: 5px 15px;
  &:hover {
    background: #ecf5ff;
    color: #409eff;
  }
}
.fake-line {
  border-left: 1px solid #EBEEF5;
  border-right: 1px solid #EBEEF5;
  box-sizing: border-box;
  text-align: center;
  font-weight: normal;
  line-height: 42px;
  background: #f1f1f1;
  -webkit-print-color-adjust: exact;
}
.bb-0 {
  border-bottom: 0;
}
.c-1 {
  color: #212121;
}
.c-2 {
  color: #a4a4a4;
}
.c-3 {
  color: #606266;
}
.c-theme {
  color: #409eff;
}
.c-red {
  color: red;
}
</style>
