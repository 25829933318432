import { render, staticRenderFns } from "./meetingRoomTags.vue?vue&type=template&id=2641e676&scoped=true"
import script from "./meetingRoomTags.vue?vue&type=script&lang=js"
export * from "./meetingRoomTags.vue?vue&type=script&lang=js"
import style0 from "./meetingRoomTags.vue?vue&type=style&index=0&id=2641e676&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2641e676",
  null
  
)

export default component.exports