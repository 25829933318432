<template>
  <el-dialog :title="form.id ? '编辑任务' : '新增任务'" :visible.sync="visible" width="600px" :before-close="hideDialog" top="5vh">
    <div>
      <div class="form-item flex flex-center">
        <div class="form-label">
          <span class="must">*</span>
          任务内容
        </div>
        <el-input class="flex-1" v-model="form.taskContent" size="small" placeholder="请输入任务内容" :disabled="!detail.id && !!detail.taskContent"></el-input>
      </div>
      <div class="form-item flex">
        <div class="form-label">
          任务描述
        </div>
        <el-input class="flex-1" v-model="form.taskDescribe" size="small" placeholder="请输入任务描述" type="textarea" maxlength="255" show-word-limit rows="3"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <div class="form-label">
          <span class="must">*</span>
          适用宴会
        </div>
        <el-select class="flex-1" v-model="form.meetingTypeCodeList" multiple size="small" placeholder="请选择类型">
          <el-option v-for="item in meetingTypeList" :key="item.code" :value="item.code" :label="item.meetingTypeName"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-align-center">
        <div class="form-label">
          <span class="must">*</span>
          宴会日期距离签约大于
        </div>
        <el-input v-model="form.preMeetingDate" size="small" style="width: 100px" @input="handleInput('preMeetingDate')"></el-input>
        <span class="ml-10">天</span>
        <el-tooltip class="item" effect="dark" content="相同宴会类型时，优先分派天数大的任务规则" placement="right">
          <span class="el-icon-warning-outline ml-10"></span>
        </el-tooltip>
      </div>
      <div class="form-item flex">
        <div class="form-label">
          <span class="must">*</span>
          要求完成时间
        </div>
        <div class="flex-1">
          <div>
            <div class="mb-20">
              <el-select v-model="form.finishTimeType" size="small" placeholder="请选择" style="width: 250px">
                <el-option v-for="item in betweenTypeOption" :key="item.k" :value="item.k * 1" :label="item.v"></el-option>
              </el-select>
              <el-input class="ml-10" v-model="form.finishTimeNum" size="small" style="width: 100px" v-show="form.finishTimeType != 1" @input="handleInput('finishTimeNum')"></el-input>
              <span class="ml-10" v-show="form.finishTimeType != 1">天</span>
            </div>
            <div>
              <el-time-picker size="small" style="width: 200px"
                v-model="form.finishTime"
                value-format="HH:mm"
                format="HH:mm"
                placeholder="请选择时间">
              </el-time-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="form-item flex flex-align-center">
        <div class="form-label">
          <span class="must">*</span>
          任务提醒时间
        </div>
        <el-select class="flex-1" v-model="form.reminderTimeType" size="small" placeholder="请选择任务提醒时间">
          <el-option v-for="item in remindList" :key="item.k" :value="item.k * 1" :label="item.v"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-align-center">
        <div class="form-label">
          <span class="must">*</span>
          任务负责部门
        </div>
        <el-select class="flex-1" v-model="form.departmentKey" size="small" placeholder="请选择任务负责部门">
          <el-option v-for="item in departmentList" :key="item.code" :value="item.code" :label="item.value"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-align-center">
        <div class="form-label">
          <span class="must">*</span>
          任务负责人
        </div>
        <el-select class="flex-1" v-model="form.principal" size="small" placeholder="请选择任务负责人">
          <el-option value="SALER_FOLLOW" label="销售跟进人"></el-option>
          <el-option value="PLANER_FOLLOW" label="策划跟进人"></el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-align-center">
        <div class="form-label">
          完成时自动设置服务阶段
        </div>
        <el-select class="flex-1" v-model="form.stageId" size="small" placeholder="请选择服务阶段">
          <el-option value="0" label="不切换"></el-option>
          <el-option value="-1" label="无"></el-option>
          <el-option v-for="item in serverStage" :key="item.id" :value="item.id" :label="item.stageName"></el-option>
        </el-select>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="hideDialog">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { FormValid } from "@/utils/formValid";
import { get_key_value } from '@/api/common'
import {
  add_task_config,
  update_task_config,
  get_getDepartmentInitList
} from '@/api/base'
const form = {
  id: '',
  taskContent: '',
  taskDescribe: '',
  meetingTypeCodeList: [],
  meetingType: '',
  preMeetingDate: '',
  finishTimeType: 3,
  finishTimeNum: '',
  finishTime: '',
  reminderTimeType: '',
  stageId: '0',
  departmentKey: '',
  principal: 'PLANER_FOLLOW',
}
export default {
  components: {
  },
  props: {
    visible: {
      type: Boolean
    },
    detail: {
      type: Object
    },
    meetingTypeList: {
      type: Array,
      default: () => []
    },
    serverStage: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      betweenTypeOption: [],
      departmentList: [], // 部门列表
      remindList: []
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = this.$deepClone(this.detail)
        if (this.detail.id) {
          if (this.form.finishTimeNum == -1) {
            this.form.finishTimeNum = ''
          }
          this.form.meetingTypeCodeList = this.form.meetingTypeCodeList || []
        }
      } else {
        this.form = this.$deepClone(form)
      }
    }
  },
  computed: {
    checkFormList() {
      let arr = [
        {
          label: '模板名称',
          value: 'taskContent',
          methods: ['notEmpty']
        },
        {
          label: '宴会类型',
          value: 'meetingTypeCodeList',
          methods: ['notEmpty']
        },
        {
          label: '宴会日期距离签约天数',
          value: 'preMeetingDate',
          methods: ['notEmpty', 'isNumber']
        },
        {
          label: '要求完成时间',
          value: 'finishTimeType',
          methods: ['notEmpty']
        },
        {
          label: '要求完成时间',
          value: 'finishTime',
          methods: ['notEmpty']
        },
        {
          label: '任务提醒时间',
          value: 'reminderTimeType',
          methods: ['notEmpty']
        },
        {
          label: '任务负责部门',
          value: 'departmentKey',
          methods: ['notEmpty']
        },
        {
          label: '任务负责人',
          value: 'principal',
          methods: ['notEmpty']
        }
      ]
      if (this.form.finishTimeType != 1) {
        arr.push({
          label: '要求完成时间',
          value: 'finishTimeNum',
          methods: ['notEmpty', 'isNumber']
        })
      }
      return arr
    }
  },
  mounted() {
    this.getOption()
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
      this.$emit('update:detail', {})
    },
    getOption() {
      get_getDepartmentInitList().then(res => {
        this.departmentList = (res || []).filter(o => { return o.flag })
      })
      // 任务提醒时间 
      get_key_value({
        type: 'REMINDER_TIME_TYPE'
      }).then(res => {
        this.remindList = (res ? res.records : []).filter(o => { return o.flag })
      })
      // 任务自动分派要求完成时间 
      get_key_value({
        type: 'TASK_AUTO_SEND_FINISH_TIME_TYPE'
      }).then(res => {
        this.betweenTypeOption = (res ? res.records : []).filter(o => { return o.flag })
      })
    },
    handleInput(val) {
      this.form[val] = this.form[val].replace(/[^\d]/g,'')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      if (this.form.id) {
        update_task_config(params).then(() => {
          localStorage.setItem('departmentId', params.departmentId)
          localStorage.setItem('principal', params.principal)
          this.$message.success('保存成功')
          this.$emit('confirm')
          this.hideDialog()
        })
      } else {
        add_task_config(params).then(() => {
          localStorage.setItem('departmentId', params.departmentId)
          localStorage.setItem('principal', params.principal)
          this.$message.success('保存成功')
          this.$emit('confirm')
          this.hideDialog()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  .must{
    color: #E14B38
  }
  .form-label{
    width: 160px;
    text-align: right;
    margin-right: 10px;
    line-height: 32px;
  }
  & + .form-item{
    margin-top: 20px;
  }
}
</style>