<template>
  <div ref="OrderScroll" v-loading="loading">
    <div class="page-sub-title flex flex-align-center">
      <span>订单服务管理相关规则设置</span>
      <span class="font-size-13 grey ml-10">修改后仅对新开户门店产生影响</span>
    </div>
    <div class="font-size-14 mt-20 s-title">
      <span>服务阶段设置</span>
      <span class="font-size-13 grey ml-10">对各宴会的服务进行阶段标记，方便了解筹备情况，删除后将取消相应订单的服务阶段标记，订单完成或取消时，服务阶段为空。</span>
    </div>
    <div>
      <draggable v-model="serverStage" chosenClass="chosen" forceFallback="true" group="item" handle=".mover" filter=".border-btn" animation="500" class="flex flex-wrap" @end="serverStageSort" :move="onMoveServerStage">
        <div
          class="tag-item"
          v-for="(tag, ti) in serverStage"
          :key="ti"
          :class="{'active': editItem && editItem.id == tag.id}"
          :data-id="tag.id"
        >
          <div class="flex flex-center plr-10" v-if="editItem && editItem.id == tag.id">
            <el-input
              class="tag-name-input"
              :ref="'tag' + tag.id"
              v-model="editItem.stageName"
              size="small"
              maxlength="6"
              @blur="saveServerStage()"
            >
            </el-input>
            <span class="el-icon-check" @click.stop="saveServerStage()"></span>
          </div>
          <el-dropdown v-else trigger="click" placement="bottom" @command="handleCommand($event, tag)" style="width: 100%">
            <div class="flex flex-center plr-10">
              <span class="pointLine mover">
                <span class="point" v-for="(o, i) in 6" :key="i"></span>
              </span>
              <span class="tag-name flex-1">
                {{tag.stageName}}
              </span>
              <span class="tri"></span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-dropdown-item command="delete">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <el-button v-show="!editItem" class="border-btn mt-10" size="small" @click.stop="showAddServerStage">添加</el-button>
      </draggable>
    </div>
    <div class="font-size-14 mt-20 s-title">
      <span>服务任务自动派发规则</span>
      <span class="font-size-13 grey ml-10">满足任务条件的前提下，确认任务负责人时即派发该任务</span>
    </div>
    <div class="s-right-box">
      <div class="r-top">
        <div class="p-15 text-center" style="width: 11%;flex: initial">任务内容</div>
        <div class="p-15 text-center">宴会类型</div>
        <div class="p-15 text-center">任务描述</div>
        <div class="p-15 text-center">
          <span>宴会日期距离签约大于</span>
          <el-tooltip content="各宴会类型订单的宴会日期距离签约日期天数不同，可设置不同任务">
            <span class="el-icon-question"></span>
          </el-tooltip>
        </div>
        <div class="p-15 text-center">要求完成时间</div>
        <div class="p-15 text-center">任务负责部门</div>
        <div class="p-15 text-center">任务负责人</div>
        <div class="p-15 text-center">完成自动设置服务阶段</div>
        <div class="p-15 text-center">操作</div>
      </div>
      <draggable v-model="taskRuleList" chosenClass="item-chosen" forceFallback="true" group="item" handle=".mover" animation="500" filter=".cantdrag" @end="onEndDragRule">
        <transition-group>
          <div class="source-list" v-for="(v, i) in taskRuleList" :key="i" :data-id="v.id" :data-content="v.taskContent" :class="{'cantdrag': v.id == 'add'}">
            <div class="item" :style="{width: v.id !== 'add' ? '11%' : '100%', 'justify-content': 'flex-start'}">
              <div class="flex flex-align-center" v-if="v.id !== 'add'">
                <el-tooltip effect="dark" content="上下拖动排序" placement="left">
                  <span class="pointLine mover">
                    <span class="point" v-for="(o, i) in 6" :key="i"></span>
                  </span>
                </el-tooltip>
                <span>{{ v.taskContent }}</span>
              </div>
              <el-link v-else :underline="false" type="primary" @click="showEditRuleModal(v)">+ 添加任务内容</el-link>
            </div>
            <div style="flex: 1" v-if="v.id !== 'add'">
              <div v-for="(o, i) in v.list" :key="i" class="source-list" v-show="o.id !== 'add'">
                <div class="item">
                  {{o.meetingTypeNameList.join(',')}}
                </div>
                <div class="item">
                  <el-tooltip :content="o.taskDescribe">
                    <div class="line-3">{{o.taskDescribe}}</div>
                  </el-tooltip>
                </div>
                <div class="item">
                  {{o.preMeetingDate}}天
                </div>
                <div class="item">
                  {{betweenTypeMap[o.finishTimeType]}}
                  {{o.finishTimeType == 1 ? '' : o.finishTimeNum + '天'}}
                </div>
                <div class="item">
                  {{o.departmentName}}
                </div>
                <div class="item">
                  {{salerMap[o.principal]}}
                </div>
                <div class="item">
                  <span v-if="o.stageId == -1">无</span>
                  <span v-else-if="o.stageId == 0">不切换</span>
                  <span>{{o.stageName}}</span>
                </div>
                <div class="item">
                  <el-link :underline="false" type="primary" @click="showEditRuleModal(o)">编辑</el-link>
                  <el-link :underline="false" type="danger" class="ml-10" @click="handleDeleteTaskRlue(o)">删除</el-link>
                </div>
              </div>
              <div class="source-list">
                <div class="item" style="justify-content: flex-start;">
                  <el-link :underline="false" type="primary" @click="showEditRuleModal(v.list[v.list.length - 1])">+ 添加规则</el-link>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <div class="font-size-14 mt-20 s-title">
      <span>单期类型划分规则</span>
      <div class="font-size-13 grey ml-10 flex-1">
        规则开启后，在订单列表或者详情中对应展示订单单期类型；业绩可根据单期类型进行宴会执行单分布情况监控，设置周期不可重合，修改后，已完成的订单单期类型不变更
      </div>
    </div>
    <el-table header-row-class-name="table-header" :data="singlePeriodList" border style="width: 100%" :span-method="objectSpanMethod">
      <el-table-column prop="status" label="规则开启" align="center" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="singlePeriod[0].flag">
          </el-switch>
          <i :class="scope.row.type"></i>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" align="center" width="80"></el-table-column>
      <el-table-column prop="supplementRoleName" align="left" label="规则内容">
        <template slot-scope="scope">
          <div v-if='scope.row.type === "长单"'>
                在距离宴会日期前
            <el-select v-model="singlePeriod[1].v" @change="$forceUpdate()" placeholder="" size="small" class="inline mlr-5" style="width: 100px">
              <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
            </el-select>
            月以上签约即为长单（包括刚好{{singlePeriod[1].v}}个月）。
          </div>
          <div v-if='scope.row.type === "中单"'>
            在距离宴会日期前
            <el-select v-model="singlePeriod[2].v" @change="$forceUpdate()" placeholder="" size="small" class="inline mlr-5" style="width: 100px">
              <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
            </el-select>
            -
            <el-select v-model="singlePeriod[3].v" @change="$forceUpdate()" placeholder="" size="small" class="inline mlr-5" style="width: 100px">
              <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
            </el-select>
            月签约即为中单（不包括{{singlePeriod[2].v}}个月和{{singlePeriod[3].v}}个月）。
          </div>
          <div v-if='scope.row.type === "短单"'>
            在距离宴会日期前
            <el-select v-model="singlePeriod[4].v" @change="$forceUpdate()" placeholder="" size="small" class="inline mlr-5" style="width: 100px">
              <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
            </el-select>
            月以内签约即为短单。
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-10">
      <el-button size="small" type="primary" @click="handleUpdateSingle()">保存</el-button>
    </div>
    <!-- <div class="font-size-14 mt-20 s-title">
      <span>订单修改审批流规则</span>
      <span class="font-size-13 grey ml-10">服务过程中对场次信息的修改及相关功能可设置审批流</span>
    </div>
    <el-table
      style="width: 100%"
      :data="flowList"
      border
      header-row-class-name="table-header"
      ref="flowTable"
    >
      <el-table-column label="开启审批" width="80" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.enableFlag"
            @change="handleChangeFlowEnableFlag($event, scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="审批流触发条件" prop="conditionName" align="center" min-width="150"></el-table-column>
      <el-table-column label="适用宴会类型" align="center" min-width="150">
        <template slot-scope="scope">
          <el-popover
            width="200"
            trigger="click"
            v-model="scope.row.visible3"
            @show="showPopover(scope.row)">
            <div slot="reference">
              <span>{{scope.row.meetingTypeNames}}</span>
              <span class="el-icon-edit-outline ml-10"></span>
            </div>
            <div>
              <div>
                <el-checkbox v-model="meetingTypeCodeSameFlag">其他规则统一配置为该选项</el-checkbox>
              </div>
              <div class="user-list">
                <div
                  class="flex flex-center"
                  v-for="item in meetingTypeList"
                  :key="item.code"
                  @click.stop="changeMeetingType(item.code)"
                  :class="{'checked': selectInfo.meetingTypeCodeList && selectInfo.meetingTypeCodeList.indexOf(item.code) > -1}"
                >
                  <span class="flex-1">{{item.meetingTypeName}}</span>
                  <span class="el-icon-check"></span>
                </div>
              </div>
              <div>
                <el-button style="width: 100%" size="small" type="primary" @click.stop="handleChangeMeetingType(scope.row)">确定</el-button>
              </div>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="审批人员" align="center" min-width="150">
        <template>
          默认开户人员
        </template>
      </el-table-column>
      <el-table-column label="抄送至" align="center" min-width="150">
        <template>
          默认为空，自行设置
        </template>
      </el-table-column>
      <el-table-column label="无需审批人员" align="center" min-width="150">
        <template>
          默认开户人员
        </template>
      </el-table-column>
      <el-table-column label="审批通过后抄送" align="center" min-width="150">
        <template slot="header">
          <div>
            <span>审批通过后抄送</span>
            <el-tooltip content="开启开关，审批通过后方抄送；关闭开关，发起审批时一同抄送">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-switch v-model="scope.row.passCopyFlag" @change="handleChangePassCopyFlag($event, scope.row)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="审批方式" align="center" min-width="230">
        <template slot="header">
          <div>
            <span>审批方式</span>
            <el-tooltip content="通过审批的方式">
              <span class="el-icon-question"></span>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-select style="width: 100%" size="small" v-model="scope.row.approveType" @change="saveFlow(scope.row)">
            <el-option value="AND" label="会签（需所有审批人通过）"></el-option>
            <el-option value="OR" label="或签（一名审批人通过即可）"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table> -->
    <!-- <div class="font-size-14 mt-20 s-title">
      <span>二销业绩计算规则</span>
      <span class="font-size-13 grey ml-10">订单服务过程中的二销业绩计算规则，业绩统计时按照该规则统计业绩</span>
    </div>
    <div class="table-tr flex flex-align-center">
      <div class="b-1 p-15 flex-1 text-center">二销业绩归属人员</div>
      <div class="p-15 flex-3">
        <el-radio-group v-model="personInCharge" size="small">
          <el-radio label="SALER_FOLLOW">销售跟进人</el-radio>
          <el-radio label="PLANER_FOLLOW">策划跟进人</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="text-center mt-10">
      <el-button type="primary" size="small" @click="saveSecondSale">保存</el-button>
    </div> -->
    <!-- <div class="font-size-14 mt-20 s-title">
      <span>回款负责人设置</span>
      <span class="font-size-13 grey ml-10">设置门店的合同回款负责人身份，权责明确，修改仅影响后续新增订单</span>
    </div>
    <div class="table-tr flex flex-align-center" v-for="item in chargeList" :key="item.contractType">
      <div class="b-1 p-15 flex-1 text-center">{{contractTypeMap[item.contractType]}}</div>
      <div class="p-15 flex-3">
      <el-radio-group v-model="item.personInCharge" size="small">
        <el-radio label="SALER_FOLLOW">销售跟进人</el-radio>
        <el-radio label="PLANER_FOLLOW">策划跟进人</el-radio>
      </el-radio-group>
      </div>
    </div>
    <div class="text-center mt-10">
      <el-button type="primary" size="small" @click="saveChargeUser">保存</el-button>
    </div> -->

    <task-rule-add-dialog
      :visible.sync="addTaskRuleVisible"
      :detail.sync="taskRuleEditData"
      :meetingTypeList="meetingTypeList"
      :serverStage="serverStage"
      @confirm="getTaskRuleList"
    ></task-rule-add-dialog>
  </div>
</template>

<script>
import {
  get_date_type_list,
  update_date_type,
  // get_approval_flow_list,
  // update_approval_flow,
  // get_chargeUser,
  // update_chargeUser,
  // get_secondSale_achievement,
  // update_secondSale_achievement,
  get_task_config_list,
  delete_task_config,
  sort_task_config,
  get_getMeetingTypeInitList,
  get_server_stage_list,
  update_server_stage_list,
  // get_SecondSaleChargeUser,
  // set_SecondSaleChargeUser
} from '@/api/base'
import draggable from 'vuedraggable'
import taskRuleAddDialog from './task-rule-add-dialog'
import { get_key_value } from '@/api/common'

export default {
  components: {
    draggable,
    taskRuleAddDialog
  },
  data() {
    return {
      meetingTypeList: [],
      // selectInfo: {},
      // meetingTypeCodeSameFlag: false,
      // flowList: [],
      // personInCharge: '',
      // chargeList: [],
      // contractTypeMap: {
      //   1: '宴席合同',
      //   2: '服务合同',
      //   3: '一站式合同',
      //   4: '二销确认单'
      // },
      singlePeriodData: [],
      singlePeriod: [
        {
          k: 'ORDER_DATE_TYPE',
          flag: false
        },
        {
          k: 'ORDER_DATE_TYPE_LONG',
          v: ''
        },
        {
          k: 'ORDER_DATE_TYPE_MID_START',
          v: ''
        },
        {
          k: 'ORDER_DATE_TYPE_MID_END',
          v: ''
        },
        {
          k: 'ORDER_DATE_TYPE_SHORT',
          v: ''
        }
      ],
      singlePeriodList: [
        {
          status: '',
          type: '长单',
        },
        {
          status: '',
          type: '中单',
        },
        {
          status: '',
          type: '短单',
        }
      ],
      taskRuleList: [],
      addTaskRuleVisible: false,
      taskRuleEditData: {},
      editItem: null,
      salerMap: {
        SALER_FOLLOW: '销售跟进人',
        PLANER_FOLLOW: '策划跟进人'
      },
      betweenTypeMap: {},
      serverStage: [], // 服务阶段
      loading: false,
    }
  },
  computed: {
  },
  mounted() {
    this.handelInit()
  },
  methods: {
    handelInit() {
      // this.getFlowList()
      // this.getChargeUser()
      // this.getSecondSale()
      this.getHotelConfigList()
      this.meetingType()
      this.getServerStage()
      this.getTaskTinishTimeType()
      this.getTaskRuleList()
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if(rowIndex <= 3) {
        if (columnIndex === 0 && rowIndex === 0) {
          return [3, 1]
        } else if (rowIndex >= 1 && columnIndex === 0){
          return [0, 0];
        }
      }
    },
    getTaskTinishTimeType() {
      // 任务自动分派要求完成时间 
      get_key_value({
        type: 'TASK_AUTO_SEND_FINISH_TIME_TYPE'
      }).then(res => {
        const betweenTypeOption = (res ? res.records : []).filter(o => { return o.flag })
        this.betweenTypeMap = this.$arrayToMap(betweenTypeOption)
      })
    },
    //获取宴会类型
    meetingType() {
      get_getMeetingTypeInitList().then(res => {
        this.meetingTypeList = (res || []).filter(o => { return o.flag })
      })
    },
    // handleChangeMeetingType(row) {
    //   if (this.selectInfo.meetingTypeCodeList.length == 0) {
    //     this.$confirm(`若未选择适用宴会类型，将会关闭${this.selectInfo.conditionName}开关`, '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       customClass: 'dialog-class',
    //       center: true
    //     }).then(() => {
    //       this.saveFlow({
    //         ...this.selectInfo,
    //         meetingTypeCodeSameFlag: false,
    //       })
    //       this.selectInfo = {}
    //     }).catch(() => {
    //       row.visible3 = true
    //       this.$nextTick(() => {
    //         this.selectInfo.meetingTypeCodeList = []
    //       })
    //     })
    //   } else {
    //     this.saveFlow({
    //       ...this.selectInfo,
    //       meetingTypeCodeSameFlag: this.meetingTypeCodeSameFlag,
    //     })
    //   }
    // },
    // showPopover(row) {
    //   this.meetingTypeCodeSameFlag = false
    //   if (row.visible3) {
    //     this.selectInfo = this.$deepClone(row)
    //   } else {
    //     this.selectInfo = {}
    //   }
    // },
    // changeMeetingType(id) {
    //   if (this.selectInfo.meetingTypeCodeList) {
    //     const index = this.selectInfo.meetingTypeCodeList.indexOf(id)
    //     if (index > -1) {
    //       this.selectInfo.meetingTypeCodeList.splice(index, 1)
    //     } else {
    //       this.selectInfo.meetingTypeCodeList.push(id)
    //     }
    //   } else {
    //     this.selectInfo.meetingTypeCodeList = []
    //     this.selectInfo.meetingTypeCodeList.push(id)
    //   }
    // },
    //获取单期类型划分规则
    getHotelConfigList() {
      get_date_type_list().then(res => {
        this.singlePeriodData = res
        
        this.singlePeriodData.map(o => {
          if (o.k === 'ORDER_DATE_TYPE') {
            this.$set(this.singlePeriod, 0, o)
          } else if (o.k === 'ORDER_DATE_TYPE_LONG') {
            this.$set(this.singlePeriod, 1, o)
          } else if (o.k === 'ORDER_DATE_TYPE_MID_START') {
            this.$set(this.singlePeriod, 2, o)
          } else if (o.k === 'ORDER_DATE_TYPE_MID_END') {
            this.$set(this.singlePeriod, 3, o)
          } else if (o.k === 'ORDER_DATE_TYPE_SHORT') {
            this.$set(this.singlePeriod, 4, o)
          }
        })
      })
    },
    handleUpdateSingle() {
      if (+this.singlePeriod[1].v >= +this.singlePeriod[3].v && +this.singlePeriod[2].v < +this.singlePeriod[3].v && +this.singlePeriod[2].v >= +this.singlePeriod[4].v) {
        update_date_type(this.singlePeriod).then(() => {
          this.$message.success('保存成功')
          this.getHotelConfigList()
        })
      } else {
        this.$message.warning('请设置正确的规则')
      }
    },
    // getFlowList() {
    //   get_approval_flow_list().then(res => {
    //     this.flowList = res || []
    //   })
    // },
    // handleChangeFlowEnableFlag(value, row) {
    //   const meetingTypeCodeList = row.meetingTypeCodeList ||[]
    //   if (value && (meetingTypeCodeList.length == 0)) {
    //     this.$message.warning('请先配置宴会类型和审批人员')
    //     row.enableFlag = false
    //   } else {
    //     row.passCopyFlag = false
    //     this.saveFlow(row)
    //   }
    // },
    // handleChangePassCopyFlag(value, row) {
    //   if (value && !row.enableFlag) {
    //     this.$message.warning('请先开启该项审批开关')
    //     row.passCopyFlag = false
    //   } else {
    //     this.saveFlow(row)
    //   }
    // },
    // saveFlow(row) {
    //   const {
    //     id,
    //     meetingTypeCodeList,
    //     enableFlag,
    //     meetingTypeCodeSameFlag,
    //     approveType,
    //     passCopyFlag,
    //   } = row
    //   update_approval_flow({
    //     id,
    //     meetingTypeCodeList,
    //     enableFlag,
    //     meetingTypeCodeSameFlag,
    //     approveType,
    //     passCopyFlag,
    //   }).then(() => {
    //     this.$message.success('保存成功')
    //     this.getFlowList()
    //   })
    // },
    // getChargeUser() {
    //   get_chargeUser().then(res => {
    //     this.chargeList = res
    //     this.getSecondSaleChargeUser()
    //   })
    // },
    // saveChargeUser() {
    //   let _list = this.chargeList.slice(0,3)
    //   update_chargeUser(_list).then(() => {
    //     this.$message.success('保存成功')
    //     this.getChargeUser()
    //   })
    //   set_SecondSaleChargeUser({
    //     personInCharge: this.chargeList.filter(o => {return o.contractType === 4})[0].personInCharge
    //   }).then(() => {
    //     this.getChargeUser()
    //   })
    // },
    // getSecondSale() {
    //   get_secondSale_achievement().then(res => {
    //     this.personInCharge = res.personInCharge
    //   })
    // },
    // saveSecondSale() {
    //   update_secondSale_achievement({
    //     personInCharge: this.personInCharge
    //   }).then(() => {
    //     this.$message.success('保存成功')
    //     this.getSecondSale()
    //   })
    // },
    getServerStage() {
      get_server_stage_list().then(res => {
        this.serverStage = res || []
      })
    },
    updateServerStage(deleteId) {
      let orderServerStageList = this.serverStage.concat()
      if (this.editItem) {
        orderServerStageList.map(o => {
          if (o.id == this.editItem.id) {
            o.stageName = this.editItem.stageName
          }
          if (o.id == 'add') o.id = ''
        })
      }
      this.loading = true
      update_server_stage_list({
        orderServerStageList: orderServerStageList.map((o, i) => {
          return {
            ...o,
            sort: i + 1
          }
        }),
        needRemoveIdList: deleteId ? [deleteId] : []
      }).then(() => {
        this.editItem = null
        this.$message.success(deleteId ? '删除成功' : '保存成功')
        this.getServerStage()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleCommand(val, item) {
      if (val == 'edit') {
        this.editItem = this.$deepClone(item)
        this.$nextTick(() => {
          this.$refs['tag' + item.id][0].$refs.input.focus();
        });
      } else if (val == 'delete') {
        this.$confirm('是否确认删除该服务阶段', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'dialog-class',
          center: true
        }).then(() => {
          this.updateServerStage(item.id)
        })
      }
    },
    showAddServerStage() {
      const newTag = {
        id: 'add',
        stageName: ''
      }
      this.editItem = this.$deepClone(newTag)
      this.serverStage.push(newTag)
      this.$nextTick(() => {
        this.$refs['tag' + newTag.id][0].$refs.input.focus();
      });
    },
    saveServerStage() {
      if (this.editItem.stageName == '') {
        if (this.editItem.id == 'add') {
          this.serverStage.splice(this.serverStage.length - 1, 1)
          this.editItem = null
          return
        } else {
          return this.$message.warning('服务阶段不可为空')
        }
      }
      this.updateServerStage()
    },
    onMoveServerStage(e) {
      return !!e.relatedContext.element
    },
    serverStageSort() {
      this.updateServerStage()
    },
    getTaskRuleList() {
      const parmas = {
        id: 'add',
        taskContent: '',
        meetingTypeCodeList: [],
        meetingTypeNameList: [],
        preMeetingDate: '',
        finishTimeType: 3,
        finishTimeNum: '',
        finishTime: '',
        reminderTimeType: '',
        stageId: '0',
        stageName: '',
        departmentId: localStorage.getItem('departmentId') || '',
        departmentName: '',
        principal: localStorage.getItem('principal') || 'PLANER_FOLLOW',
        span: 1
      }
      get_task_config_list().then(res => {
        let arr = res || []
        let obj = {}, num = 1
        let arrCopy = this.$deepClone(arr)
        arr.map((o, i) => {
          if (o.taskContent) {
            if (!obj[o.taskContent]) {
              obj[o.taskContent] = 1
            }
            obj[o.taskContent]++
            if ((arr[i + 1] && arr[i + 1].taskContent != o.taskContent) || !arr[i + 1]) {
              arrCopy.splice(i + num, 0, {
                ...parmas,
                taskContent: o.taskContent
              })
              num++
            }
          }
        })
        this.taskRuleList = []
        arrCopy.map(o => {
          const index = this.taskRuleList.findIndex(v => v.taskContent == o.taskContent)
          if (index < 0) {
            this.taskRuleList.push({
              id: o.id,
              taskContent: o.taskContent,
              list: [{
                ...o
              }]
            })
          } else {
            this.taskRuleList[index].list.push(o)
          }
        })
        this.taskRuleList.push({
          ...parmas,
          list: []
        })
      })
    },
    showEditRuleModal(item) {
      if (item.id == 'add') {
        this.taskRuleEditData = {
          ...item,
          id: '',
        }
      } else {
        this.taskRuleEditData = this.$deepClone(item)
      }
      this.addTaskRuleVisible = true
    },
    handleDeleteTaskRlue(item) {
      console.log(item)
      this.$confirm('是否确认删除该任务规则', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        center: true
      }).then(() => {
        delete_task_config({
          id: item.id
        }).then(async () => {
          this.$message.success('删除成功')
          this.getTaskRuleList()
        })
      })
    },
    onEndDragRule(val) {
      console.log(val)
      sort_task_config({
        taskContent: val.clone.dataset.content,
        primaryIndex: val.newIndex
      }).then(() => {
        this.getTaskRuleList()
      })
    },
    // //二销合同汇款负责人
    // getSecondSaleChargeUser() {
    //   get_SecondSaleChargeUser().then(res => {
    //     this.chargeList.push({
    //       contractType: 4,
    //       personInCharge: res.personInCharge
    //     })
    //     this.$forceUpdate()
    //     // console.log(res)
    //   })
    // },
  },
  beforeDestroy() {
  }
}
</script>

<style scoped lang="scss">
.s-title {
  padding: 15px 15px 15px 25px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  position: relative;
  ::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #aaaaaa;
    border-radius: 6px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.b-1 {
  background: #f5f6fb;
}
.pointLine {
  opacity: 0;
  width: 7px;
  height: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: 10px;
  padding: 4px;
  &:hover {
    background: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    .point {
      background: $color;
    }
  }
  .point {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    background: #999999;
    margin-right: 1px;
    flex: 0 0 30%;
  }
  .active {
    background: $color;
  }
}
.table-tr{
  border: 1px solid #EBEEF5;
  border-top: 0;
  .td{
    padding: 15px;
    position: relative;
    border-left: 1px solid #EBEEF5;
    &:first-child{
      border-left: 0;
    }
  }
  &:hover {
    .pointLine {
      opacity: 1;
    }
  }
}
.miniMenu {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: $color;
  cursor: pointer;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;
  i {
    color: white;
  }
}
.menu {
  position: fixed;
  right: 40px;
  bottom: 40px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 15px 0;
  z-index: 999;
  div {
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      color: $color;
      background: #F2F6FC;
    }
  }
  .scrollActive {
    color: $color;
    background: #F2F6FC;
  }
}
.user-list{
  overflow: auto;
  max-height: 200px;
  margin: 10px -12px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  & > div{
    padding: 10px 12px;
    &:hover{
      background: #F2F6FC;
      color: $color;
      cursor: pointer;
    }
    .el-icon-check{
      display: none;
    }
    &.checked{
      color: $color;
      .el-icon-check{
        display: block;
      }
    }
  }
}
.el-icon-edit-outline{
  cursor: pointer;
  &:hover{
    color: $color;
  }
}
.tri{
  opacity: 0;
  border-top: 4px solid $color;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  display: inline-block;
  margin-left: 15px;
}
.tag-item{
  display: inline-block;
  font-size: 12px;
  position: relative;
  white-space: nowrap;
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  min-width: 100px;
  margin: 10px 10px 0 0;
  .tag-name{
    line-height: 30px;
    height: 30px;
    position: relative;
    text-align: center;
  }
  .tag-name-input{
    width: 80px;
    padding: 0 20px 0 10px;
    position: relative;
    ::v-deep .el-input__inner{
      border: 0;
      padding: 0;
      font-size: 12px;
    }
  }
  .el-icon-check{
    color: $color;
    margin-left: 10px;
    font-weight: bold;
  }
  &:hover{
    background: #F3F9FF;
    border: 1px solid #CBE5FF;
    color: $color;
    cursor: pointer;
    .tag-name{
      color: $color;
    }
    .pointLine {
      opacity: 1;
      .point {
        background: $color;
      }
    }
    .tri{
      opacity: 1;
    }
  }
  &.active{
    background: #F3F9FF;
    border: 1px solid #CBE5FF;
    color: $color;
    cursor: pointer;
    .tag-name-input{
      ::v-deep .el-input__inner{
        background: #F3F9FF;
      }
    }
  }
}

.s-right-box {
  overflow: auto;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  .r-top {
    display: flex;
    width: 100%;
    & > div {
      flex: 1;
      border-right: 1px solid #eeeeee;
      color: #909399;
      font-weight: bold;
      background: #f5f6fb;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      box-sizing: border-box;
      &:last-child {
        border-width: 0;
      }
    }
  }
  .source-list {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }
    &:first-child {
      border-top: 1px solid #eeeeee;
    }
    & > .item {
      padding: 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      border-right: 1px solid #eeeeee;
      color: #606266;
      font-size: 14px;
      position: relative;
      &:last-child {
        border-width: 0;
      }
      .pointLine {
        opacity: 0;
        width: 7px;
        height: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4px;
        margin-left: 5px;
        flex-shrink: 0;
        &:hover {
          background: rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          .point {
            background: #409eff;
          }
        }
        .point {
          width: 2px;
          height: 2px;
          border-radius: 2px;
          background: #999999;
          margin-right: 1px;
          flex: 0 0 30%;
        }
        .active {
          background: #409eff !important;
        }
      }
      &:hover {
        .pointLine {
          opacity: 1;
        }
      }
    }
    .source-list{
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        border-top: none;
      }
      .item{
        flex: 1;
      }
    }
  }
  .item-chosen{
    background: #ffffff !important;
  }
}
</style>