<template>
  <div class="discount">
    <div class="flex flex-between">
      <div class="page-sub-title flex flex-center mb-0" v-if="sourceType === 1">
        <span class="flex-1">门店角色初始化</span>
      </div>
      <div class="flex flex-align-center" v-if="sourceType === 1">
        <el-input class="flex-1 mr-10" clearable v-model="searchForm" placeholder="请输入角色名称搜索" size="small" @keyup.enter.native="getList(searchForm)"></el-input>
        <el-button size="small" type="primary" @click="getList(searchForm)">搜索</el-button>
      </div>
    </div>
    <div v-if="sourceType === 2" class="page-sub-title font-size-14">{{ selectInfo.roleName }}角色设置</div>
    <div class="mt-20 flex" v-if="sourceType === 1">
      <div class="s-right-box flex-1">
        <div class="r-top flex flex-align-center b-1">
          <span class="p-15 flex-1 text-center">角色编号</span>
          <span class="p-15 flex-1 text-center">角色名称</span>
          <span class="p-15 flex-1 text-center">是否初始化</span>
          <span class="p-15 flex-1 text-center">是否可修改</span>
          <span class="p-15 flex-1 text-center">创建时间</span>
          <span class="p-15 flex-1 text-center">操作</span>
        </div>
        <div class="pl-20" :class="{'mt-10 mb-10':!isAdd}">
          <el-button v-if="!isAdd" size="small" class="border-btn" @click="isAdd = true">+ 新增角色</el-button>
          <div class="source-list2 pt-10 pb-10" v-if="isAdd">
            <span class="items">
              <el-input class="flex-1" v-model="form.roleName" placeholder="请输入角色名称" size="small" maxlength="8" show-word-limit></el-input>
            </span>
            <span class="items">
              <el-button size="small" type="primary" @click="addList">保存</el-button>
              <el-button size="small" class="border-btn" @click="isAdd = false">取消</el-button>
            </span>
          </div>
        </div>
        <!-- <draggable class="draggable" v-loading="loading" v-model="listData" chosenClass="chosen" forceFallback="true" group="item" handle=".mover" animation="500" @end="onEnd">
          <transition-group> -->
            <div class="source-list draggable" v-loading="loading" v-for="(o, i) in listData" :key="i" :data-id="o.id">
              <span class="item">
                <!-- <el-tooltip effect="dark" content="上下拖动排序" placement="left">
                  <span class="pointLine mover">
                    <span class="point" v-for="(o, i) in 6" :key="i"></span>
                  </span>
                </el-tooltip> -->
                <span style="min-width:92px">{{ o.roleCode }}</span>
                <!-- <div class="pl-10 pr-10">
                  <el-input maxlength="8" show-word-limit class="flex-1" v-model="o.value" size="small" @change="update(o)"></el-input>
                </div> -->
              </span>
              <span class="item">
                <div class="pl-10 pr-10">
                  <el-input maxlength="8" show-word-limit class="flex-1" v-model="o.roleName" size="small" @change="update(o)" :disabled="o.roleCode === 'R00'"></el-input>
                </div>
              </span>
              <span class="item">
                <el-switch
                  :disabled="o.roleCode === 'R00'"
                  @change="update(o)"
                  v-model="o.initFlag">
                </el-switch>
              </span>
              <span class="item">
                <el-select v-model="o.updateFlag" size="small" :class="o.updateFlag?'select':'select1'" @change="update(o)" :disabled="o.roleCode === 'R00'">
                  <el-option label="是" :value="true"> </el-option>
                  <el-option label="否" :value="false"> </el-option>
                </el-select>
              </span>
              <span class="item">
                {{o.createTime}}
              </span>
              <span class="item">
                <el-button class="mr-10" type="text" @click="selectInfo = o,sourceType = 2,$emit('change',sourceType)" v-if="o.roleCode !== 'R00'"
                  >配置权限</el-button
                >
                <el-popconfirm
                title="若删除该角色，新开户的酒店将不显示该角色，确定删除？"
                confirm-button-text="确认"
                cancel-button-text="取消"
                @confirm="deleteSelect(o)"
              >
                <el-button slot="reference" type="text" v-if="o.roleCode !== 'R00'"
                  >删除</el-button
                >
              </el-popconfirm> 
              </span>
            </div>
          <!-- </transition-group>
        </draggable> -->
      </div>
    </div>
    <div class="mt-20 flex flex-column" v-if="sourceType === 2">
        <el-tabs v-model="tabName" class="flex-1 flex flex-column right-tabs">
          <el-tab-pane label="操作权限" align="center" name="first">
            <el-table :data="tableData" style="width: 100%" border header-row-class-name="table-header" v-loading="loading" :cell-style="cellStyle">
              <el-table-column prop="belongProject" align="center" label="项目" width="180"> </el-table-column>
              <el-table-column prop="rolePower" label="操作权限">
                <template slot-scope="scope">
                  <div v-if="scope.row.belongProject != '零点订单管理'">
                    <el-checkbox v-model="scope.row.checked" @change="checkAll(scope.row)" :indeterminate="scope.row.isIndeterminate">全选</el-checkbox>
                    <el-checkbox v-for="(o, i) in scope.row.globalRolePermissionDetailList" :key="i" v-model="o.checked" @change="checkSingle(scope, o)">{{ o.note }}</el-checkbox>
                  </div>
                  <div v-else style="line-height: 35px">
                    <!-- pc -->
                     <div class="flex bt">
                       <div class="note br" style="text-indent:24px;">PC</div>
                       <div class="note br" style="width:91px">零点订单</div>
                       <div class="flex-7 pd">
                          <el-checkbox style="width:60px" v-model="zeroCheck.pc.checked"  @change="checkAll(scope.row.pc.zero,true,zeroCheck.pc)" :indeterminate="zeroCheck.pc.isIndeterminate">全选</el-checkbox>
                          <el-checkbox style="width:100px" v-for="(o, i) in scope.row.pc.zero"  :key="i" v-model="o.checked" @change="checkSingle(scope, o)">
                          <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                            <span>{{ o.note }}</span>
                          </el-tooltip>
                        </el-checkbox>
                       </div>
                     </div>
                     <!-- app -->
                      <div class="flex bt">
                       <div class="note br">
                          <el-checkbox style="width:100px" v-for="(o, i) in scope.row.app.isApp"  :key="i" v-model="o.checked" @change="checkSingle(scope, o)">
                          <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                            <span>APP</span>
                          </el-tooltip>
                        </el-checkbox>
                       </div>
                       <div class="flex flex-column br">
                         <div class="note bt" style="line-height:141px">预订桌位页</div>
                         <div class="note">零点订单</div>
                       </div>
                       <div class="flex-7">
                         <div>
                           <!-- 他人订单 -->
                           <div class="flex bt order-checkbox">
                             <span class="flex-shrink note br" style="width:103px">他人订单</span>
                             <div class="pd"><el-checkbox v-model="zeroCheck.app.table.others.checked" @change="checkAll(scope.row.app.table.others,true,zeroCheck.app.table.others)" :indeterminate="zeroCheck.app.table.others.isIndeterminate" :disabled="!scope.row.app.isApp[0].checked">全选</el-checkbox>
                          <el-checkbox v-for="(o, i) in scope.row.app.table.others" :disabled="!scope.row.app.isApp[0].checked" :key="i" v-model="o.checked" @change="checkSingle(scope, o)">
                          <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                            <span>{{ o.note }}</span>
                          </el-tooltip>
                        </el-checkbox></div>
                           </div>
                           <!-- 我的订单 -->
                           <div class="flex bt order-checkbox">
                              <span class="note br" style="width:140px">我的订单
                               <el-tooltip effect="dark" content="当前登录用户作为销售跟进人的零点订单" placement="top">
                                 <i class="el-icon-question"></i>
                               </el-tooltip>
                             </span>
                             <div class="pd"><el-checkbox v-model="zeroCheck.app.table.own.checked" @change="checkAll(scope.row.app.table.own,true,zeroCheck.app.table.own)" :indeterminate="zeroCheck.app.table.own.isIndeterminate" :disabled="!scope.row.app.isApp[0].checked">全选</el-checkbox>
                          <el-checkbox v-for="(o, i) in scope.row.app.table.own"  :key="i" v-model="o.checked" @change="checkSingle(scope, o)" :disabled="!scope.row.app.isApp[0].checked">
                          <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                            <span>{{ o.note }}</span>
                          </el-tooltip>
                        </el-checkbox></div>
                           </div>
                         </div>
                         <!-- 零点订单 -->
                         <div class="pd">
                           <el-checkbox style="width:60px" v-model="zeroCheck.app.zero.checked"  @change="checkAll(scope.row.app.zero,true,zeroCheck.app.zero)" :indeterminate="zeroCheck.app.zero.isIndeterminate" :disabled="!scope.row.app.isApp[0].checked">全选</el-checkbox>
                          <el-checkbox style="width:100px" v-for="(o, i) in scope.row.app.zero"  :key="i" v-model="o.checked" @change="checkSingle(scope, o)" :disabled="!scope.row.app.isApp[0].checked">
                          <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                            <span>{{ o.note }}</span>
                          </el-tooltip>
                        </el-checkbox>
                         </div>
                       </div>
                     </div>
                     <!-- pad -->
                     <div class="pd">
                      <el-checkbox style="width:70px" v-for="(o, i) in scope.row.pad"  :key="i" v-model="o.checked" @change="checkSingle(scope, o)">
                      <el-tooltip effect="dark" :content="o.description" placement="bottom" :disabled="!o.description">
                        <span>{{ o.note }}</span>
                      </el-tooltip>
                    </el-checkbox>
                    <el-tooltip effect="dark" content="勾选后将拥有预订台所有功能，亦可对他人订单进行操作" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                     </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据权限" name="second">
            <div>
              设置该角色的用户可以查询或操作（操作权限控制）的线索，销售机会，订单以及客户的数据范围
            </div>
            <div class="radio-box">
              <el-radio v-for="(o, i) in dataRoleList" :key="i" v-model="radio" @change="updateDataPermission" :label="o.k">
                <span class="span1">{{ o.v }}</span>
                <span class="span2">{{ o.detail }}</span>
              </el-radio>
            </div>
          </el-tab-pane>
          <el-tab-pane label="功能权限" name="third">
            <div>
              <div class="mb-10">
                设置该角色相关功能权限
              </div>
              <el-table :data="functionPermissionList" style="width: 100%" border header-row-class-name="table-header" v-loading="loading">
                <el-table-column align="center" label="功能权限">
                  <template slot-scope="scope">
                    <span class="client-tag">{{functionPKeyMap[scope.row.functionKey].tag}}</span>
                    <span class="ml-5">{{functionPKeyMap[scope.row.functionKey].name}}</span>
                    <el-tooltip effect="dark" :content="functionPKeyMap[scope.row.functionKey].desc" placement="bottom" :disabled="!functionPKeyMap[scope.row.functionKey].desc">
                      <span class="el-icon-warning-outline ml-5"></span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.flag" @change="handleChangeFunction($event, scope.row)"></el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>
// import draggable from 'vuedraggable'
import { get_getGlobalRoleList, post_addGlobalRole, post_updateGlobalRole, post_deleteGlobalRole, get_getGlobalRolePermissionList, post_globalRolePermissionBind, post_globalRolePermissionUnBind, post_updateGlobalRoleDataPermission, post_updateGlobalRoleFunctionPermission } from "@/api/base";
import { get_key_value } from "@/api/common";
const form =  {
  roleName: '',
}
export default {
  name: "discount",
  components: {
    // draggable,
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      isAdd: false,
      drag: false,
      listData: [],
      form: this.$deepClone(form),
      searchForm: '',
      sourceType: 1,
      selectInfo: {},
      tableData: [],
      dataRoleList: [],
      tabName: 'first',
      radio: '',
      // 功能权限
      functionPermissionList: [],
      functionPKeyMap: {
        'HIDDEN_PHONE_NUM': {
          tag: 'PC+APP',
          name: '手机号码隐藏',
          desc: 'PC及APP各页面，非本人跟进的线索/销售机会/订单/的客户手机号码，将被隐藏中间位数'
        }
      },
      // 零点订单管理 全选控制
      zeroCheck:{
        pc:{
          checked:false,
          isIndeterminate:false,
        },
        app:{
          table:{
           others:{
              checked:false,
              isIndeterminate:false,
           },
           own:{
              checked:false,
              isIndeterminate:false,
           }
          },
          zero:{
            checked:false,
            isIndeterminate:false,
          }
        }
      },
    };
  },
  computed: {},
  watch: {
    isAdd(val) {
      if(!val) {
        this.form = this.$deepClone(form)
      }
    },
    sourceType(val) {
      if(val === 1) {
        this.getList()
      } else {
        this.getKeyValue()
        this.getList2()
      }
    }
  },
  methods: {
    initCheckAll(key,arr){
    if (arr.findIndex(p => p.checked === false) == -1) {
        key.checked = true
        key.isIndeterminate = false
      } else if (arr.findIndex(p => p.checked === false) !== -1 && arr.findIndex(p => p.checked === true) !== -1) {
        key.isIndeterminate = true
        key.checked = false
      } else {
        key.checked = false
        key.isIndeterminate = false
      }
    },
     // 单元格样式
    cellStyle({row, columnIndex}){
      if(row.belongProject == '零点订单管理' && columnIndex == 1){
        return 'padding-top:0;padding-bottom:0'
      }
    },
    //码表获取数据权限
    async getKeyValue() {
      const res = await get_key_value({
        type: 'DATA_PERMISSION'
      })
      let _arr = []
      res.records.map(o => {
        if (o.v === '个人及下属') {
          o.detail = '只能操作自己和下属的数据'
          _arr[0] = o
        } else if (o.v === '所属部门') {
          o.detail = '能操作自己、下属和自己所属部门的数据'
          _arr[1] = o
        } else if (o.v === '全店') {
          o.detail = '能操作全店和公海的数据'
          _arr[2] = o
        }
      })
      this.dataRoleList = _arr
    },
    //获取初始化列表
    getList(val) {
      get_getGlobalRoleList({
        searchKey: val
      }).then(res => {
        this.loading = false
        this.listData = res
      })
    },
    //获取权限信息
    getList2() {
      this.loading = true
       get_getGlobalRolePermissionList({
        globalRoleId: this.selectInfo.id
      }).then(res => {
        res && res.globalRolePermissionList.map(o => {
          if (o.globalRolePermissionDetailList.findIndex(p => p.checked === false) == -1) {
            o.checked = true
            o.isIndeterminate = false
          } else if (o.globalRolePermissionDetailList.findIndex(p => p.checked === false) !== -1 && o.globalRolePermissionDetailList.findIndex(p => p.checked === true) !== -1) {
            o.isIndeterminate = true
            o.checked = false
          } else {
            o.checked = false
            o.isIndeterminate = false
          }
          if(o.belongProject == "零点订单管理"){
          console.log(o.globalRolePermissionDetailList,'零点订单管理');
          let app = {
            isApp:[],
            table:{
              others:[],
              own:[]
            },
            zero:[]
          }
          let pad = []
          let pc = {
            zero:[]
          }
          o.globalRolePermissionDetailList.map(o =>{
            if(o.configKey.includes('zeroApp')){
              if(o.configKey.includes('zeroOrder')){
                app.zero.push(o)
              }
              else if(o.configKey.includes('others')){
                app.table.others.push(o)
              }
              else{
                if(o.configKey.includes('client')){
                  app.isApp.push(o)
                }else{
                  app.table.own.push(o)
                }
              }
            }else{
             if(o.configKey.includes('pad')){
               pad.push(o)
             }else{
               pc.zero.push(o)
             }
            }
          })
          o.app = app
          o.pc = pc
          o.pad = pad
          this.initCheckAll(this.zeroCheck.pc,o.pc.zero)
          this.initCheckAll(this.zeroCheck.app.table.others,o.app.table.others)
          this.initCheckAll(this.zeroCheck.app.table.own,o.app.table.own)
          this.initCheckAll(this.zeroCheck.app.zero,o.app.zero)
        }
        })
        this.tableData = res ? res.globalRolePermissionList : []
        this.radio = res ? res.dataPermission : []
        this.functionPermissionList = res.functionPermissionList || []
        this.loading = false
        // this.listData = res
      })
    },
    //新增
    addList() {
      if(this.form.roleName === '') {
        this.$message({
          message: "名称不能为空",
          type: "warning",
        });
      } else {
        post_addGlobalRole({
          ...this.form
        }).then(() => {
          this.$message({
            message: "新增成功",
            type: "success",
          })
          this.getList()
          this.isAdd = false
        })
      }
    },
    // 更新
    update(val) {
      if(val.roleName === '') {
        this.$message({
          message: "名称不能为空",
          type: "warning",
        });
        this.getList()
      } else {
        post_updateGlobalRole({
          globalRoleId: val.id,
          roleName: val.roleName,
          initFlag: val.initFlag,
          updateFlag: val.updateFlag
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList()
        })
      }
    },
    
    //删除
    deleteSelect(val) {
      console.log(val)
      post_deleteGlobalRole({
        globalRoleId: val.id
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
       this.getList()
      }) 
    },

     // 全选
    checkAll(row, flag, key) {
      // console.log(row, 111);
      // this.loading = true
      if(flag){
         row.map(o =>{
          if(key.checked){
            o.checked = true
          }else{
            o.checked = false
          }
        })
         if(key.checked) {
          let _selectIds = []
          row.map(o => {
            _selectIds.push(o.globalPermissionId)
          })
          this.permissionsBind(this.selectInfo.id, _selectIds)
        } else {
          let _unSelectIds = []
          row.map(o => {
            _unSelectIds.push(o.id)
          })
          this.permissionsUnbound(_unSelectIds)
        }
        }else{
          this.tableData.map(o => {
            if (o.belongProject === row.belongProject) {
              o.globalRolePermissionDetailList.map(p => {
                if (row.checked) {
                  p.checked = true
                } else {
                  p.checked = false
                }
              })
            }
          })
          if (row.checked) {
            let _selectIds = []
            row.globalRolePermissionDetailList.map(o => {
              _selectIds.push(o.globalPermissionId)
            })
            this.permissionsBind(this.selectInfo.id, _selectIds)
          } else {
            let _unSelectIds = []
            row.globalRolePermissionDetailList.map(o => {
              _unSelectIds.push(o.id)
            })
            this.permissionsUnbound(_unSelectIds)
          }
          this.isIndeterminate = false
      }
    },
    //单个勾选
    checkSingle(scope, val) {
      console.log(scope, 'scope')
      let _list = scope.row.globalRolePermissionDetailList
      // this.loading = true
      if (val.checked === false) {
        //单个取消
        this.tableData[scope.$index].checked = false
        this.permissionsUnbound([val.id])
        if (_list.findIndex(p => p.checked === false) !== -1 && _list.findIndex(p => p.checked === true) !== -1) {
          this.tableData[scope.$index].isIndeterminate = true
        } else {
          this.tableData[scope.$index].isIndeterminate = false
        }
      } else {
        //单个选中
        let _data = this.tableData[scope.$index].globalRolePermissionDetailList
        if (_data.findIndex(o => o.checked === false) == -1) {
          this.tableData[scope.$index].checked = true
          this.tableData[scope.$index].isIndeterminate = false
        } else if (_list.findIndex(p => p.checked === false) !== -1 && _list.findIndex(p => p.checked === true) !== -1) {
          this.tableData[scope.$index].isIndeterminate = true
        }
        this.permissionsBind(this.selectInfo.id, [val.globalPermissionId])
      }
    },
    //绑定权限
    async permissionsBind(id, _selectIds) {
      this.loading = true
      await post_globalRolePermissionBind({
        globalRoleId: id,
        globalPermissionIdList: _selectIds
      })
        .then(() => {
          this.loading = false
          this.$message({
            message: '权限设置成功',
            type: 'success'
          })
          this.getList2()
        })
        .catch(() => {
          this.loading = false
        })
    },
    //解绑权限
    async permissionsUnbound(_unSelectIds) {
      this.loading = true
      await post_globalRolePermissionUnBind({
        idList:_unSelectIds,
        globalRoleId: this.selectInfo.id
      })
        .then(() => {
          this.loading = false
          this.$message({
            message: '权限设置成功',
            type: 'success'
          })
          this.getList2()
        })
        .catch(() => {
          this.loading = false
        })
    },
    updateDataPermission() {
      post_updateGlobalRoleDataPermission({
        globalRoleId: this.selectInfo.id,
        dataPermission: this.radio
      }).then(() => {
        this.$message({
          message: '数据权限修改成功',
          type: 'success'
        })
        this.getList2()
        this.tabName = 'second'
      })
    },
    handleChangeFunction(val, row) {
      post_updateGlobalRoleFunctionPermission({
        roleId: this.selectInfo.id,
        functionKey: row.functionKey,
        value: row.value,
        flag: val,
        note: row.note
      }).then(() => {
        this.$message({
          message: '功能权限修改成功',
          type: 'success'
        })
        this.getList2()
      })
    }
    
    // //修改排序
    // changeSort(val) {
    //   post_changeSort({
    //     index: val.newIndex,
    //     hotelConfigInitId: val.clone.dataset.id,
    //     configType: 13
    //   }).then(() => {
    //     this.getList()
    //   })
    // },
    // onEnd(val) {
    //   this.changeSort(val)
    //   this.drag = false
    // },
  },
};
</script>

<style scoped lang="scss">
.s-right-box {
    ::v-deep {
      .el-collapse {
        .el-collapse-item__header {
          background: #f9f9f9;
        }
        .el-collapse-item__content {
          padding-bottom: 0;
        }
      }
    }
    .r-top {
      span {
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-width: 0;
        }
      }
    }
    .draggable {
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
    }
    .source-list2 {
       display: flex;
        align-items: center;
        align-items: stretch;
        .items {
          border-right: unset;
          margin: 0 10px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                border: 1px solid #eeeeee;
              }
            }
          }
        }
    }
    .source-list {
      display: flex;
      align-items: center;
      // border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      min-height: 45px;
      align-items: stretch;
      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
      &:first-child {
        border-top: 1px solid #eeeeee;
      }
      .item {
        flex: 1;
        border-right: 1px solid #eeeeee;
        // line-height: 45px;
        color: #333333;
        text-align: center;
        font-size: 14px;
        // padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:last-child {
          border-width: 0;
        }
        .select {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #e6f8e6;
                color: #64a264;
                border-width: 0;
              }
              .el-select__caret {
                color: #64a264;
              }
            }
          }
        }
        .select1 {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #f1f1f1;
                color: #666666;
                border-width: 0;
              }
              .el-select__caret {
                color: #666666;
              }
            }
          }
        }
        .pointLine {
          opacity: 0;
          width: 7px;
          height: 12px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 4px;
          margin-left: 5px;
          flex-shrink: 0;
          &:hover {
            background: rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            .point {
              background: #409eff;
            }
          }
          .point {
            width: 2px;
            height: 2px;
            border-radius: 2px;
            background: #999999;
            margin-right: 1px;
            flex: 0 0 30%;
          }
          .active {
            background: #409eff !important;
          }
        }
        &:hover {
          .pointLine {
            opacity: 1;
          }
        }
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid transparent;
              &:hover {
                border: 1px solid #409eff;
                +.el-input__suffix {
                  opacity: 1;
                }
              }
            }
          }
          .el-date-editor--timerange.el-input__inner {
            width: unset;
            border-width: 0;
          }
          .el-input__suffix {
            opacity: 0;
          }
        }
      }
      .items {
        border-right: unset;
        margin: 0 10px;
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid #eeeeee;
            }
          }
        }
      }
    }
  }
  .w100 {
  width: 100%;
}
.b-1 {
  background: #f5f6fb;
}
.i-hover {
  color: #cccccc;
  &:hover {
    color: #409eff;
  }
}
.c-1 {
  color: #a4a4a4;
}
.back {
  // width: 100%;
  margin-bottom: 10px;
  background: white;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :first-child {
    margin-right: 5px;
  }
}
.radio-box {
      margin-top: 10px;
      .el-radio {
        display: block;
        padding: 10px 0;
        .span1 {
          width: 100px;
          display: inline-block;
        }
        .span2 {
          color: #999999;
        }
      }
    }
.client-tag{
  color: #409eff;
  background: #f5f7fa;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 4px;
}
::v-deep {
  .el-tabs {
    .el-tabs__content {
      height: calc(100vh - 350px);
      overflow: auto;
    }
  }
}
.note{
  text-align: left;
  width: 90px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.bt{
  border-bottom: 1px solid #EBEEF5;
}
.br{
  border-right: 1px solid #EBEEF5;
}
.center{
  text-align: center;
}
.pd{
  padding-left: 10px;
}
.content-box{
  box-sizing: content-box !important;
}
::v-deep .el-table tr:nth-child(6) td:nth-child(2) .cell{
  padding: 0 !important;
}
::v-deep .order-checkbox .el-checkbox{
  width: 100px !important;
}
</style>
