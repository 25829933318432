<template>
  <div v-loading="clueRuleLoading"  class="clueRule">
    <div class="flex flex-align-center">
      <div class="page-sub-title mb-0">线索管理相关规则设置<span class="font-size-13 c-1 ml-10">线索管理模块的所有规则设置</span></div>
    </div>
    <div class="main-box">
      <div class="t1 flex flex-align-center mt-20">
        <span class="point mr-10"></span>
        <span class="c-5 mr-15">无效线索，每日凌晨（大约0点）自动回收至公海池</span>
        <span class="c-1 font-size-13">让线索在系统中流转起来，充分挖掘价值</span>
      </div>
      <div class="s-right-box flex-1">
        <div class="r-top flex flex-align-center b-1">
          <span class="p-15 flex-1 text-center">规则开启</span>
          <span class="p-15 flex-3 text-center">规则内容</span>
        </div>
        <div class="source-list" v-for="(o, i) in clueHotelConfigs" :key="i">
          <span class="item flex-1">
            <span>
              <el-switch v-model="o.configStatus" @change="updateClueHotelConfig(o)"> </el-switch>
            </span>
          </span>
          <span class="item flex-3" v-if="o.code === 'CLUE_NO_CONFIRM_DAY'">
            <span class="mr-10">分配后超过</span>
            <el-select v-model="o.day" size="small" class="mr-10 select" @change="changeday1(o)">
              <el-option v-for="(p,q) in daysList" :key="q" :label="p.label" :value="p.value"> </el-option>
            </el-select>
            天
            <!-- <el-select v-model="o.time" size="small" @change="changetime1(o)" class="select mr-10 ml-10">
              <el-option v-for="(p,q) in timeList" :key="q" :label="p" :value="p"> </el-option>
            </el-select> -->
            24点" 未确认 "的线索
            <el-popover placement="top-start" width="200" trigger="hover" content="未将线索转无效或确认为有效即为未确认">
              <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
            </el-popover>
            ，由系统自动回收至公海池
          </span>
          <span class="item flex-3" v-if="o.code === 'CLUE_NO_FOLLOW_DAY'">
            <span class="mr-10">分配后超过</span>
            <el-select v-model="o.day" size="small" class="mr-10 select" @change="changeday1(o)">
              <el-option v-for="(p,q) in daysList" :key="q" :label="p.label" :value="p.value"> </el-option>
            </el-select>
            天
            <!-- <el-select v-model="o.time" size="small" @change="changetime1(o)" class="select mr-10 ml-10">
              <el-option v-for="(p,q) in timeList" :key="q" :label="p" :value="p"> </el-option>
            </el-select> -->
            24点" 未跟进 "的线索
            <el-popover placement="top-start" width="200" trigger="hover" content="在系统中无跟进记录即为未跟进">
              <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
            </el-popover>
            ，由系统自动回收至公海池
          </span>
          <span class="item flex-3" v-if="o.code === 'CLUE_INVALID_TO_POOL'">
            <span>线索转无效后自动回收到公海池</span>
          </span>
        </div>
      </div>
      <div class="t1 flex flex-align-center mt-20">
        <span class="point mr-10"></span>
        <span class="c-5 mr-15">线索跟进任务规则</span>
        <span class="c-1 font-size-13">让线索在系统中流转起来，充分挖掘价值</span>
      </div>
      <el-table
        border
        :data="clueTaskConfig"
        style="width: 100%">
        <el-table-column
          label="任务开启"
          align="center">
          <template slot-scope="scope">
            <span>
              <el-switch v-model="scope.row.status" @change="updateClueTaskConfig"></el-switch>
            </span>
          </template>
        </el-table-column>
       <el-table-column
          prop="clueTaskConfig"
          label="任务内容"
          align="center">
          <template>
            <span>
              <!-- {{scope.row.taskContent.content}} -->
              新分配线索跟进任务
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop='taskTriggerRule'
          label="任务触发规则"
          align="center">
          <template>
            <span>
              被分配线索时，自动分派
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="任务完成规则"
          align="center"
          width="420">
          <template slot-scope="scope">
            <span>
              被分配后
              <el-select v-model="scope.row.taskCompletionRule.dayNum" size="small" class="mr-10 select ml-10" @change="updateClueTaskConfig">
                <el-option v-for="(o, i) in daysList" :key="i" :label="o.label" :value="o.value"> </el-option>
              </el-select>
              天
              <el-select v-model="scope.row.taskCompletionRule.detailedTime" size="small" class="select mr-10 ml-10" @change="updateClueTaskConfig">
                <el-option v-for="(o, i) in timeList" :key="i" :label="o" :value="o"> </el-option>
              </el-select>
              时进行跟进
              <el-popover placement="top-start" width="200" trigger="hover" content="在系统中新增跟进记录即为进行了跟进">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="任务提醒"
          align="center">
          <template slot-scope="scope">
            <span>
              <el-select v-model="scope.row.reminderTimeType" size="small" class="select1" @change="updateClueTaskConfig">
                <el-option v-for="(o, i) in noticeList" :key="i" :label="o.v" :value="o.seq"> </el-option>
              </el-select>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="任务失败规则"
          width="120"
          align="center">
          <!-- <template slot-scope="scope">
            <span>{{ scope.row.taskInvalidationRule }}</span>
          </template> -->
          <template>
            <span>
              线索状态变为转换状态或任务负责人不是当前线索跟进人时失效
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="t1 flex flex-align-center mt-20">
        <span class="point mr-10"></span>
        <span class="c-5 mr-15">线索关联跟进规则</span>
        <span class="c-1 font-size-13">设置线索阶段任务及流转过程是否关联跟进</span>
      </div>
      <el-table
        border
        :data="clueFollowFlagList"
        style="width: 100%">
        <el-table-column label="线索过程节点" width="300px" align="center">
          <template slot-scope="scope">
            {{configMap[scope.row.code]}}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>是否必须关联跟进</span>
            <!-- <el-popover placement="top-start" width="200" trigger="hover" content="若开启，线索阶段过程必须关联跟进记录；若关闭，则无需关联跟进">
              <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
            </el-popover> -->
          </template>
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.value" @change="updateClueFollowConfig(scope.row)">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { get_hotelConfigInitConfigTypeList,get_clueTaskConfig, post_updateClueTaskConfig, post_updateHotelConfigInitConfigType } from '@/api/base'
import { get_key_value } from '@/api/common'
export default {
  name: '',
  data() {
    return {
      // 线索管理相关规则设置
      clueRuleLoading: false,
      tableData: [],
      daysList: [
        {
          label: '2',
          value: 2
        },
        {
          label: '3',
          value: 3
        },
        {
          label: '4',
          value: 4
        },
        {
          label: '5',
          value: 5
        },
        {
          label: '6',
          value: 6
        },
        {
          label: '7',
          value: 7
        }
      ],
      timeList: ['17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30'],
      clueHotelConfigs: [],
      clueTaskConfig: [],
      noticeList: [],
      taskCompletionRule: {},
      clueFollowFlagList: [],
      configMap: {
        'CLUE_COMPLETE_TASK_FOLLOW_FLAG': '完成线索任务',
        'CLUE_TO_VALUE_FOLLOW_FLAG': '确认有效转为销售机会'
      },
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {
    getkeyvalue() {
      get_key_value({
        type: 'REMINDER_TIME_TYPE'
      }).then(res => {
        this.noticeList = res.records
      })
    },
    //获取线索相关配置
    clueHotelConfig() {
      get_hotelConfigInitConfigTypeList({
        configKList: 'CLUE_INVALID_TO_POOL,CLUE_NO_FOLLOW_DAY,CLUE_NO_CONFIRM_DAY,CLUE_COMPLETE_TASK_FOLLOW_FLAG,CLUE_TO_VALUE_FOLLOW_FLAG'
      }).then(res => {
        console.log(res,11)
        this.clueHotelConfigs = res.filter(o => o.code === 'CLUE_INVALID_TO_POOL' || o.code === 'CLUE_NO_FOLLOW_DAY' || o.code === 'CLUE_NO_CONFIRM_DAY').map(o => {
          if (o.code === 'CLUE_NO_FOLLOW_DAY' || o.code === 'CLUE_NO_CONFIRM_DAY') {
            o.day = JSON.parse(o.value).dayNum
            o.time = JSON.parse(o.value).time
          }
          return o
        })

        this.clueFollowFlagList = res.filter(o => o.code === 'CLUE_COMPLETE_TASK_FOLLOW_FLAG' || o.code === 'CLUE_TO_VALUE_FOLLOW_FLAG')
      })
    },
    //获取线索任务相关配置
    get_clueTaskConfig() {
      get_clueTaskConfig({
        hotelId: 0
      }).then(res => {
        this.clueTaskConfig = res[0] ? [res[0]] : []
        this.taskCompletionRule = res[0]?.taskCompletionRule
        // console.log(res,222)
      })
    },
    changeday1(val) {
      console.log(val)
      let _v = JSON.parse(val.value)
      _v.dayNum = val.day
      this.clueHotelConfigs.map(o => {
        if (o.code === val.code) {
          o.value = JSON.stringify(_v)
        }
      })
      this.updateClueHotelConfig(val)
    },
    changetime1(val) {
      let _v = JSON.parse(val.value)
      _v.time = val.time
      this.clueHotelConfigs.map(o => {
        if (o.code === val.code) {
          o.value = JSON.stringify(_v)
        }
      })
      this.updateClueHotelConfig(val)
    },
    // changeday2(val) {
    //   console.log(val)
    //   this.clueTaskConfig.taskCompletionRule.dayNum = val
    //   this.updateClueTaskConfig()
    // },
    // changetime2(val) {
    //   this.clueTaskConfig.taskCompletionRule.detailedTime = val
    //   this.updateClueTaskConfig()
    // },
    updateClueFollowConfig(val) {
      this.updateClueHotelConfig(val)
    },
    //修改线索配置
    updateClueHotelConfig(val) {
      post_updateHotelConfigInitConfigType({
        ...val
      }).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.clueHotelConfig()
      })
    },
    //修改线索任务配置
    updateClueTaskConfig() {
      this.clueTaskConfig.configId = this.clueTaskConfig.id
      post_updateClueTaskConfig({
        taskConfigList: this.clueTaskConfig
      }).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.get_clueTaskConfig()
      })
    }
  },
};
</script>

<style scoped lang='scss'>
.clueRule {
  .main-box {
    .t1 {
      background: #f9f9f9;
      padding: 20px 30px;
      .point {
        width: 6px;
        height: 6px;
        background: #aaaaaa;
        border-radius: 50%;
      }
    }
    .s-right-box {
      .r-top {
        span {
          border-right: 1px solid #eeeeee;
          &:last-child {
            border-width: 0;
          }
        }
      }
      .source-list {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        height: 65px;
        &:first-child {
          border-top: 1px solid #eeeeee;
        }
        .item {
          border-right: 1px solid #eeeeee;
          height: 65px;
          color: #333333;
          text-align: center;
          font-size: 14px;
          padding: 0px 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &:last-child {
            border-width: 0;
          }
          // span {
          //   line-height: 20px;
          // }
        }
      }
    }
  }
}
.select {
    width: 80px;
  }
  .select1 {
    width: 120px;
  }
.c-1 {
  color: #a4a4a4;
}
.c-2 {
  color: #c2c9d6;
}
.c-3 {
  color: #5d6485;
}
.c-4 {
  color: #409eff;
}
.c-5 {
  color: #333333;
}
.c-6 {
  color: #cccccc;
}
.b-1 {
  background: #f5f6fb;
}
.b-2 {
  background: #f8f8f8;
}
.w100 {
  width: 100%;
}
.opacity0 {
  opacity: 0 !important;
}
::v-deep {
  .el-table--border {
    border-width: 0;
  }
  .el-table {
    th {
      background: #f5f7fa;
    }
  }
}
</style>
