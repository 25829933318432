<template>
  <div class="discount">
    <div class="flex flex-between">
      <div class="page-sub-title flex flex-center mb-0">
        <span class="flex-1">宴会类别初始化</span>
      </div>
      <!-- <div class="flex flex-align-center">
        <el-input class="flex-1 mr-10" clearable v-model="searchForm" placeholder="请输入宴会类别初始化搜索" size="small" @keyup.enter.native="getList(searchForm)"></el-input>
        <el-button size="small" type="primary" @click="getList(searchForm)">搜索</el-button>
      </div> -->
    </div>
      <div class="mt-20 flex">
        <div class="s-right-box flex-1">
          <div class="r-top flex flex-align-center b-1">
            <span class="p-15 flex-1 text-center">类别名称</span>
            <span class="p-15 flex-1 text-center">类别标识图标</span>
            <span class="p-15 flex-1 text-center">初始化说明</span>
            <span class="p-15 flex-1 text-center">是否初始化</span>
            <span class="p-15 flex-1 text-center">是否可修改</span>
            <span class="p-15 flex-1 text-center">创建时间</span>
            <span class="p-15 flex-1 text-center">操作</span>
          </div>
          <div class="pl-20" :class="{'mt-10 mb-10':!isAdd}">
            <el-button v-if="!isAdd" size="small" class="border-btn" @click="isAdd = true">+ 新增类别</el-button>
            <div class="source-list2 pt-10 pb-10" v-if="isAdd">
              <span class="item items">
                <el-input class="flex-1" v-model="form.value" placeholder="请输入类别名称" size="small" maxlength="8" show-word-limit></el-input>
              </span>
              <span class="item items flex flex-align-center">
                设置宴会类别图标
                <div class="row-svg flex flex-center ml-5 cursor-point" @click="showSvgDialog('add')">
                  <!-- <el-button size="small" class="ml-5" @click="showSvgDialog('add',o)">+</el-button> -->
                  <span class="font-size-18" v-if="form.isSvg" :class="form.icon" style="color:#a4a4a4"></span>
                  <!-- <el-image v-else :src="form.icon" style="width: 100%;height: 100%;"></el-image> -->
                  <span v-else style="width:14px;height:14px" class="icon" :style="{'-webkit-mask-image':`url(${form.icon})`,'mask-image':`url(${form.icon})`}" ></span>
                  <span v-if="form.icon == ''">+</span>
                </div>
              </span>
              <span class="item items">
                <el-input class="flex-1" v-model="form.explain" placeholder="请输入初始化说明" size="small" maxlength="16" show-word-limit></el-input>
              </span>
              <span class="item items">
                <el-button size="small" type="primary" @click="addList">保存</el-button>
                <el-button size="small" class="border-btn" @click="isAdd = false">取消</el-button>
              </span>
            </div>
          </div>
          <draggable class="draggable" v-loading="loading" v-model="listData" chosenClass="chosen" forceFallback="true" group="item" handle=".mover" animation="500">
            <transition-group>
              <div class="source-list" v-for="(o, i) in listData" :key="i" :data-id="o.id">
                <span class="item">
                  <!-- <el-tooltip effect="dark" content="上下拖动排序" placement="left">
                    <span class="pointLine mover">
                      <span class="point" v-for="(o, i) in 6" :key="i"></span>
                    </span>
                  </el-tooltip> -->
                  <!-- <span>{{ o.value }}</span> -->
                  <div class="pl-10 pr-10">
                    <el-input maxlength="8" show-word-limit class="flex-1" @change="update(o)" v-model="o.value" size="small"></el-input>
                  </div>
                </span>
                <span class="item">
                  <div class="pl-10 pr-10">
                    <div class="row-svg flex flex-center">
                      <span class="font-size-18" v-if="o.isSvg" :class="o.icon" style="color:#a4a4a4"></span>
                      <!-- <el-image v-else :src="o.icon" style="width: 100%;height: 100%;"></el-image> -->
                      <span v-else style="width:18px;height:18px" class="icon" :style="{'-webkit-mask-image':`url(${o.icon})`,'mask-image':`url(${o.icon})`}" ></span>
                      <span class="edit" @click="showSvgDialog('edit',o)"><i class="el-icon-edit"></i></span>
                    </div>
                  </div>
                </span>
                <span class="item">
                  <div class="pl-10 pr-10">
                    <el-input maxlength="16" show-word-limit class="flex-1" v-model="o.explain" size="small" @change="update(o)"></el-input>
                  </div>
                </span>
                <span class="item">
                  <el-switch
                    @change="changeHotelConfigInitFlag(o)"
                    v-model="o.flag">
                  </el-switch>
                </span>
                <span class="item">
                  <el-select v-model="o.allowUpdate" size="small" :class="o.allowUpdate?'select':'select1'" @change="update(o)">
                    <el-option label="是" :value="true"> </el-option>
                    <el-option label="否" :value="false"> </el-option>
                  </el-select>
                </span>
                <span class="item">
                  {{o.createTime}}
                </span>
                <span class="item">
                  <el-popconfirm
                  title="若删除该类别，新开户的酒店将不显示该宴会类型类别，确定删除？"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="deleteSelect(o)"
                >
                  <el-button slot="reference" type="text"
                    >删除</el-button
                  >
                </el-popconfirm> 
                </span>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <svg-dialog :visible.sync="dialogVisible2" :item="dialogData" @confirm="getIcon"></svg-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { get_MeetingTypeAttributeInitList, post_addMeetingTypeAttributeInit, post_updateMeetingTypeAttributeInit, post_removeHotelConfigInit, post_changeHotelConfigInitFlag } from "@/api/base";
import svgDialog from '@/components/ui-tabs-svg-dialog.vue'
const form =  {
  value: '',
  explain: '',
  icon: '',
  isSvg: true
}
export default {
  name: "discount",
  components: {
    draggable,
    svgDialog
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      isAdd: false,
      drag: false,
      listData: [],
      form: this.$deepClone(form),
      searchForm: '',
      dialogVisible2: false,
      dialogData: {},
      type: ''
    };
  },
  computed: {},
  watch: {
    isAdd(val) {
      if(!val) {
        this.form = this.$deepClone(form)
      }
    }
  },
  methods: {
    //获取优惠类型初始化列表
    getList(val) {
      get_MeetingTypeAttributeInitList({
        searchKey: val
      }).then(res => {
        this.loading = false
        res.map(o => {
          if(o.icon && o.icon.indexOf('http') !== -1) {
            o.isSvg = false
          } else {
            o.isSvg = true
          }
        })
        this.listData = res
        console.log(this.listData,'this.listData')
      })
    },
    //新增
    addList() {
      if(this.form.value === '') {
        this.$message({
          message: "类别名称不能为空",
          type: "warning",
        });
      } else if (this.form.icon === '') {
        this.$message({
          message: "类别图标不能为空",
          type: "warning",
        });
      } else {
        post_addMeetingTypeAttributeInit({
          ...this.form
        }).then(() => {
          this.$message({
            message: "新增成功",
            type: "success",
          })
          this.getList()
          this.isAdd = false
        })
      }
    },
    // 更新
    update(val) {
      if(val.value === '') {
        this.$message({
          message: "类别名称不能为空",
          type: "warning",
        });
        this.getList()
      } else {
        post_updateMeetingTypeAttributeInit({
          id: val.id,
          value: val.value,
          explain: val.explain,
          allowUpdate: val.allowUpdate,
          icon: val.icon
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList()
        })
      }
    },
    changeHotelConfigInitFlag(val) {
      post_changeHotelConfigInitFlag({
        id: val.id,
        flag: val.flag
      }).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      })
    },
    
    //删除
    deleteSelect(val) {
      console.log(val)
      post_removeHotelConfigInit({
        id: val.id
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
       this.getList()
      }) 
    },
    showSvgDialog(type,item) {
      this.type = type
      console.log(item)
      if(type === 'edit') {
        if((item.icon).indexOf('http') !== -1) {
          item.svgType = 2
        } else {
          item.svgType = 1
        } 
        this.dialogData = item
      } else {
        this.dialogData = {
          svgType: this.form.isSvg?1:2,
          icon: this.form.icon?this.form.icon:''
        }
      }
      this.dialogVisible2 = true
    },
    getIcon(val) {
      console.log(val,11)
      if(this.type === 'add') {
        if((val.icon).indexOf('http') !== -1) {
          this.form.isSvg = false
        } else {
          this.form.isSvg = true
        } 
        this.form.icon = val.icon
      } else {
        this.update(val)
      }
    },
    //修改排序
    // changeSort(val) {
    //   post_changeSort({
    //     index: val.newIndex,
    //     hotelConfigInitId: val.clone.dataset.id,
    //     configType: 1
    //   }).then(() => {
    //     this.getList()
    //   })
    // },
    // onEnd(val) {
    //   this.changeSort(val)
    //   this.drag = false
    // },
  },
};
</script>

<style scoped lang="scss">
.s-right-box {
    ::v-deep {
      .el-collapse {
        .el-collapse-item__header {
          background: #f9f9f9;
        }
        .el-collapse-item__content {
          padding-bottom: 0;
        }
      }
    }
    .r-top {
      span {
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-width: 0;
        }
      }
    }
    .draggable {
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
    }
    .source-list2 {
       display: flex;
        align-items: center;
        align-items: stretch;
        .items {
          border-right: unset;
          margin: 0 10px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                border: 1px solid #eeeeee;
              }
            }
          }
        }
    }
    .source-list {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      min-height: 45px;
      align-items: stretch;
      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
      &:first-child {
        border-top: 1px solid #eeeeee;
      }
      .item {
        flex: 1;
        border-right: 1px solid #eeeeee;
        // line-height: 45px;
        color: #333333;
        text-align: center;
        font-size: 14px;
        // padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:last-child {
          border-width: 0;
        }
        .select {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #e6f8e6;
                color: #64a264;
                border-width: 0;
              }
              .el-select__caret {
                color: #64a264;
              }
            }
          }
        }
        .select1 {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #f1f1f1;
                color: #666666;
                border-width: 0;
              }
              .el-select__caret {
                color: #666666;
              }
            }
          }
        }
        .pointLine {
          opacity: 0;
          width: 7px;
          height: 12px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 4px;
          margin-left: 5px;
          flex-shrink: 0;
          &:hover {
            background: rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            .point {
              background: #409eff;
            }
          }
          .point {
            width: 2px;
            height: 2px;
            border-radius: 2px;
            background: #999999;
            margin-right: 1px;
            flex: 0 0 30%;
          }
          .active {
            background: #409eff !important;
          }
        }
        &:hover {
          .pointLine {
            opacity: 1;
          }
        }
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid transparent;
              &:hover {
                border: 1px solid #409eff;
                +.el-input__suffix {
                  opacity: 1;
                }
              }
            }
          }
          .el-date-editor--timerange.el-input__inner {
            width: unset;
            border-width: 0;
          }
          .el-input__suffix {
            opacity: 0;
          }
        }
      }
      .items {
        border-right: unset;
        margin: 0 10px;
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid #eeeeee;
            }
          }
        }
      }
    }
  }
  .w100 {
  width: 100%;
}
.row-svg{
  border: 1px solid #DCDFE6;
  width: 30px;
  height: 30px;
  line-height: 32px;
  position: relative;
  .edit{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
    color: #ffffff;
    opacity: 0;
    cursor: pointer;
  }
  &:hover{
    .edit{
      opacity: 1;
    }
  }
}
.b-1 {
  background: #f5f6fb;
}
.i-hover {
  color: #cccccc;
  &:hover {
    color: #409eff;
  }
}
.icon {
  background:#a4a4a4;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
}
.c-1 {
  color: #a4a4a4;
}
</style>
