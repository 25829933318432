<template>
  <div class="discount">
    <div class="flex flex-between">
      <div class="page-sub-title flex flex-center mb-0">
        <span class="flex-1">零点餐段初始化</span>
      </div>
    </div>
    <div class="mt-20 flex">
      <div class="s-right-box flex-1">
        <div class="r-top flex flex-align-center b-1">
            <span class="pt-15 pb-15 flex-center flex-1 text-center">系统餐段</span>
            <span class="pt-15 pb-15 flex-center flex-1 text-center">自定义餐段名称</span>
            <span class="pt-15 pb-15 flex-center flex-1 text-center">状态</span>
            <span class="pt-15 pb-15 flex-center flex-2 text-center">时间段</span>
            <span class="pt-15 pb-15 flex-center flex-1 text-center">是否初始化</span>
            <span class="pt-15 pb-15 flex-center flex-1 text-center">是否可修改</span>
        </div>
        <draggable
          class="draggable"
          v-loading="loading"
          v-model="listData"
          chosenClass="chosen"
          forceFallback="true"
          group="item"
          handle=".mover"
          animation="500"
          @end="onEnd"
        >
          <transition-group>
            <div
              class="source-list draggable"
              v-loading="loading"
              v-for="(o, i) in listData"
              :key="i"
              :data-id="o.id"
            >
              <span class="item">
                <el-tooltip
                  effect="dark"
                  content="上下拖动排序"
                  placement="left"
                >
                  <span class="pointLine mover">
                    <span class="point" v-for="(o, i) in 6" :key="i"></span>
                  </span>
                </el-tooltip>
                <span style="min-width: 92px">{{ o.code }}</span>
              </span>
              <span class="item">
                <div class="pl-10 pr-10">
                  <el-input
                    maxlength="6"
                    show-word-limit
                    class="flex-1"
                    v-model="o.mealTypeName"
                    size="small"
                    @change="update(o)"
                  ></el-input>
                </div>
              </span>
              <span class="item">
                <el-select
                  v-model="o.configStatus"
                  size="small"
                  :class="o.configStatus ? 'select' : 'select1'"
                  @change="update(o)"
                >
                  <el-option label="启用中" :value="true"> </el-option>
                  <el-option label="已停用" :value="false"> </el-option>
                </el-select>
              </span>
                <span class="item time cursor-point flex-2 just-start">
                  <!-- <el-time-picker is-range class="flex-1 cursor-point" v-model="o.realTime" @change="changeMeetingTime(o)" :clearable="true" format="HH:mm" value-format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围"> </el-time-picker> -->
                  <div class="mr-10">
                    <el-time-picker
                      v-model="o.mealStartTime"
                      format="HH:mm"
                      value-format="HH:mm"
                      size="small"
                      style="width:80px"
                      :clearable="false"
                      @focus="listData.map(o => {o.select = false}),o.select = true"
                      placeholder="任意时间点">
                    </el-time-picker>
                    至
                    <el-time-picker
                      v-model="o.mealEndTime"
                      format="HH:mm"
                      value-format="HH:mm"
                      size="small"
                      style="width:80px"
                      :clearable="false"
                      @focus=" listData.map(o => {o.select = false}),o.select = true"
                      placeholder="任意时间点">
                    </el-time-picker>
                  </div>
                  <el-button v-if="o.select" size="small" type="primary" @click.stop="update(o)">保存</el-button>
                </span>
              <span class="item">
                <el-switch
                  @change="changeHotelConfigInitFlag(o)"
                  v-model="o.flag"
                >
                </el-switch>
              </span>
              <span class="item">
                <el-select
                  v-model="o.allowUpdate"
                  size="small"
                  :class="o.allowUpdate ? 'select' : 'select1'"
                  @change="update(o)"
                >
                  <el-option label="是" :value="true"> </el-option>
                  <el-option label="否" :value="false"> </el-option>
                </el-select>
              </span>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_getZeroMealTypeInitList,
  post_updateZeroMealTypeInit,
  post_removeHotelConfigInit,
  post_changeSort,
  post_changeHotelConfigInitFlag,
} from "@/api/base";
import draggable from "vuedraggable";
const form = {
  value: "",
  explain: "",
};
export default {
  name: "tempMealType",
  data() {
    return {
      loading: true,
      dialogVisible: false,
      isAdd: false,
      drag: false,
      listData: [],
      form: this.$deepClone(form),
    };
  },
  components: {
    draggable,
  },
  watch: {
    isAdd(val) {
      if (!val) {
        this.form = this.$deepClone(form);
      }
    },
  },
  methods: {
    //获取初始化列表
    getList() {
      get_getZeroMealTypeInitList().then((res) => {
        this.loading = false;
        if (res.length > 0) {
          res.map((o) => {
            o.select = false
            o.realTime = [o.mealStartTime, o.mealEndTime];
          });
        }
        this.listData = res;
        console.log(this.listData);
      });
    },
    // 更新
    update(val) {
      if (val.value === "") {
        this.$message({
          message: "餐段名称不能为空",
          type: "warning",
        });
        this.getList();
      } else {
        post_updateZeroMealTypeInit({
          id: val.id,
          mealTypeName: val.mealTypeName,
          mealStartTime: val.mealStartTime|| '',
          mealEndTime: val.mealEndTime|| '',
          configStatus: val.configStatus,
          explain: val.explain,
          allowUpdate: val.allowUpdate,
        })
          .then(() => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getList();
          })
          .catch(() => {
            this.getList();
          });
      }
    },
    changeHotelConfigInitFlag(val) {
      post_changeHotelConfigInitFlag({
        id: val.id,
        flag: val.flag,
      }).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      });
    },

    //删除
    deleteSelect(val) {
      console.log(val);
      post_removeHotelConfigInit({
        id: val.id,
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getList();
      });
    },
    //修改排序
    changeSort(val) {
      post_changeSort({
        index: val.newIndex,
        hotelConfigInitId: val.clone.dataset.id,
        configType: 101,
      }).then(() => {
        this.getList();
      });
    },
    onEnd(val) {
      this.changeSort(val);
      this.drag = false;
    },
  },
};
</script>

<style scoped lang="scss">
.s-right-box {
  ::v-deep {
    .el-collapse {
      .el-collapse-item__header {
        background: #f9f9f9;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
    }
  }
  .r-top {
    span {
      border-right: 1px solid #eeeeee;
      &:last-child {
        border-width: 0;
      }
    }
  }
  .draggable {
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
  }
  .source-list2 {
    display: flex;
    align-items: center;
    align-items: stretch;
    .items {
      border-right: unset;
      margin: 0 10px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: 1px solid #eeeeee;
          }
        }
      }
    }
  }
  .source-list {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eeeeee;
    min-height: 45px;
    align-items: stretch;
    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }
    &:first-child {
      border-top: 1px solid #eeeeee;
    }
    .item {
      flex: 1;
      border-right: 1px solid #eeeeee;
      // line-height: 45px;
      color: #333333;
      text-align: center;
      font-size: 14px;
      // padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:last-child {
        border-width: 0;
      }
      .select {
        width: 95px;
        ::v-deep {
          .el-input {
            .el-input__inner {
              background: #e6f8e6;
              color: #64a264;
              border-width: 0;
            }
            .el-select__caret {
              color: #64a264;
            }
          }
        }
      }
      .select1 {
        width: 95px;
        ::v-deep {
          .el-input {
            .el-input__inner {
              background: #f1f1f1;
              color: #666666;
              border-width: 0;
            }
            .el-select__caret {
              color: #666666;
            }
          }
        }
      }
      .pointLine {
        opacity: 0;
        width: 7px;
        height: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 4px;
        margin-left: 5px;
        flex-shrink: 0;
        &:hover {
          background: rgba(0, 0, 0, 0.06);
          border-radius: 4px;
          .point {
            background: #409eff;
          }
        }
        .point {
          width: 2px;
          height: 2px;
          border-radius: 2px;
          background: #999999;
          margin-right: 1px;
          flex: 0 0 30%;
        }
        .active {
          background: #409eff !important;
        }
      }
      &:hover {
        .pointLine {
          opacity: 1;
        }
      }
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: 1px solid transparent;
            &:hover {
              border: 1px solid #409eff;
              + .el-input__suffix {
                opacity: 1;
              }
            }
          }
        }
        .el-date-editor--timerange.el-input__inner {
          width: unset;
          border-width: 0;
        }
        .el-input__suffix {
          opacity: 0;
        }
      }
    }
      .just-start {
        justify-content: flex-start;
      }
    .items {
      border-right: unset;
      margin: 0 10px;
      ::v-deep {
        .el-input {
          .el-input__inner {
            border: 1px solid #eeeeee;
          }
        }
      }
    }
  }
}
.w100 {
  width: 100%;
}
.time {
  ::v-deep {
    .el-input .el-input__inner {
      padding-right: 0!important;
    }
  }
}
.flex-2 {
  flex: 2!important;
}
.b-1 {
  background: #f5f6fb;
}
.i-hover {
  color: #cccccc;
  &:hover {
    color: #409eff;
  }
}
.c-1 {
  color: #a4a4a4;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}
</style>
