<template>
  <div>
    <!-- <div class="page-sub-title flex flex-align-center">
      <span>消息发送设置</span>
      <span class="font-size-13 grey ml-10">修改后仅对新开户门店产生影响</span>
    </div> -->
    <el-tabs v-model="tabIndex" class="flex-1 flex flex-column right-tabs" @tab-click="getList">
      <el-tab-pane label="宴会业务相关模板" align="center" name="1">
      </el-tab-pane>
      <el-tab-pane label="零点业务相关模板" align="center" name="2" >
      </el-tab-pane>
    </el-tabs>
    <el-table
      style="width: 100%"
      class="mt-20"
      border
      header-row-class-name="table-header"
      :data="list"
    >
      <el-table-column label="短信类型" prop="msgTypeName"></el-table-column>
      <el-table-column label="发送规则" prop="sendRuleText"></el-table-column>
      <el-table-column label="模板内容" prop="msgContent" min-width="200px">
        <template slot-scope="scope">
          <div class="text-left" style="white-space: pre-wrap;">{{msgTempTransfer(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120px" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="showInfoDialog(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <messageConfigDialog :visible.sync="infoVisible" :info.sync="editInfo" @confirm="getList"></messageConfigDialog>
  </div>
</template>

<script>
import messageConfigDialog from './messageConfigDialog'
import { get_sms_template_list } from '@/api/base'

export default {
  name: "message-config",
  components: {
    messageConfigDialog
  },
  data() {
    return {
      infoVisible: false,
      list: [],
      editInfo: {},
      tabIndex:'1'
    };
  },
  props: {
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      get_sms_template_list({
        hotelId: this.$store.state.userInfo.fkOrgId,
        templateType: 'SMS',
        resvType:this.tabIndex
      }).then(res => {
        this.list = (res || []).map(o => {
          const sendRule = JSON.parse(o.sendRule)
          o.sendRuleText = sendRule.sendFlag != 0 ? sendRule.roleContent : '不发送'
          return o
        })
      })
    },
    showInfoDialog(row) {
      this.infoVisible = true
      this.editInfo = this.$deepClone(row)
    },
    msgTempTransfer(row) {
      let obj = {
        sort: [],
        messageContent: ""
      };
      try {
        obj = JSON.parse(row.msgContent);
      } catch (e) {
        obj = {
          sort: [],
          messageContent: row.msgContent
        };
      }
      const { sort, messageContent } = obj;
      let str = messageContent;
      if (sort) {
        for (let i = 0; i < sort.length; i++) {
          str = str.replace("%s", sort[i]);
        }
      }
      return str;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
