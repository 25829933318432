<template>
  <el-dialog
    :title="info.msgTypeName + '短信设置'"
    :visible.sync="visible"
    width="80%"
    top="5vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog"
    destroy-on-close
  >
    <div class="dialog-body-content flex">
      <div class="flex-1">
        <div class="page-sub-title">短信发送规则</div>
        <div class="mb-20" v-if="info.msgType == 3">
          <div>
            <el-checkbox v-model="sendFlagChecked">发送</el-checkbox>
          </div>
          <div>
            预约到店时间前
            <el-select size="small" style="width: 80px" placeholder="" v-model="sendRule.config">
              <el-option v-for="i in hourOption" :key="i" :label="i" :value="i"></el-option>
            </el-select>
            小时自动发送
          </div>
        </div>
        <div class="mb-20" v-else>
          {{info.sendRuleText}}
        </div>
        <div class="page-sub-title">短信内容</div>
        <div class="relative">
          <div class="sms-header">【宴荟佳】</div>
          <quill-editor class="sms-editor main-content" ref="msgContent" :options="editorOption" @@click.native="handleFocusEditor()"></quill-editor>
        </div>
      </div>
      <div class="flex-1 ml-20">
        <div class="page-sub-title">
          短信参数
        </div>
        <div class="params-div">
          <div v-for="(variable, index) in variableItemMap" :key="index">
            <div class="tag-title">{{variable.paramGroupName}}</div>
            <div class="tag-group">
              <div
                v-for="(tag, index) in variable.smsTemplateParamDtoList"
                :key="index"
                :class="{'inline-block': tag.paramType == 1}"
              >
                <el-tag
                  effect="plain"
                  size="small"
                  class="mr-5 mb-5 cursor-point"
                  v-if="tag.paramType == 1" @click="insertTagValue(tag)"
                >
                  #{{tag.paramCn}}#
                </el-tag>
                <div v-else class="mt-10" style="width: 100%">
                  <div class="flex flex-center">
                    <span class="light-blue">#{{tag.paramCn}}#</span>
                    <span class="grey flex-1 font-size-12 ml-5">{{tag.paramExample}}</span>
                    <span class="icon-add" :class="{'disabled': !paramEditorContent[tag.param]}" @click.stop="insertTagValueToMain(tag)"></span>
                  </div>
                  <div class="mt-10" v-if="tag.privateParams">
                    <div
                      v-for="(tag0, index) in tag.privateParams"
                      :key="index"
                      :class="'inline-block'"
                    >
                      <el-tag
                        effect="plain"
                        size="small"
                        class="mr-5 mb-5 cursor-point"
                        @click="insertTagValue(tag0)"
                      >
                        #{{tag0.paramCn}}#
                      </el-tag>
                    </div>
                  </div>
                  <quill-editor class="sms-editor2 mt-10" :ref="'msgContent_' + tag.param" :id="tag.param" :options="editorOption" @@click.native="handleFocusEditor(tag)"></quill-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1 ml-20">
        <div class="page-sub-title">短信效果预览</div>
        <div class="preview-container">
          <div class="msg-con">【宴荟佳】{{messagePreview}}</div>
        </div>
        <div class="text-center mt-10">
          样式预览
        </div>
        <div class="text-center mt-10 grey">
          不同手机显示效果不同，请以实际 发送效果为准
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        确定
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import "../../../../quill-develop/dist/quill.core.css";
import "../../../../quill-develop/dist/quill.snow.css";
import "../../../../quill-develop/dist/quill.bubble.css";
// import Quill from "quill";
let Quill = require('../../../../quill-develop/dist/quill')
import { quillEditor } from "vue-quill-editor";
import BtnBlot from "@/components/quillEmbedBtn";
import { get_sms_template_detail, get_sms_template_params, update_sms_template } from '@/api/base'

Quill.register(BtnBlot);

export default {
  components: {
    quillEditor
  },
  props: {
    visible: {
      type: Boolean,
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: false
        },
        placeholder: ""
      },
      editData: {
        content: ""
      },
      variableItemMap: [],
      smsVariables: [],
      editor: null,
      paramEditor: {},
      focusEditor: '',
      paramEditorContent: {},
      sendRule: {},
      sendFlagChecked: false,
      hourOption: [1,2,3,4,5,6,7,8,9,10,11,12]
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getParams()
      } else {
        Object.assign(this.$data, this.$options.data())
      }
    }
  },
  computed: {
    messagePreview() {
      if (!this.editor) {
        return "";
      }
      const contents = this.editor.getContents().ops;
      console.log("messagePreview", contents);
      let messagePreview = "";
      for (let i = 0; i < contents.length; i++) {
        const append = contents[i].insert;
        if (append.tag) {
          const tag = append.tag.replace(/\$|#/g, '')
          const variable = this.smsVariables.find(o => {
            return o.paramCn == tag.trim()
          }) || {}
          if (variable.paramType == 1) {
            messagePreview += variable.paramExample || ''
          } else {
            // const pContents = this.paramEditor[variable.param].getContents().ops;
            // let pMessage = ''
            // pContents.map(o => {
            //   const pAppend = o.insert;
            //   if (pAppend.tag) {
            //     const pTag = pAppend.tag.replace(/#/g, '')
            //     const pVariable = this.smsVariables.find(so => {
            //       return so.paramCn == pTag.trim()
            //     }) || {}
            //     pMessage += pVariable.paramExample || ''
            //   } else {
            //     pMessage += pAppend;
            //   }
            // })
            // messagePreview += pMessage.trim()
            messagePreview += this.paramEditorContent[variable.param] || ''
          }
        } else {
          messagePreview += append;
        }
      }
      return messagePreview;
    },
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
      this.$emit('update:info', {})
    },
    getParams() {
      get_sms_template_params({
        hotelId: this.$store.state.userInfo.fkOrgId,
        smsType: this.info.msgType
      }).then(res => {
        this.variableItemMap = res || []
        this.variableItemMap.map(o => {
          o.smsTemplateParamDtoList = o.smsTemplateParamDtoList.map(v => {
            return {
              ...v,
              privateParams: v.privateParams ? v.privateParams.map(vv => {
                return {
                  ...vv,
                  parentParam: v.param
                }
              }) : null
            }
          })
          this.smsVariables = this.smsVariables.concat(o.smsTemplateParamDtoList)
          o.smsTemplateParamDtoList.map(p => {
            if (p.privateParams) {
              this.smsVariables = this.smsVariables.concat(p.privateParams)
            }
          })
        })
        this.getDetail()
      })
    },
    getDetail() {
      if (!this.info.id) return
      get_sms_template_detail({
        templateId: this.info.id
      }).then(res => {
        this.editData = res
        this.sendRule = JSON.parse(res.sendRule)
        if (res.msgType == 3) {
          this.sendFlagChecked = this.sendRule.sendFlag == 0 ? false : true
        }
        
        let content = {
          sort: [],
          messageContent: ""
        };
        let tmpContent = this.editData.msgContent == '{}' ? '' : this.editData.msgContent

        try {
          content = JSON.parse(tmpContent);
        } catch (e) {
          content = {
            sort: [],
            messageContent: tmpContent
          };
        }
        console.log("content", content);
        const contentArray = (content.messageContent || '').split("%s");
        const sort = content.sort;
        let editContent = [];
        for (let i = 0; i < sort.length; i++) {
          const variable = this.smsVariables.find(o => { return o.param == sort[i].replace('$', '') });
          editContent.push({
            insert: contentArray[i]
          });
          console.log(this.smsVariables, sort[i])
          editContent.push({
            insert: {
              tag: `# ${variable ? variable.paramCn : ''} #`
            }
          });
        }
        editContent.push({
          insert: contentArray[contentArray.length - 1]
        });
        this.$nextTick(() => {
          // this.editor = this.$refs.msgContent.quill;
          let dom = this.$refs.msgContent.$el
          this.editor = new Quill(dom, this.editorOption);
          this.editor.setContents(editContent);
          this.editor.setSelection(this.editor.getLength() - 1);
        });

        // 组合参数
        let paramContent = []
        const msgControllableContent = this.editData.msgControllableContent
        try {
          paramContent = JSON.parse(msgControllableContent);
        } catch (e) {
          paramContent = []
        }
        this.smsVariables.filter(o => { return o.paramType == 2 || o.paramType == 3 }).map(o => {
          const item = paramContent.find(vv => { return vv.name == o.param })
          let l = []
          if (item) {
            const c = item.messageContent.split("%s")
            const s = item.sort
            for (let i = 0; i < s.length; i++) {
              const v = this.smsVariables.find(o => { return o.param == s[i] });
              l.push({
                insert: c[i]
              });
              l.push({
                insert: {
                  tag: `# ${v ? v.paramCn : ''} #`
                }
              });
            }
            l.push({
              insert: c[c.length - 1]
            });
          }
          this.$nextTick(() => {
            // 监听组合参数的输入框变化
            this.paramEditor[o.param] = this.$refs['msgContent_' + o.param][0].quill;
            this.paramEditor[o.param].on('text-change', () => {
              const pContents = this.paramEditor[o.param].getContents().ops;
              let pMessage = ''
              pContents.map(o => {
                const pAppend = o.insert;
                if (pAppend.tag) {
                  const pTag = pAppend.tag.replace(/#/g, '')
                  const pVariable = this.smsVariables.find(so => {
                    return so.paramCn == pTag.trim()
                  }) || {}
                  pMessage += pVariable.paramExample || ''
                } else {
                  pMessage += pAppend;
                }
              })

              let a = this.$deepClone(this.paramEditorContent)
              a[o.param] = pMessage.trim()
              this.paramEditorContent = this.$deepClone(a)
            })
            this.paramEditor[o.param].setContents(l);
          });
        })
      })
    },
    insertTagValue(tag) {
      if (this.focusEditor && this.paramEditor[this.focusEditor]) {
        if (tag.paramType == 4 && this.focusEditor != tag.parentParam) {
          console.log(tag)
          return this.$message.warning('请将焦点定位到对应的组合信息中')
        }
        this.paramEditor[this.focusEditor].focus();
        const range = this.paramEditor[this.focusEditor].getSelection();
        if (range) {
          this.paramEditor[this.focusEditor].deleteText(range.index, range.length);
          this.paramEditor[this.focusEditor].insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
          this.paramEditor[this.focusEditor].setSelection(range.index + 1);
        }
      } else {
        if (tag.paramType == 4) {
          return this.$message.warning('请将焦点定位到对应的组合信息中')
        }
        this.editor.focus();
        const range = this.editor.getSelection();
        if (range) {
          this.editor.deleteText(range.index, range.length);
          this.editor.insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
          this.editor.setSelection(range.index + 1);
        }
      }
    },
    insertTagValueToMain(tag) {
      const pContents = this.paramEditorContent[tag.param]
      console.log(pContents)
      if (!pContents) return
      this.focusEditor = ''
      this.editor.focus();
      const range = this.editor.getSelection();
      if (range) {
        this.editor.deleteText(range.index, range.length);
        this.editor.insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
        this.editor.setSelection(range.index + 1);
      }
    },
    handleFocusEditor(tag) {
      this.focusEditor = tag ? tag.param : ''
    },
    confirm() {
      if (!this.editor) {
        return;
      }
      const contents = this.editor.getContents().ops;
      let messageContent = "";
      let sort = [];
      for (let i = 0; i < contents.length; i++) {
        const append = contents[i].insert;
        if (append.tag) {
          const tag = append.tag.replace(/\$|#/g, '')
          const variable = this.smsVariables.find(o => {
            return o.paramCn == tag.trim()
          })
          messageContent += '%s';
          if (variable.paramType == 1) {
            sort.push(variable.param);
          } else {
            sort.push('$' + variable.param);
          }
        } else {
          messageContent += append;
        }
      }
      const templateContent = {
        sort,
        messageContent
      };
      let msgControllableContent = []
      for(var i in this.paramEditor) {
        console.log(this.paramEditor[i].container.id)
        const contents = this.paramEditor[i].getContents().ops;
        let m = "";
        let s = [];
        for (let a = 0; a < contents.length; a++) {
          const append = contents[a].insert;
          if (append.tag) {
            const tag = append.tag.replace(/#/g, '')
            const variable = this.smsVariables.find(o => {
              return o.paramCn == tag.trim()
            })
            m += '%s';
            s.push(variable.param);
          } else {
            m += append;
          }
        }
        msgControllableContent.push({
          name: this.paramEditor[i].container.id,
          sort: s,
          messageContent: m.trim()
        })
      }
      let params = {
        templateId: this.editData.id,
        msgContent: JSON.stringify(templateContent),
        msgControllableContent: JSON.stringify(msgControllableContent),
        sendRule: JSON.stringify(this.sendRule)
      }
      if (this.info.msgType == 3) {
        params.sendRule = JSON.stringify({
          ...this.sendRule,
          sendFlag: this.sendFlagChecked ? 1 : 0,
          roleContent: `预约到店时间前${this.sendRule.config}小时自动发送`
        })
      }
      console.log(params)
      // if(params) return
      update_sms_template(params).then(() => {
        this.$message.success('保存成功')
        this.$emit('confirm')
        this.hideDialog()
      })
    },
  }
}
</script>

<style lang="scss">
.sms-editor{
  &.main-content{
    height: 500px;
    .ql-editor{
      & > p:first-child {
        text-indent: 68px;
      }
    }
  }
  .ql-editor input{
    background: transparent;
    border: none;
    outline: none;
    color: #65829A;
    padding: 0 5px;
  }
}
.sms-editor2{
  &.main-content{
    height: 500px;
    .ql-editor{
      & > p:first-child {
        text-indent: 68px;
      }
    }
  }
  .ql-editor input{
    background: transparent;
    border: none;
    outline: none;
    color: #409eff;
    padding: 3px 10px;
    border: 1px solid #409eff;
    border-radius: 2px;
    margin: 2px;
    font-size: 12px;
  }
}
</style>
<style lang="scss" scoped>
.sms-header{
  position: absolute;
  left: 15px;
  top: 16px;
  font-size: 13px;
  line-height: 1;
}
.params-div{
  padding: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  max-height: 600px;
  overflow: auto;
  .tag-title {
    background: #F5F6FB;
    padding: 10px;
    &:before{
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      background: #AAAAAA;
      margin-right: 10px;
      vertical-align: 3px;
    }
  }
  .tag-group {
    border: 1px solid #f4f4f4;
    padding: 5px;
  }
  .tag-item {
    color: #d14;
    opacity: 0.65;
    margin: 1px 3px;
    padding: 1px 6px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  .icon-add{
    background: url('../../../../assets/icons/add2.png') no-repeat center center/100% 100%;
    width: 16px;
    height: 16px;
    display: block;
    &.disabled{
      opacity: 0.4;
    }
  }
  .light-blue{
    color: #65829A;
  }
}
.preview-container {
  width: 250px;
  height: 540px;
  padding: 20px;
  margin: 0 auto;
  background: url('../../../../assets/img/iphone.png') no-repeat center center/100% 100%;
  .msg-con {
    width: auto;
    min-height: 30px;
    max-height: 270px;
    overflow: auto;
    border-radius: 8px;
    word-wrap: break-word;
    margin: 100px 20px 0;
    background-color: #E9E9EB;
    color: #666;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
  }
}
</style>