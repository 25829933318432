<template>
  <div class="discount">
    <div class="flex flex-between">
      <div class="page-sub-title flex flex-center mb-0">
        <span class="flex-1">收款项目初始化</span>
      </div>
      <div class="flex flex-align-center">
        <el-input class="flex-1 mr-10" clearable v-model="searchForm" placeholder="请输入收款项目名称搜索" size="small" @keyup.enter.native="getList(searchForm)"></el-input>
        <el-button size="small" type="primary" @click="getList(searchForm)">搜索</el-button>
      </div>
    </div>
      <div class="mt-20 flex">
        <div class="s-right-box flex-1">
          <div class="r-top flex flex-align-center b-1">
            <span class="p-15 flex-1 text-center">收款项目编号</span>
            <span class="p-15 flex-1 text-center">收款项目名称</span>
            <span class="p-15 flex-1 text-center">是否初始化</span>
            <span class="p-15 flex-1 text-center">是否可修改</span>
            <span class="p-15 flex-1 text-center">所属收款类型</span>
            <span class="p-15 flex-1 text-center">创建时间</span>
            <span class="p-15 flex-1 text-center">操作</span>
          </div>
          <div class="pl-20" :class="{'mt-10 mb-10':!isAdd}">
            <el-button v-if="!isAdd" size="small" class="border-btn" @click="isAdd = true">+ 新增收款项目</el-button>
            <div class="source-list2 pt-10 pb-10" v-if="isAdd">
              <span class="items">
                <el-input class="flex-1" v-model="form.value" placeholder="请输入收款项目名称" size="small" maxlength="8" show-word-limit></el-input>
              </span>
              <span class="items flex flex-center">
                <span class="mr-10">所属收款类型</span>
                <!-- <el-switch v-model="form.depositFlag" size="small"></el-switch> -->
                <el-select v-model="form.depositFlag" size="small">
                  <el-option label="回款" :value="0"> </el-option>
                  <el-option label="定金" :value="1"> </el-option>
                  <el-option label="退款" :value="2"> </el-option>
                </el-select>
              </span>
              <span class="items">
                <el-button size="small" type="primary" @click="addList">保存</el-button>
                <el-button size="small" class="border-btn" @click="isAdd = false">取消</el-button>
              </span>
            </div>
          </div>
          <draggable class="draggable" v-loading="loading" v-model="listData" chosenClass="chosen" forceFallback="true" group="item" handle=".mover" animation="500" @end="onEnd">
            <transition-group>
              <div class="source-list" v-for="(o, i) in listData" :key="i" :data-id="o.id">
                <span class="item">
                  <el-tooltip effect="dark" content="上下拖动排序" placement="left">
                    <span class="pointLine mover">
                      <span class="point" v-for="(o, i) in 6" :key="i"></span>
                    </span>
                  </el-tooltip>
                  <span style="min-width:92px">{{ o.code }}</span>
                  <!-- <div class="pl-10 pr-10">
                    <el-input maxlength="8" show-word-limit class="flex-1" v-model="o.value" size="small" @change="update(o)"></el-input>
                  </div> -->
                </span>
                <span class="item">
                  <div class="pl-10 pr-10">
                    <el-input maxlength="8" show-word-limit class="flex-1" v-model="o.value" size="small" @change="update(o)"></el-input>
                  </div>
                </span>
                <span class="item">
                  <el-switch
                    @change="changeHotelConfigInitFlag(o)"
                    v-model="o.flag">
                  </el-switch>
                </span>
                <span class="item">
                  <el-select v-model="o.allowUpdate" size="small" :class="o.allowUpdate?'select':'select1'" @change="update(o)">
                    <el-option label="是" :value="true"> </el-option>
                    <el-option label="否" :value="false"> </el-option>
                  </el-select>
                </span>
                <span class="item">
                  <!-- <el-switch v-model="o.depositFlag" size="small" @change="update(o)"></el-switch> -->
                  <el-select v-model="o.depositFlag" size="small" @change="update(o)">
                    <el-option label="回款" :value="0"> </el-option>
                    <el-option label="定金" :value="1"> </el-option>
                    <el-option label="退款" :value="2"> </el-option>
                  </el-select>
                </span>
                <span class="item">
                  {{o.createTime}}
                </span>
                <span class="item">
                  <el-popconfirm
                  title="若删除该收款项目，新开户的酒店将不显示该收款项目，确定删除？"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  @confirm="deleteSelect(o)"
                >
                  <el-button slot="reference" type="text"
                    >删除</el-button
                  >
                </el-popconfirm> 
                </span>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { post_addCollectionItemInit, get_getCollectionItemInitList, post_updateCollectionItemInit, post_removeHotelConfigInit, post_changeSort,post_changeHotelConfigInitFlag } from "@/api/base";
const form =  {
  value: '',
  explain: '',
  depositFlag: 0
}
export default {
  name: "discount",
  components: {
    draggable,
  },
  data() {
    return {
      loading: true,
      dialogVisible: false,
      isAdd: false,
      drag: false,
      listData: [],
      form: this.$deepClone(form),
      searchForm: ''
    };
  },
  computed: {},
  watch: {
    isAdd(val) {
      if(!val) {
        this.form = this.$deepClone(form)
      }
    }
  },
  methods: {
    //获取初始化列表
    getList(val) {
      get_getCollectionItemInitList({
        searchKey: val
      }).then(res => {
        this.loading = false
        this.listData = res
      })
    },
    //新增
    addList() {
      if(this.form.value === '') {
        this.$message({
          message: "名称不能为空",
          type: "warning",
        });
      } else {
        post_addCollectionItemInit({
          ...this.form
        }).then(() => {
          this.$message({
            message: "新增成功",
            type: "success",
          })
          this.getList()
          this.isAdd = false
        })
      }
    },
    // 更新
    update(val) {
      if(val.value === '') {
        this.$message({
          message: "名称不能为空",
          type: "warning",
        });
        this.getList()
      } else {
        post_updateCollectionItemInit({
          id: val.id,
          value: val.value,
          explain: val.explain,
          allowUpdate: val.allowUpdate,
          depositFlag: val.depositFlag
        }).then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getList()
        })
      }
    },
    changeHotelConfigInitFlag(val) {
      post_changeHotelConfigInitFlag({
        id: val.id,
        flag: val.flag,
      }).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      })
    },
    
    //删除
    deleteSelect(val) {
      console.log(val)
      post_removeHotelConfigInit({
        id: val.id
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
       this.getList()
      }) 
    },
    //修改排序
    changeSort(val) {
      post_changeSort({
        index: val.newIndex,
        hotelConfigInitId: val.clone.dataset.id,
        configType: 7
      }).then(() => {
        this.getList()
      })
    },
    onEnd(val) {
      this.changeSort(val)
      this.drag = false
    },
  },
};
</script>

<style scoped lang="scss">
.s-right-box {
    ::v-deep {
      .el-collapse {
        .el-collapse-item__header {
          background: #f9f9f9;
        }
        .el-collapse-item__content {
          padding-bottom: 0;
        }
      }
    }
    .r-top {
      span {
        border-right: 1px solid #eeeeee;
        &:last-child {
          border-width: 0;
        }
      }
    }
    .draggable {
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
    }
    .source-list2 {
       display: flex;
        align-items: center;
        align-items: stretch;
        .items {
          border-right: unset;
          margin: 0 10px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                border: 1px solid #eeeeee;
              }
            }
          }
        }
    }
    .source-list {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      min-height: 45px;
      align-items: stretch;
      &:last-child {
        border-bottom: 1px solid #eeeeee;
      }
      &:first-child {
        border-top: 1px solid #eeeeee;
      }
      .item {
        flex: 1;
        border-right: 1px solid #eeeeee;
        // line-height: 45px;
        color: #333333;
        text-align: center;
        font-size: 14px;
        // padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &:last-child {
          border-width: 0;
        }
        .select {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #e6f8e6;
                color: #64a264;
                border-width: 0;
              }
              .el-select__caret {
                color: #64a264;
              }
            }
          }
        }
        .select1 {
          width: 95px;
          ::v-deep {
            .el-input {
              .el-input__inner {
                background: #f1f1f1;
                color: #666666;
                border-width: 0;
              }
              .el-select__caret {
                color: #666666;
              }
            }
          }
        }
        .pointLine {
          opacity: 0;
          width: 7px;
          height: 12px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 4px;
          margin-left: 5px;
          flex-shrink: 0;
          &:hover {
            background: rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            .point {
              background: #409eff;
            }
          }
          .point {
            width: 2px;
            height: 2px;
            border-radius: 2px;
            background: #999999;
            margin-right: 1px;
            flex: 0 0 30%;
          }
          .active {
            background: #409eff !important;
          }
        }
        &:hover {
          .pointLine {
            opacity: 1;
          }
        }
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid transparent;
              &:hover {
                border: 1px solid #409eff;
                +.el-input__suffix {
                  opacity: 1;
                }
              }
            }
          }
          .el-date-editor--timerange.el-input__inner {
            width: unset;
            border-width: 0;
          }
          .el-input__suffix {
            opacity: 0;
          }
        }
      }
      .items {
        border-right: unset;
        margin: 0 10px;
        ::v-deep {
          .el-input {
            .el-input__inner {
              border: 1px solid #eeeeee;
            }
          }
        }
      }
    }
  }
  .w100 {
  width: 100%;
}
.b-1 {
  background: #f5f6fb;
}
.i-hover {
  color: #cccccc;
  &:hover {
    color: #409eff;
  }
}
.c-1 {
  color: #a4a4a4;
}
</style>
