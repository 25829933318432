<template>
  <el-dialog
    title="选择图标"
    :visible.sync="visible"
    width="700px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog"
    destroy-on-close
    append-to-body
  >
    <div>
      <div class="flex flex-align-center mb-20">
        <span class="mr-20">图标来源</span>
        <el-radio-group v-model="form.svgType">
          <el-radio :label="1">图标库</el-radio>
          <el-radio :label="2">自定义上传</el-radio>
        </el-radio-group>
      </div>
      <!-- 图标库 -->
      <div class="content flex flex-wrap flex-between" v-show="form.svgType == 1">
        <div class="svg-item" v-for="item in 12" :key="'A' + item" @click="chooseSvg('icon-svg1-A' + item)" :style="{'border': svg == 'icon-svg1-A' + item ? '1px solid #409eff' : ''}">
          <span class="font-size-18" :class="'icon-svg1-A' + item" ></span>
        </div>
        <div class="svg-item" v-for="item in 12" :key="'B' + item" @click="chooseSvg('icon-svg1-B' + item)" :style="{'border': svg == 'icon-svg1-B' + item ? '1px solid #409eff' : ''}">
          <span class="font-size-18" :class="'icon-svg1-B' + item"></span>
        </div>
        <div class="svg-item" v-for="item in 12" :key="'C' + item" @click="chooseSvg('icon-svg1-C' + item)" :style="{'border': svg == 'icon-svg1-C' + item ? '1px solid #409eff' : ''}">
          <span class="font-size-18" :class="'icon-svg1-C' + item"></span>
        </div>
        <div class="svg-item" v-for="item in 12" :key="'D' + item" @click="chooseSvg('icon-svg1-D' + item)" :style="{'border': svg == 'icon-svg1-D' + item ? '1px solid #409eff' : ''}">
          <span class="font-size-18" :class="'icon-svg1-D' + item"></span>
        </div>
        <div class="svg-item" v-for="item in 12" :key="'E' + item" @click="chooseSvg('icon-svg1-E' + item)" :style="{'border': svg == 'icon-svg1-E' + item ? '1px solid #409eff' : ''}">
          <span class="font-size-18" :class="'icon-svg1-E' + item"></span>
        </div>
      </div>
      <!-- 自定义上传 -->
      <div class="content" v-show="form.svgType == 2">
        <div class="tips flex flex-center">
          <span>请上传色值为#ffffff的图标；建议尺寸为26*26px,支持png格式</span>
        </div>
        <div class="flex flex-center mt-30 pb-60">
          <div class="icon-upload-item">
            <!-- <div class="label">选中图标</div> -->
            <div class="upload-inner">
              <div class="upload-img">
                <el-image v-if="selectedImg" :src="selectedImg" style="width: 100%;height: 100%;"></el-image>
              </div>
              <upload :showFileList="false" @change="selectedImg = $event[0]">
                <el-button class="border-btn" plain size="small">去上传</el-button>
              </upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import upload from '@/components/upload'
export default {
  components: {
    upload
  },
  props: {
    visible: {
      type: Boolean,
    },
    color: {
      type: String,
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = this.$deepClone(this.item)
        if (this.form.svgType == 1) {
          this.svg = this.form.icon
        } else {
          this.selectedImg = this.form.icon
          this.unSelectedImg = this.form.icon
        }
      } else {
        this.form = {}
        this.svg = ''
        this.selectedImg = ''
        this.unSelectedImg = ''
      }
    }
  },
  data() {
    return {
      form: {},
      svg: '',
      selectedImg: '',
      unSelectedImg: ''
    }
  },
  methods: {
    hideDialog() {
      this.$emit('update:visible', false)
    },
    chooseSvg(item) {
      this.svg = item
    },
    confirm() {
      if (!this.svg && this.form.svgType == 1) {
        return this.$message.warning('请选择图标')
      }
      if (!this.selectedImg && this.form.svgType == 2) {
        return this.$message.warning('请上传图标')
      }
      let params = this.$deepClone(this.form)
      if (params.svgType == 1) {
        params.icon = this.svg
      } else {
        params.icon = this.selectedImg
      }
      this.$emit('confirm', params)
      this.hideDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  background: #F4F4F4;
  padding: 15px 12px;
  .svg-item{
    width: 40px;
    height: 40px;
    background: #ffffff;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .tips{
    background: #EBEBEB;
    text-align: center;
    color: #666666;
    font-size: 13px;
    height: 32px;
  }
  .icon-upload-item{
    text-align: center;
    .label{
      color: #666666;
      margin-bottom: 12px;
    }
    .upload-inner{
      background: #ffffff;
      padding: 10px;
      .upload-img{
        width: 100px;
        height: 100px;
        background: #f3f3f3;
        margin-bottom: 15px;
      }
    }
  }
}
</style>