<template>
  <div class="source">
    <div class="flex flex-between flex-align-center">
      <div class="page-sub-title">零点预订相关显示设置</div>
    </div>

    <div class="btn-container mt-20">
      <div class="btn-item mb-10" v-for="btn in pageList" :key="btn.id">
        <span class="mr-20">
          {{ noteMap[btn.code] }}
          <el-tooltip :content="noteDescMap[btn.code]">
            <span class="el-icon-question" v-if="noteDescMap[btn.code]"></span>
          </el-tooltip>
        </span>
        <el-switch
          v-model="btn.value"
          active-value="1"
          inactive-value="0"
          @change="updateBtn(btn)"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_hotelConfigInitConfigTypeList,
  post_updateHotelConfigInitConfigType,
} from "@/api/base";

export default {
  name: "setting",
  props: ["hasAuth"],
  data() {
    return {
      pageList: [],
      noteMap: {
        ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME: "台位客户姓名完整显示",
        ZERO_ARRIVE_TIME_CONFIG: "到店时间必填",
      },
      noteDescMap: {
        ZERO_ARRIVE_TIME_CONFIG: '开启后，零点预订相关订单必填到店时间，否则无法完成预订'
      }
    };
  },
  mounted () {
    this.getConfigs();
  },
  methods: {
    getConfigs() {
      get_hotelConfigInitConfigTypeList({
        configKList: 'ZERO_ARRIVE_TIME_CONFIG,ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME'
      }).then((res) => {
        this.pageList = res || []
      });
    },
    updateBtn(val) {
      post_updateHotelConfigInitConfigType({
        ...val
      }).then((res) => {
        this.$message.success(res);
        this.getConfigs()
      });
    },
  },
};
</script>

<style scoped lang="scss">
.source {
  .page-sub-title {
    padding-top: 6px;
  }
  .page-sub-title::before {
    margin-top: -2px;
  }
}
// }
.w100 {
  width: 100%;
}
.b-1 {
  background: #f5f6fb;
}
.c-1 {
  color: #a4a4a4;
}
</style>
