<template>
  <div class="contractModel" v-loading="loading">
    <div class="flex flex-between flex-align-center plr-20 pt-10 pb-10 bb-1 toolBar">
      <span class="cursor-point" @click="quitEdit">
        <i class="el-icon-arrow-left mr-5"></i>
        退出编辑
      </span>
      <span>
        <el-button class="border-btn" size="small" plain @click="save('preview')">预览</el-button>
        <el-button size="small" type="primary" @click="save('save')">保存</el-button>
      </span>
    </div>
    <div class="flex" style="height: calc(100vh - 190px);">
      <div class="left o-auto">
        <div class="page-sub-title flex flex-align-center">
          <span class="">变量参数</span>
          <span class="c-1 font-size-13 ml-10">点击相关参数即可添加至右侧光标处</span>
        </div>
        <el-collapse v-model="active">
          <el-collapse-item v-for="(o,i) in leftListOriginal" :key="i" :name="i">
            <template slot="title">
              <div class="l-label flex flex-align-center pl-10 font-size-15">
                <span class="point mr-10"></span>
                {{o.paramGroupName}}
              </div>
            </template>
            <div class="flex flex-wrap p-10">
              <div
                v-for="(tag, index) in o.templateParamList"
                :key="index"
                :class="{'inline-block': tag.paramType == 1}"
              >
                <el-tag
                  effect="plain"
                  size="small"
                  class="mr-5 mb-5 cursor-point"
                  v-if="tag.paramType == 1" @click="addLabel(tag)"
                >
                  #{{tag.paramCn}}#
                </el-tag>
                <div v-else class="mt-10" style="width: 100%">
                  <div class="flex flex-center">
                    <span class="light-blue">#{{tag.paramCn}}#</span>
                    <span class="grey flex-1 font-size-12 ml-5">{{tag.paramExample}}</span>
                    <span class="icon-add" :class="{'disabled': !paramEditorContent[tag.param]}" @click.stop="insertTagValueToMain(tag)"></span>
                  </div>
                  <div class="mt-10" v-if="tag.privateParams">
                    <div
                      v-for="(tag0, index) in tag.privateParams"
                      :key="index"
                      :class="'inline-block'"
                    >
                      <el-tag
                        effect="plain"
                        size="small"
                        class="mr-5 mb-5 cursor-point"
                        @click="addLabel(tag0)"
                      >
                        #{{tag0.paramCn}}#
                      </el-tag>
                    </div>
                  </div>
                  <quill-editor class="sms-editor2 mt-10" :ref="'msgContent_' + tag.param" :id="tag.param" :options="editorOption0" @click.native="handleFocusEditor(tag)"></quill-editor>
                </div>
              </div>
              <!-- <span class="l-item mr-5 mb-5 cursor-point" @click="addLabel(p)" v-for="(p,q) in o.templateParamList" :key="q">
                #{{p.paramCn}}#
              </span> -->
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="right pt-20 pr-20 pb-20">
        <quill-editor class="main-content" ref="msgContent" :options="editorOption" @click.native="handleFocusEditor()"></quill-editor>
      </div>
    </div>
    <el-image-viewer
      style="z-index:3000"
      v-if="urlShow"
      :on-close="()=>{urlShow = false,loading = false}"
      :url-list="urlList" />
    <el-dialog
      :visible.sync="previewVisible"
      title="合同预览"
      width="628px"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      class="previewDialog ql-editor"
      append-to-body
    >
      <div v-html="previewDetail">

      </div>
    </el-dialog>  
  </div>
</template>

<script>
import "../../../../quill-develop/dist/quill.core.css";
import "../../../../quill-develop/dist/quill.snow.css";
import "../../../../quill-develop/dist/quill.bubble.css";
// import Quill from "quill";
let Quill = require('../../../../quill-develop/dist/quill')
import { quillEditor } from "vue-quill-editor";
import BtnBlot from "@/components/quillEmbedBtn";
// import html2canvas from 'html2canvas'
import { get_contractTemplateParams, get_contractInitTemplate, post_saveContractTemplate, get_contractTemplateContent } from '@/api/base'
// import { upload_file } from '@/api/common'

Quill.register(BtnBlot);
const ICON_SVGS = {
  'table-insert-row': `<svg viewbox="0 0 18 18">
    <g class="ql-fill ql-stroke ql-thin ql-transparent">
      <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="2.5"></rect>
      <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="12.5"></rect>
    </g>
    <rect class="ql-fill ql-stroke ql-thin" height="3" rx="0.5" ry="0.5" width="7" x="8.5" y="7.5"></rect>
    <polygon class="ql-fill ql-stroke ql-thin" points="4.5 11 2.5 9 4.5 7 4.5 11"></polygon>
    <line class="ql-stroke" x1="6" x2="4" y1="9" y2="9"></line>
  </svg>`,
  'table-insert-column': `<svg viewbox="0 0 18 18">
    <g class="ql-fill ql-transparent">
      <rect height="10" rx="1" ry="1" width="4" x="12" y="2"></rect>
      <rect height="10" rx="1" ry="1" width="4" x="2" y="2"></rect>
    </g>
    <path class="ql-fill" d="M11.354,4.146l-2-2a0.5,0.5,0,0,0-.707,0l-2,2A0.5,0.5,0,0,0,7,5H8V6a1,1,0,0,0,2,0V5h1A0.5,0.5,0,0,0,11.354,4.146Z"></path>
    <rect class="ql-fill" height="8" rx="1" ry="1" width="4" x="7" y="8"></rect>
  </svg>`,
  'table-delete-row': `<svg viewbox="0 0 18 18">
    <g class="ql-fill ql-stroke ql-thin ql-transparent">
      <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="2.5"></rect>
      <rect height="3" rx="0.5" ry="0.5" width="7" x="4.5" y="12.5"></rect>
    </g>
    <rect class="ql-fill ql-stroke ql-thin" height="3" rx="0.5" ry="0.5" width="7" x="8.5" y="7.5"></rect>
    <line class="ql-stroke ql-thin" x1="6.5" x2="3.5" y1="7.5" y2="10.5"></line>
    <line class="ql-stroke ql-thin" x1="3.5" x2="6.5" y1="7.5" y2="10.5"></line>
  </svg>`,
  'table-delete-column': `<svg viewbox="0 0 18 18">
    <g class="ql-fill ql-transparent">
      <rect height="10" rx="1" ry="1" width="4" x="2" y="6"></rect>
      <rect height="10" rx="1" ry="1" width="4" x="12" y="6"></rect>
    </g>
    <rect class="ql-fill" height="8" rx="1" ry="1" width="4" x="7" y="2"></rect>
    <path class="ql-fill" d="M9.707,13l1.146-1.146a0.5,0.5,0,0,0-.707-0.707L9,12.293,7.854,11.146a0.5,0.5,0,0,0-.707.707L8.293,13,7.146,14.146a0.5,0.5,0,1,0,.707.707L9,13.707l1.146,1.146a0.5,0.5,0,0,0,.707-0.707Z"></path>
  </svg>`
}
const titleConfig = [
  { Choice: '.ql-insertMetric', title: '跳转配置' },
  { Choice: '.ql-bold', title: '加粗' },
  { Choice: '.ql-italic', title: '斜体' },
  { Choice: '.ql-underline', title: '下划线' },
  { Choice: '.ql-header', title: '段落格式' },
  { Choice: '.ql-strike', title: '删除线' },
  { Choice: '.ql-blockquote', title: '块引用' },
  { Choice: '.ql-code', title: '插入代码' },
  { Choice: '.ql-code-block', title: '插入代码段' },
  { Choice: '.ql-font', title: '字体' },
  { Choice: '.ql-size', title: '字体大小' },
  { Choice: '.ql-list[value="ordered"]', title: '编号列表' },
  { Choice: '.ql-list[value="bullet"]', title: '项目列表' },
  { Choice: '.ql-direction', title: '文本方向' },
  { Choice: '.ql-header[value="1"]', title: 'h1' },
  { Choice: '.ql-header[value="2"]', title: 'h2' },
  { Choice: '.ql-align', title: '对齐方式' },
  { Choice: '.ql-color', title: '字体颜色' },
  { Choice: '.ql-background', title: '背景颜色' },
  { Choice: '.ql-image', title: '图像' },
  { Choice: '.ql-video', title: '视频' },
  { Choice: '.ql-link', title: '添加链接' },
  { Choice: '.ql-formula', title: '插入公式' },
  { Choice: '.ql-clean', title: '清除字体格式' },
  { Choice: '.ql-script[value="sub"]', title: '下标' },
  { Choice: '.ql-script[value="super"]', title: '上标' },
  { Choice: '.ql-indent[value="-1"]', title: '向左缩进' },
  { Choice: '.ql-indent[value="+1"]', title: '向右缩进' },
  { Choice: '.ql-header .ql-picker-label', title: '标题大小' },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: '标题一' },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: '标题二' },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: '标题三' },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: '标题四' },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: '标题五' },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: '标题六' },
  { Choice: '.ql-header .ql-picker-item:last-child', title: '标准' },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: '小号' },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: '大号' },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: '超大号' },
  { Choice: '.ql-size .ql-picker-item:nth-child(2)', title: '标准' },
  { Choice: '.ql-align .ql-picker-item:first-child', title: '居左对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="center"]', title: '居中对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="right"]', title: '居右对齐' },
  { Choice: '.ql-align .ql-picker-item[data-value="justify"]', title: '两端对齐' },
  { Choice: '.ql-table', title: '表格' },
  { Choice: '.ql-table-insert-row', title: '插入行' },
  { Choice: '.ql-table-insert-column', title: '插入列' },
  { Choice: '.ql-table-delete-row', title: '删除行' },
  { Choice: '.ql-table-delete-column', title: '删除列' },
]
import _ from 'lodash'
export default {
  name: '',
  components: {
    quillEditor,
    'el-image-viewer':()=>import('element-ui/packages/image/src/image-viewer')
  },
  data() {
    return {
      active: [0,1,2,3,4,5,6,7,8,9,10],
      leftList: [],
      leftListOriginal: [],
      editorOption: {
        theme: 'snow',
        modules: {
          table: true, // 设为true
          toolbar:{
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
              // ['blockquote', 'code-block'], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
              [{ script: 'sub' }, { script: 'super' }], // 上标/下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              // [{ direction: 'rtl' }], // 文本方向
              [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ['clean'], // 清除文本格式
              // ['image', 'video'], // 链接、图片、视频
              [
                {'table': 'TD'},
                {'table-insert-row': 'TIR'},
                {'table-insert-column': 'TIC'},
                {'table-delete-row': 'TDR'},
                {'table-delete-column': 'TDC'}
              ],
            ],
            handlers: {
              'table': this._tableHandler,
              'table-insert-row': this._tableInsertRowHandler,
              'table-insert-column': this._tableInsertColumnHandler,
              'table-delete-row': this._tableDeleteRowHandler,
              'table-delete-column': this._tableDeleteColumnHandler
            }
          }
          
        },
        placeholder: ""
      },
      editorOption0: {
        theme: 'snow',
        modules: {
          toolbar: false
        },
        placeholder: ""
      },
      editData: {},
      smsVariables: [],
      editor: null,
      paramEditor: {},
      focusEditor: '',
      paramEditorContent: {},
      disorderList: [],
      urlShow: false,
      urlList: [],
      loading: false,
      newContent: '',
      oldContent: '',
      previewVisible: false,
      previewDetail: ''
    };
  },
  computed:{
  },
  watch:{
  },
  methods: {
    _tableHandler () {
      this.editor.getModule('table').insertTable(2, 3)
    },
    _tableInsertRowHandler () {
      this.editor.getModule('table').insertRowBelow()
    },
    _tableInsertColumnHandler () {
      this.editor.getModule('table').insertColumnRight()
    },
    _tableDeleteRowHandler () {
      this.editor.getModule('table').deleteRow()
    },
    _tableDeleteColumnHandler () {
      this.editor.getModule('table').deleteColumn()
    },
    getList() {
      get_contractTemplateParams({
        hotelId: 0
      }).then(res => {
        this.leftListOriginal = res || []
        this.leftListOriginal.map(o => {
          o.templateParamList = o.templateParamList.map(v => {
            return {
              ...v,
              privateParams: v.privateParams ? v.privateParams.map(vv => {
                return {
                  ...vv,
                  parentParam: v.param
                }
              }) : null
            }
          })
          this.leftList = this.leftList.concat(o.templateParamList)
          o.templateParamList.map(p => {
            if (p.privateParams) {
              this.leftList = this.leftList.concat(p.privateParams)
            }
          })

          this.smsVariables = this.smsVariables.concat(o.templateParamList)
          o.templateParamList.map(p => {
            if (p.privateParams) {
              this.smsVariables = this.smsVariables.concat(p.privateParams)
            }
          })
        })
        this.getDetail()
      })
    },
    getDetail(type) {
      this.loading = true
      get_contractInitTemplate().then(res => {
        // console.log(res)
        this.editData = res
        let content = {
          sort: [],
          messageContent: ""
        };
        let tmpContent = this.editData.contentConfig == '{}' ? '' : this.editData.contentConfig

        try {
          content = JSON.parse(tmpContent);
        } catch (e) {
          content = {
            sort: [],
            messageContent: tmpContent
          };
        }
        var sort = content.sort;
        var contents = content.messageContent;
        for (let i = 0; i < sort.length; i++) {
          const variable = this.leftList.find(o => { return o.param == sort[i].replace('$', '') });
          contents = contents.replace('%s',`<input value="# ${variable ? variable.paramCn : ''} #" type="button" role="button">`)
          this.disorderList.push(variable.paramCn)
        }

        this.$nextTick(() => {
          if(type !== 'save') {
            let dom = this.$refs.msgContent.$el
            this.editor = new Quill(dom, this.editorOption);
            for (let item of titleConfig) {
              let tip = document.querySelector('.ql-formats ' + item.Choice)
              if (!tip) continue
              tip.setAttribute('title', item.title)
            }
            // console.log(this.editor,11)
          }
          
          this.editor.container.firstChild.innerHTML = contents
          // this.$refs.msgContent.quill.container.querySelector('.ql-blank').innerHTML = contents
          this.oldContent = contents
          // console.log(this.oldContent,'this.oldContent')
          // this.editor = this.$refs.msgContent.quill;
          this.editor.setSelection(this.editor.getLength() - 1);
          this.loading = false
        });



        // 组合参数
        let paramContent = []
        const controllableContentConfig = this.editData.controllableContentConfig
        try {
          paramContent = JSON.parse(controllableContentConfig);
        } catch (e) {
          paramContent = []
        }
        this.smsVariables.filter(o => { return o.paramType == 2 || o.paramType == 3 }).map(o => {
          const item = paramContent.find(vv => { return vv.name == o.param })
          let l = []
          if (item) {
            const c = item.messageContent.split("%s")
            const s = item.sort
            for (let i = 0; i < s.length; i++) {
              const v = this.smsVariables.find(o => { return o.param == s[i] });
              l.push({
                insert: c[i]
              });
              l.push({
                insert: {
                  tag: `# ${v ? v.paramCn : ''} #`
                }
              });
            }
            l.push({
              insert: c[c.length - 1]
            });
          }
          this.$nextTick(() => {
            // 监听组合参数的输入框变化
            let dom = this.$refs['msgContent_' + o.param][0].$el
            this.paramEditor[o.param] = new Quill(dom, this.editorOption0);
            // this.paramEditor[o.param] = this.$refs['msgContent_' + o.param][0].quill;
            this.paramEditor[o.param].on('text-change', () => {
              const pContents = this.paramEditor[o.param].getContents().ops;
              let pMessage = ''
              pContents.map(o => {
                const pAppend = o.insert;
                if (pAppend.tag) {
                  const pTag = pAppend.tag.replace(/#/g, '')
                  const pVariable = this.smsVariables.find(so => {
                    return so.paramCn == pTag.trim()
                  }) || {}
                  pMessage += pVariable.paramExample || ''
                } else {
                  pMessage += pAppend;
                }
              })

              let a = this.$deepClone(this.paramEditorContent)
              a[o.param] = pMessage.trim()
              this.paramEditorContent = this.$deepClone(a)
            })
            this.paramEditor[o.param].setContents(l);
          });
        })
      })
    },
    quitEdit() {
      this.newContent = this.editor.container.firstChild.innerHTML
      // console.log(this.newContent,'this.newContent')
      var reg = "/﻿/g";
      if(this.newContent.replace(eval(reg),'') == this.oldContent.replace(eval(reg), '')) {
        this.$parent.checkMenu(this.$parent.reloadList[0].list[0])
      } else {
        this.$confirm('有修改内容未保存，是否确认退出？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'dialog-class',
          center: true
        }).then(() => {
          this.$parent.checkMenu(this.$parent.reloadList[0].list[0])
        })
      } 
      
    },
    // 添加要素
    addLabel(tag) {
      if (this.focusEditor && this.paramEditor[this.focusEditor]) {
        if (tag.paramType !== 4) {
          return this.$message.warning('请将焦点定位到合同模板编辑框中')
        }
        if (tag.paramType == 4 && this.focusEditor != tag.parentParam) {
          // console.log(tag)
          return this.$message.warning('请将焦点定位到对应的组合信息中')
        }
        this.paramEditor[this.focusEditor].focus();
        const range = this.paramEditor[this.focusEditor].getSelection();
        if (range) {
          this.paramEditor[this.focusEditor].deleteText(range.index, range.length);
          this.paramEditor[this.focusEditor].insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
          this.paramEditor[this.focusEditor].setSelection(range.index + 1);
        }
      } else {
        if (tag.paramType == 4) {
          return this.$message.warning('请将焦点定位到对应的组合信息中')
        }
        this.editor.focus();
        const range = this.editor.getSelection();
        if (range) {
          this.disorderList.push(tag.paramCn)
          this.editor.deleteText(range.index, range.length);
          this.editor.insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
          this.editor.setSelection(range.index + 1);
        }
      }

      // if (tag.paramType == 4) {
      //   return this.$message.warning('请将焦点定位到对应的组合信息中')
      // }
      // this.editor.focus();
      // const range = this.editor.getSelection();
      // if (range) {
      //   this.disorderList.push(tag.paramCn)
      //   this.editor.deleteText(range.index, range.length);
      //   this.editor.insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
      //   this.editor.setSelection(range.index + 1);
      // }
      // // }
    },
    insertTagValueToMain(tag) {
      const pContents = this.paramEditorContent[tag.param]
      // console.log(pContents)
      if (!pContents) return
      this.focusEditor = ''
      this.editor.focus();
      const range = this.editor.getSelection();
      if (range) {
        this.editor.deleteText(range.index, range.length);
        this.editor.insertEmbed(range.index, "tag", `# ${tag.paramCn} #`);
        this.editor.setSelection(range.index + 1);
      }
    },
    handleFocusEditor(tag) {
      // console.log(tag,11)
      this.focusEditor = tag ? tag.param : ''
    },
    // handleChangeEditor(val) {
    //   console.log(val.html,'this.newContent')
    //   this.newContent = val.html
		// },
    save(type) {
      if (!this.editor) {
        return;
      }
      this.loading = true
      const contents = this.editor.getContents().ops;
      var html = this.editor.editor.scroll.domNode.innerHTML
      let sort = [];
      for (let i = 0; i < this.disorderList.length; i++) {
        // html = html.replace('<input value="# '+ this.disorderList[i] +' #" type="button" role="button">','%s')
        html = html.replace(/<input[^>]*role="button"[^>]*>/g, '%s')
      }
      // console.log(html)
      for (let i = 0; i < contents.length; i++) {
        const append = contents[i].insert;
        if (append.tag) {
          const tag = append.tag.replace(/\$|#/g, '')
          const variable = this.leftList.find(o => {
            return o.paramCn == tag.trim()
          })
          if(variable) {
            if (variable.paramType == 1) {
              sort.push(variable.param);
            } else {
              sort.push('$' + variable.param);
            }
          }
        }
      }

      let controllableContentConfig = []
      for(var i in this.paramEditor) {
        // console.log(this.paramEditor[i])
        const contents = this.paramEditor[i].getContents().ops;
        let m = "";
        let s = [];
        for (let a = 0; a < contents.length; a++) {
          const append = contents[a].insert;
          if (append.tag) {
            const tag = append.tag.replace(/#/g, '')
            const variable = this.smsVariables.find(o => {
              return o.paramCn == tag.trim()
            })
            m += '%s';
            s.push(variable.param);
          } else {
            m += append;
          }
        }
        controllableContentConfig.push({
          name: this.paramEditor[i].container.id,
          sort: s,
          messageContent: m.trim()
        })
      }

      this.$nextTick(() => {
        let messageContent = html
        const templateContent = {
          sort,
          messageContent
        };
        let params = {
          templateId: this.editData.id,
          contentConfig: JSON.stringify(templateContent),
          controllableContentConfig: JSON.stringify(controllableContentConfig),
        }
        // console.log(params)
        // if(params) return
        if(type == 'save') {
          post_saveContractTemplate(params).then(() => {
            this.$message.success('保存成功')
            this.getDetail('save')
            this.loading = false
          })
        } else if (type == 'preview') {
          this.loading = true
          get_contractTemplateContent({
            contentConfig: JSON.stringify(templateContent),
            controllableContentConfig: JSON.stringify(controllableContentConfig),
          }).then(res => {
            // console.log(res)
            this.previewDetail = res
            this.previewVisible = true
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        }
        
      });
    },
  },
  mounted() {
    let icons = Quill.import('ui/icons')
    // console.log(icons,'icons')
    // 从自定义图标SVG列表中找到对应的图标填入到原生图标库中
    _.forEach(ICON_SVGS, (iconValue, iconName) => {
      icons[iconName] = iconValue
    })

  }
};
</script>

<style scoped lang='scss'>
.left {
  flex: 0 0 24%;
  padding: 20px;
  .l-label {
    .point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #999;
    }
  }
  .l-item {
    padding: 0px 4px;
    color: #5290FF;
    border: 1px solid #5290FF;
    border-radius: 4px;
  }
}
.right {
  flex: 1;
  width: 500px;
  .main-content {
    height: calc(100vh - 308px);
    // width: 592px;
    // height: 100%;
    // min-height: 650px;
    // height: 100%;
    // min-height: 600px;
    // height: calc(100vh - 300px);
  }
}
::v-deep {
  .el-collapse {
    border: 0;
  }
  .el-collapse-item__header {
    background: #f4f5fa;
    border: 0;
    color: #666;
  }
  .el-collapse-item__content {
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    padding-bottom: 0;
  }
  .el-collapse-item__arrow {
    transform: rotate(-90deg);
  }
  .el-collapse-item__arrow.is-active {
    transform: rotate(90deg);
  }
  .ql-editor input{
    background: transparent;
    border: none;
    outline: none;
    color: #65829A;
    margin: 0 5px;
  }
  .main-content.ql-container {
    min-height: 650px;
  }
  .sms-editor2.ql-container{
    min-height: 150px;
    .ql-editor{
      min-height: 150px;
    }
    .ql-editor input{
      background: transparent;
      border: none;
      outline: none;
      color: #409eff;
      padding: 3px 10px;
      border: 1px solid #409eff;
      border-radius: 2px;
      margin: 2px;
      font-size: 12px;
    }
  }
  .el-dialog {
    margin-top: unset!important;
    height: 850px;
    .el-dialog__body {
      height: 730px;
      overflow: auto;
    }
  }
}
.toolBar {
  background: white;
  position: sticky;
  top: 0;
  z-index: 999;
}
.bb-1 {
  border-bottom: 1px solid #f2f2f2;
}
.c-1 {
  color: #a4a4a4;
}
.icon-add{
  background: url('../../../../assets/icons/add2.png') no-repeat center center/100% 100%;
  width: 16px;
  height: 16px;
  display: block;
  &.disabled{
    opacity: 0.4;
  }
}
.light-blue{
  color: #65829A;
}
</style>
