<template>
  <div v-loading="salesOpportunitiesLoading" class="salesOpportunities" ref="scroll">
    <div class="flex flex-align-center">
      <div class="page-sub-title mb-0">销售机会管理相关规则设置<span class="font-size-13 c-1 ml-10">销售机会管理模块的所有规则设置</span></div>
    </div>
    <div class="font-size-14 mt-20 s-title season2">跟进任务规则<span class="font-size-13 c-1 ml-10">系统将按照如下规则给员工自动分派任务</span></div>
    <div class="item mt-0">
      <el-table header-row-class-name="table-header" :data="tableData" border :key="timeNow" style="width: 100%" :span-method="spanMethod">
        <el-table-column prop="status" label="开启任务" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="任务内容" align="center" >
          <template slot-scope="scope">
            <div
              class="text-left"
              v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'"
            >
              （预计）宴会档期距分配时间小于
              <el-select v-model="scope.row.taskContent.timeConfig" @change="changeTime1" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5">
                <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
              </el-select>
              个月的销售机会持续跟进任务
              <el-popover placement="top-start" width="200" trigger="hover" content="如果分配时未明确宴会档期，则在明确宴会档期后再根据规则自动分派跟进任务；宴会档期与分配时间的间距则按照确认宴会档期的日期和宴会日期之间间距计算。">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </div>
            <div
              class="text-left"
              v-else-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'"
            >
              （预计）宴会档期距分配时间大于等于
              <el-select v-model="scope.row.taskContent.timeConfig" @change="changeTime1" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5">
                <el-option v-for="(o, i) in 12" :key="i" :label="i + 1" :value="i + 1"> </el-option>
              </el-select>
              个月的销售机会跟进任务
              <el-popover placement="top-start" width="200" trigger="hover" content="如果分配时未明确宴会档期，则在明确宴会档期后再根据规则自动分派跟进任务；宴会档期与分配时间的间距则按照确认宴会档期的日期和宴会日期之间间距计算。">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </div>
            <div class="text-left" v-else>
              {{ scope.row.taskContent.content }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="supplementRoleName" align="center" label="适用宴会类型">
          <template slot-scope="scope">
            <div class="meetingTypeBox">
              {{ scope.row.supplementRoleName }}
              <i class="el-icon-edit-outline cursor-point font-size-16" :class="!scope.row.status?'disabled':''" @click="editMeetingType(scope.row)" :disabled="!scope.row.status"></i>
              <!-- <el-button class="m-btn" size="small" type="primary" @click="editMeetingType(scope.row)" :disabled="!scope.row.status">编辑</el-button> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="preOrderLevelCodes" align="center" label="适用价值等级">
          <template slot-scope="scope">
            <div class="meetingTypeBox">
              {{ scope.row.preOrderLevelName }}
              <i class="el-icon-edit-outline cursor-point font-size-16" :class="!scope.row.status?'disabled':''" @click="scope.row.status?showAddRuleDialog(scope.$index,scope.row,'edit'):''" :disabled="!scope.row.status"></i>
              <!-- <el-select v-model="scope.row.preOrderLevelCodesList" multiple placeholder="请选择" :disabled="!scope.row.status" size="small" class="inline mlr-5">
                <el-option v-for="(o, i) in levelList" :key="i" :label="o.value" :value="o.code" :disabled="o.disabled"> </el-option>
              </el-select> -->
              <!-- <div class="c-theme cursor-point mt-10" v-if="scope.row.canAdd" @click="addRule(scope.$index,scope.row)">+ 添加规则</div> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="taskTriggerRule" label="任务触发规则">
          <template slot-scope="scope">
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE'">
              被分配对应价值的销售机会未明确宴会档期
            </div>
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'">
              1. 被分配对应价值的销售机会时自动分派2. 分配跟进任务结束后，仍未签约/未输单的，每
              <el-select v-model="scope.row.taskTriggerRule.dayNum" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in 5" :key="i" :label="i + 2" :value="i + 2"> </el-option>
              </el-select>
              天自动分派
              <el-popover placement="top-start" width="200" trigger="hover" content="比如，如果设置的是2天，1月20日完成的分配跟进任务，但仍未签约或输单的销售机会，系统将会在1月22日0:00为其销售跟进人分派一个跟进任务">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </div>
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'">
              1. 被分配对应价值的销售机会时自动分派2. 分配跟进任务结束后，仍未签约/未输单的，每
              <el-select v-model="scope.row.taskTriggerRule.dayNum" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in 5" :key="i" :label="i + 2" :value="i + 2"> </el-option>
              </el-select>
              天自动分派
              <el-popover placement="top-start" width="200" trigger="hover" content="比如，如果设置的是2天，1月20日完成的分配跟进任务，但仍未签约或输单的销售机会，系统将会在1月22日0:00为其销售跟进人分派一个跟进任务">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="taskInvalidationRule" align="center" label="任务完成规则">
          <template slot-scope="scope">
            <div
              class="text-left"
              v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN' || scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'"
            >
              每
              <el-select v-model="scope.row.taskCompletionRule.dayNum" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in Number(scope.row.taskTriggerRule.dayNum)-2==0?1:Number(scope.row.taskTriggerRule.dayNum)-2" :key="i" :label="i + 2" :value="i + 2"> </el-option>
              </el-select>
              天
              <el-select v-model="scope.row.taskCompletionRule.detailedTime" placeholder="" :disabled="!scope.row.status" size="small" class="inline2 mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in timeList" :key="i" :label="o" :value="o"> </el-option>
              </el-select>
              前对销售机会或客户进行一次跟进
              <el-popover v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'" placement="top-start" width="200" trigger="hover" content="比如，如果设置的是每2天21:00，系统在1月22日0:00分配的任务，要求完成时间会是1月23日的21:00">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
              <el-popover v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'" placement="top-start" width="200" trigger="hover" content="比如，如果设置的是每2天21:00，系统在1月22日0:00分配的任务，要求完成时间会是1月23日的21:00">
                <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
              </el-popover>
            </div>
            <div class="text-left" v-else>
              被分配的第
              <el-select v-model="scope.row.taskCompletionRule.dayNum" placeholder="" :disabled="!scope.row.status" size="small" class="inline mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in 5" :key="i" :label="i + 2" :value="i + 2"> </el-option>
              </el-select>
              天
              <el-select v-model="scope.row.taskCompletionRule.detailedTime"  placeholder="" :disabled="!scope.row.status" size="small" class="inline2 mlr-5" @change="updateHotelConfig(2)">
                <el-option v-for="(o, i) in timeList" :key="i" :label="o" :value="o"> </el-option>
              </el-select>
              <span v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_AFTER_DISTRIBUTION'">前对销售机会或客户进行一次跟进</span>
              <span v-else-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE'">前将宴会的明确档期输入系统</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="任务提醒">
          <template slot-scope="scope">
            <el-select v-model="scope.row.reminderTimeType" :disabled="!scope.row.status" size="small" @change="updateHotelConfig(2)">
              <el-option v-for="(o, i) in noticeList" :key="i" :label="o.v" :value="o.seq"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="任务失效规则">
          <template slot-scope="scope">
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE'">
              销售机会输单
            </div>
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'">
              销售机会签约或输单
            </div>
            <div v-if="scope.row.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'">
              销售机会签约或输单
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="setting" align="center" label="操作">
          <template slot-scope="scope">
            <div style="display:inline-block">
              <el-tooltip v-if="!scope.row.isAdd" placement="right" :disabled="!scope.row.cantDel">
                <div slot="content">每条任务必须保留一条规则</div>
                <div class="c-theme cursor-point" :class="scope.row.cantDel || !scope.row.status?'disabled':''" @click="(!scope.row.cantDel&&scope.row.status)?deleteRule(scope.row):''" >删除</div>
              </el-tooltip>
              <el-tooltip placement="right" v-else :disabled="!scope.row.cantAdd || !scope.row.cantAdd">
                <div slot="content">规则已包括所有价值等级<br/>取消勾选后方可新增规则</div>
                <div class="c-theme cursor-point" :class="scope.row.cantAdd || !scope.row.status?'disabled':''" @click="(!scope.row.cantAdd&&scope.row.status)?showAddRuleDialog(scope.$index,scope.row,'add'):''">+ 添加规则</div>
              </el-tooltip>
              <!-- <div v-if="!scope.row.isAdd" class="c-theme cursor-point" :class="scope.row.cantDel || !scope.row.status?'disabled':''" @click="(!scope.row.cantDel&&scope.row.status)?deleteRule(scope.row):''" >删除</div>
              <el-tooltip placement="right" v-else :disabled="!scope.row.cantAdd">
                <div slot="content">规则已包括所有价值等级<br/>取消勾选后方可新增规则</div>
                <div class="c-theme cursor-point" :class="scope.row.cantAdd || !scope.row.status?'disabled':''" @click="(!scope.row.cantAdd&&scope.row.status)?showAddRuleDialog(scope.$index,scope.row,'add'):''">+ 添加规则</div>
              </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="text-center">
        <el-button class="mt-10" size="small" type="primary" @click="updateHotelConfig(2)">保存</el-button>
      </div> -->
      
    </div>
    <div class="font-size-14 mt-20 s-title season2">销售机会关联跟进规则<span class="font-size-13 c-1 ml-10">设置销售机会阶段任务及流转过程是否关联跟进</span></div>
    <el-table
      border header-row-class-name="table-header" 
      :data="preOrderFollowFlagList"
      style="width: 100%">
      <el-table-column label="销售机会过程节点" width="300px" align="center">
        <template slot-scope="scope">
          {{configMap[scope.row.code]}}
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>是否必须关联跟进</span>
          <!-- <el-popover placement="top-start" width="200" trigger="hover" content="若开启，销售机会阶段过程必须关联跟进记录；若关闭，则无需关联跟进">
            <i slot="reference" class="el-icon-question c-6 font-size-16 ml-5 mr-5"></i>
          </el-popover> -->
        </template>
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.value" @change="updatePreOrderFollowConfig(scope.row)">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
    </el-table>
    <div class="font-size-14 mt-20 s-title season3">签约规则<span class="font-size-13 c-1 ml-10">设置销售机会签约/新增订单是否必选担保人</span></div>
    <div class="item mt-0">
      <!-- <div class="mt-10">
        定金的收款项目设置为
        <el-select class="w-120 inline mlr-5" v-if="orderDeposit[0]" v-model="orderDeposit[0].value" @change="updateHotelConfig(3,orderDeposit[0])" placeholder="" size="small">
          <el-option v-for="(o, i) in collectionItemsList" :key="i" :label="o.value" :value="o.code"> </el-option>
        </el-select>
        月以上签约即为长单（包括刚好6个月）。
      </div>
      <div class="mt-10 flex flex-align-center">
        定金金额要求建议不低于合同签约金额的
        <el-input class="w-80 ml-10" v-if="orderDeposit[1]" @change="updateHotelConfig(3,orderDeposit[1])" v-model="orderDeposit[1].value" size="small">
          <i slot="suffix" style="margin-right: 10px;">%</i>
        </el-input>
      </div> -->
      <div class="mt-10">
        <!-- <div class="font-bold mb-10">无收款或收款不足时，是否选择担保人确认才能预留</div> -->
        <el-radio v-if="orderDeposit[2]" @change="updateHotelConfig(3,orderDeposit[2])" v-model="orderDeposit[2].configStatus" :label="true">必须选择担保人</el-radio>
        <el-radio v-if="orderDeposit[2]" @change="updateHotelConfig(3,orderDeposit[2])" v-model="orderDeposit[2].configStatus" :label="false">可不选择担保人预留</el-radio>
      </div>
    </div>
    <div class="font-size-14 mt-20 s-title season4">预留厅位规则<span> </span></div>
    <div class="item">
      <div class="flex flex-align-center">
        <span class="mr-10">启用</span>
        <el-switch v-if="orderRoom[0]" v-model="orderRoom[0].configStatus" @change="updateHotelConfig(4,orderRoom[0])"> </el-switch>
      </div>
      <div class="mt-10">
        最长可预留
        <el-select v-if="orderRoom[1]" v-model="orderRoom[1].value" :disabled="!orderRoom[0].configStatus" @change="updateHotelConfig(4,orderRoom[1])" placeholder="" size="small" class="inline mlr-5">
          <el-option v-for="(o, i) in 7" :key="i" :label="i + 1" :value="i + 1"> </el-option>
        </el-select>
        天，过期后自动取消预留。
      </div>
      <div class="mt-10">
        如有收款，收款项目设置为
        <el-select class="w-120 inline mlr-5" v-if="orderRoom[2]" v-model="orderRoom[2].value" :disabled="!orderRoom[0].configStatus" @change="updateHotelConfig(4,orderRoom[2])" placeholder="" size="small">
          <el-option v-for="(o, i) in collectionItemsList" :key="i" :label="o.value" :value="o.code"> </el-option>
        </el-select>
      </div>
      <div class="mt-10">
        收款金额需至少
        <el-input class="w-120 ml-10" v-if="orderRoom[3]" v-model="orderRoom[3].value" :disabled="!orderRoom[0].configStatus" @change="updateHotelConfig(4,orderRoom[3])" size="small">
          <i slot="suffix" style="margin-right: 10px;">元</i>
        </el-input>
      </div>
      <div class="mt-10">
        <div class="font-bold mb-10">无收款或收款不足时，是否选择担保人确认才能预留</div>
        <el-radio v-if="orderRoom[4]" @change="updateHotelConfig(4,orderRoom[4])" v-model="orderRoom[4].configStatus" :disabled="!orderRoom[0].configStatus" :label="true">必须选择担保人</el-radio>
        <el-radio v-if="orderRoom[4]" @change="updateHotelConfig(4,orderRoom[4])" v-model="orderRoom[4].configStatus" :disabled="!orderRoom[0].configStatus" :label="false">可不选择担保人预留</el-radio>
      </div>
      
    </div>
    <el-dialog title="选择适用该任务的宴会类型" :visible.sync="dialogVisible" width="630px">
      <span>对于未选择的宴会类型系统将不根据规则自动分派相应的任务</span>
      <div class="checkbox mt-20">
        <el-checkbox v-for="(o, i) in meetingTypeList" :key="i" :label="o.meetingTypeName" v-model="o.flag"></el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择适用该规则的价值等级" :visible.sync="addDialogVisible" width="630px">
      <div>对于未选择的价值等级系统将不根据规则自动分派相应的任务</div>
      <div class="checkbox mt-20">
        <el-checkbox v-for="(o, i) in addLevelList" :key="i" :label="o.value" v-model="o.checked" :disabled="o.disabled"></el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addRule" :disabled="addLevelList.filter(o => {return o.checked}).length == 0">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { 
  get_preOrderTaskConfig,
  get_hotelConfigInitConfigTypeList, 
  post_updateHotelConfigInitConfigType, 
  post_updatePreOrderTaskConfig, 
  get_getMeetingTypeInitList, 
  get_getCollectionItemInitList,
  get_getPreOrderLevelInitList,
  post_deletePreOrderTaskConfig,
  post_savePreOrderTaskConfig
} from '@/api/base'
import { get_key_value } from '@/api/common'

const form = {
  preOrderLevelCodes: '',
  preOrderLevelCodesList: [],
  reminderTimeType: 1,
  status: true,
  supplementRole: '',
  taskConfigType: '',
  ruleDescription: '',
  taskConfigGroup: 'PRE_ORDER_TASK',
  taskContent: {
    dayNum: '',
    detailedTime: '',
    timeRole: null
  },
  taskTriggerRule: {
    dayNum: '',
    detailedTime: null,
    timeRole: null
  },
  taskCompletionRule: {
    dayNum: 2,
    detailedTime: '21:00',
    timeRole: null
  },
  rowspan: 0,
  isAdd: true
}
export default {
  name: '',
  data() {
    return {
      salesOpportunitiesLoading: true,
      singlePeriod: [],
      tableData: [],
      meetingTypeList: [],
      checked: false,
      dialogVisible: false,
      selectInfo: {},
      timeNow: '',
      timeList: ['17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30'],
      noticeList: '',
      collectionItemsList: [],
      orderDepositData: [],
      orderRoomData: [],
      checkedAll: false,
      levelList: [],
      addDialogVisible: false,
      addLevelList: [],
      addData: {},
      selectRule: {},
      preOrderFollowFlagList: [],
      configMap: {
        'PRE_ORDER_COMPLETE_TASK_FOLLOW_FLAG': '完成销售机会任务',
        'PRE_ORDER_SIGN_FOLLOW_FLAG': '销售机会签约'
      }
    }
  },
  computed: {
    orderDeposit() {
      let _data = []
      this.orderDepositData.map(o => {
        if (o.code === 'PRE_ORDER_DEPOSIT_TYPE') {
          _data[0] = o
        } else if (o.code === 'PRE_ORDER_SUGGEST_DEPOSIT_PROPORTION') {
          _data[1] = o
        } else if (o.code === 'PRE_ORDER_SIGN_GUARANTEE') {
          _data[2] = o
        }
      })
      return _data
    },
    orderRoom() {
      let _data = []
      this.orderRoomData.map(o => {
        if (o.code === 'PRE_ORDER_RESERVE_ROOM_ROLE') {
          _data[0] = o
        } else if (o.code === 'PRE_ORDER_RESERVE_ROOM_MAX_DAY') {
          _data[1] = o
        } else if (o.code === 'PRE_ORDER_RESERVE_ROOM_DEPOSIT_TYPE') {
          _data[2] = o
        } else if (o.code === 'PRE_ORDER_RESERVE_ROOM_DEPOSIT_MIN') {
          _data[3] = o
        } else if (o.code === 'PRE_ORDER_RESERVE_ROOM_GUARANTEE') {
          _data[4] = o
        }
      })
      return _data
    }
  },
  watch: {
    // tableData: {
    //   handler(val) {
    //     val.map(o => {
          
    //     })
    //     this.timeNow = new Date().valueOf()
    //   },
    //   // 开启深度监听：只要obj中的任何一个属性发生改变，都会触发相应的代码
    //   deep: true
    // },
  },
  methods: {
    handelInit() {
      this.getKeyValue()
      this.getOrderDeposit()
      this.getOrderRoom()
      this.getHotelConfigList()
      this.getPreOrderFollowFlagList()
      this.getPreOrderLevelInitList()
      this.getCollectionItemInitList()
      this.getAllMeetingTypeList()
    },
    // 获取宴会类型列表
    getAllMeetingTypeList() {
      get_getMeetingTypeInitList({}).then(res => {
        console.log(res,'meetingTypeList')
        this.meetingTypeList = res.filter(o => o.flag)
        this.getPreOrderTaskConfig()
      })
    },
     //获收款方式列表
    getCollectionItemInitList() {
      get_getCollectionItemInitList({}).then(res => {
        this.collectionItemsList = res
      })
    },
    //获取提醒列表
    getKeyValue() {
      get_key_value({
        type: 'REMINDER_TIME_TYPE'
      }).then(res => {
        this.noticeList = res.records
      })
    },
    //获取销售机会签约规则
    getOrderDeposit() {
      get_hotelConfigInitConfigTypeList({
        configKList: 'PRE_ORDER_DEPOSIT_TYPE,PRE_ORDER_SUGGEST_DEPOSIT_PROPORTION,PRE_ORDER_SIGN_GUARANTEE'
      }).then(res => {
        this.orderDepositData = res
        console.log(res,11)
      })
    },
    //获取预留宴会厅规则
    getOrderRoom() {
      get_hotelConfigInitConfigTypeList({
        configKList:
          'PRE_ORDER_RESERVE_ROOM_ROLE,PRE_ORDER_RESERVE_ROOM_MAX_DAY,PRE_ORDER_RESERVE_ROOM_DEPOSIT_TYPE,PRE_ORDER_RESERVE_ROOM_DEPOSIT_MIN,PRE_ORDER_RESERVE_ROOM_GUARANTEE'
      }).then(res => {
        this.orderRoomData = res
        // console.log(res,11)
      })
    },
    //获取单期类型划分规则
    getHotelConfigList() {
      get_hotelConfigInitConfigTypeList({
        configKList: 'PRE_ORDER_DATE_TYPE,PRE_ORDER_DATE_TYPE_LONG,PRE_ORDER_DATE_TYPE_MID_START,PRE_ORDER_DATE_TYPE_MID_END,PRE_ORDER_DATE_TYPE_SHORT'
      }).then(res => {
        this.singlePeriodData = res
        this.salesOpportunitiesLoading = false
      })
    },
    //获取单期类型划分规则
    getPreOrderFollowFlagList() {
      get_hotelConfigInitConfigTypeList({
        configKList: 'PRE_ORDER_COMPLETE_TASK_FOLLOW_FLAG,PRE_ORDER_SIGN_FOLLOW_FLAG'
      }).then(res => {
        this.preOrderFollowFlagList = res
      })
    },
    //获取价值等级列表
    getPreOrderLevelInitList() {
      get_getPreOrderLevelInitList({
        needNoneFlag: true
      }).then((res) => {
        res.map(o => {o.checked = false})
        this.levelList = res.filter(o => {return o.flag})
      });
    },
    //修改规则
    updateHotelConfig(type,val) {
      if (type === 1 || type === 3 || type === 4 || type === 5) {
        post_updateHotelConfigInitConfigType({
          ...val
        }).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
        })
      } else if (type === 2) {
        let _list = this.tableData.filter(o => {return o.id})
        _list.map(o => {
          o.preOrderLevelCodes = o.preOrderLevelCodesList.join()
        })
        post_updatePreOrderTaskConfig({
          hotelId: 0,
          taskConfigList: _list
        }).then(() => {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getPreOrderTaskConfig()
        })
      }
    },
    editMeetingType(val) {
      // console.log(val)
      this.selectInfo = val
      if (val.supplementRoleList.length > 0) {
        this.meetingTypeList.map(o => {
          o.flag = false
          val.supplementRole.split(',').map(p => {
            if (o.code === p) {
              o.flag = true
            }
          })
        })
      }
      // console.log(this.meetingTypeList,11)
      this.dialogVisible = true
    },
    confirm() {
      let _data = []
      this.meetingTypeList.map(o => {
        if (o.flag) {
          _data.push(o.code)
        }
      })
      this.tableData.map(o => {
        if (o.id === this.selectInfo.id) {
          o.supplementRole = _data.join()
        }
      })
      this.dialogVisible = false
      this.updateHotelConfig(2)
    },
    //获取跟进任务规则
    getPreOrderTaskConfig() {
      // this.salesOpportunitiesLoading = true
      get_preOrderTaskConfig({
        hotelId: 0
      }).then(res => {
        // console.log(res,11)
        if(res.PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE.length == 1) res.PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE[0].cantDel = true 
        if(res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER.length == 1) res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER[0].cantDel = true 
        if(res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN.length == 1) res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN[0].cantDel = true 
        let _list = this.$deepClone(res.PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE.concat(res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER).concat(res.PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN))
        _list.map(o => {
          o.rowspan = 1;
          o.supplementRoleName = []
          this.meetingTypeList.map(p => {
            o.supplementRoleList = o.supplementRole.split(',')
            o.supplementRoleList.map(q => {
              if (q === p.code) {
                o.supplementRoleName.push(p.meetingTypeName)
              }
            })
          })
          o.supplementRoleName = o.supplementRoleName.join()
          o.content = o.taskContent.content
           o.preOrderLevelNameList = []
          this.levelList.map(p => {
            o.preOrderLevelCodesList = o.preOrderLevelCodes.split(',')
            o.preOrderLevelCodesList.map(q => {
              if (q === p.code) {
                o.preOrderLevelNameList.push(p.value)
              }
            })
          })
          o.preOrderLevelName = o.preOrderLevelNameList.join()
        })
        let _data1 = _list.filter(o => {return o.taskConfigType == 'PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE'})
        let _form1 = this.$deepClone(form)
        let _len1 = []
        _form1.status = _data1[0].status
        _form1.content = _data1[0].content
        _form1.supplementRoleName = _data1[0].supplementRoleName
        _form1.taskConfigType = 'PRE_ORDER_FOLLOW_TASK_CLEAR_MEETING_DATE'
        _data1.map(o => {
          _len1 = _len1.concat(o.preOrderLevelCodesList)
        })
        if(_len1.length >= this.levelList.length) {
          _form1.cantAdd = true
        }
        _data1.push(_form1)
        let _data2 = _list.filter(o => {return o.taskConfigType == 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'})
        let _form2 = this.$deepClone(form)
        let _len2 = []
        _form2.status = _data2[0].status
        _form2.content = _data2[0].content
        _form2.supplementRoleName = _data2[0].supplementRoleName
        _form2.taskConfigType = 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER'
        _data2.map(o => {
          _len2 = _len2.concat(o.preOrderLevelCodesList)
        })
        if(_len2.length >= this.levelList.length) {
          _form2.cantAdd = true
        }
        _data2.push(_form2)
        let _data3 = _list.filter(o => {return o.taskConfigType == 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'})
        let _form3 = this.$deepClone(form)
        let _len3 = []
        _form3.status = _data3[0].status
        _form3.content = _data3[0].content
        _form3.supplementRoleName = _data3[0].supplementRoleName
        _form3.taskConfigType = 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN'
        _data3.map(o => {
          _len3 = _len3.concat(o.preOrderLevelCodesList)
        })
        if(_len3.length >= this.levelList.length) {
          _form3.cantAdd = true
        }
        _data3.push(_form3)
        _data1.map((o,i) => {
          if(i === 0) {
            o.rowspan = _data1.length
          } else {
            o.rowspan = 0
          }
        })
        _data2.map((o,i) => {
          if(i === 0) {
            o.rowspan = _data2.length
          } else {
            o.rowspan = 0
          }
        })
        _data3.map((o,i) => {
          if(i === 0) {
            o.rowspan = _data3.length
          } else {
            o.rowspan = 0
          }
        })
        let _arr = _data1.concat(_data2).concat(_data3)
        this.tableData = _arr
        console.log(this.tableData,'this.tableData')
        // this.salesOpportunitiesLoading = false
      })
    },
    changeTime1(val) {
      this.tableData.map(o => {
        if(o.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN' || o.taskConfigType === 'PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER') {
          o.taskContent.timeConfig = val
        }
      })
      this.timeNow = new Date().valueOf()
      this.updateHotelConfig(2)
    },
    spanMethod({ column, row, columnIndex }) {
      // console.log(column, row, columnIndex,'column, row, columnIndex')
      const spanArr = ['status', 'content', 'supplementRoleName' ]
      if(spanArr.includes(column.property) || columnIndex == 0) {
        return {
          rowspan: row.rowspan,
          colspan: 1
        };
      }
      if(row.isAdd) {
        if(column.property == 'setting') {
          return {
            rowspan: 1,
            colspan: 6
          }
        } else {
          return {
            rowspan: 1,
            colspan: 0
          }
        }
        
      }
    },
    //添加规则弹窗
    showAddRuleDialog(index,val,type) {
      console.log(index,val)
      this.selectRule = val
      if(type == 'add') {
        this.addLevelList = []
        this.addData = this.$deepClone(form)
        this.addData.taskConfigType = val.taskConfigType
        if(val.taskConfigType == "PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_OVER" || val.taskConfigType == "PRE_ORDER_FOLLOW_TASK_NOT_CLOSE_WITHIN") {
          this.addData.taskTriggerRule.dayNum = 2
        }
        let _list = this.$deepClone(this.levelList)
        let _arr = this.tableData.filter(o => {return o.taskConfigType == val.taskConfigType})
        let _list2 = []
        _arr.map(o => {
          _list2 = _list2.concat(o.preOrderLevelCodesList)
        })
        _list.map(o => {
          o.checked = false
          _list2.map(p => {
            if(p == o.code) {
              o.disabled = true
            }
          })
        })
        this.addLevelList = _list
        this.addDialogVisible = true
      } else if (type == 'edit') {
        this.addLevelList = []
        let _list = this.$deepClone(this.levelList)
        let _arr = this.tableData.filter(o => {return o.taskConfigType == val.taskConfigType})
        let _list2 = []
        _arr.map(o => {
          _list2 = _list2.concat(o.preOrderLevelCodesList)
        })
        _list.map(o => {
          o.disabled = false
          _list2.map(p => {
            if(p == o.code) {
              o.disabled = true
            }
          })
        })
        _list.map(o => {
          o.checked = false
          val.preOrderLevelCodesList.map(p => {
            if(o.code == p) {
              o.disabled = false
              o.checked = true
            }
          })
        })
        this.addLevelList = _list
        this.addDialogVisible = true
      }
    },
    //添加规则
    addRule() {
      // console.log( this.addLevelList,' this.addLevelList')
      if(!this.selectRule.id) { //添加
        let _list = []
        this.addLevelList.map(o => {
          if(o.checked) {
            _list.push(o.code)
          }
        })
        this.addData.preOrderLevelCodes = _list.join()
        // console.log(this.addData)
        post_savePreOrderTaskConfig({
          hotelId: 0,
          taskConfigList: [this.addData]
        }).then(() => {
          this.addDialogVisible = false
          this.$message.success('添加成功')
          this.getPreOrderTaskConfig()
        })
      } else if (this.selectRule.id) { //编辑
        let _list = []
        this.addLevelList.map(o => {
          if(o.checked) {
            _list.push(o.code)
          }
        })
        this.selectRule.preOrderLevelCodesList = _list
        this.updateHotelConfig(2)
        this.addDialogVisible = false
      }
      
    },
    deleteRule(val) {
      this.$confirm('删除后，该任务规则将不再显示，请确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'dialog-class',
        center: true
      }).then(() => {
        post_deletePreOrderTaskConfig({
          hotelId: 0,
          id: val.id
        }).then(() => {
          this.$message.success('删除成功')
          this.getPreOrderTaskConfig()
        })
      })
    },
    changeStatus(val) {
      this.tableData.map(o => {
        if(o.taskConfigType == val.taskConfigType) {
          o.status = val.status
        }
      })
      this.updateHotelConfig(2)
      // console.log(val)
    },
    updatePreOrderFollowConfig(val) {
      this.updateHotelConfig(5, val)
    },
  },
}
</script>

<style scoped lang="scss">
.salesOpportunities {
  .item {
    // padding: 24px;
    margin-top: 20px;
    .inline {
      display: inline-block;
      width: 70px;
    }
    .inline2 {
      display: inline-block;
      width: 80px;
    }
  }
}
.c-1 {
  color: #a4a4a4;
}
::v-deep .el-dialog {
  .el-dialog__footer {
    box-shadow: unset;
  }
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
.w-80 {
  width: 80px;
}
.w-120 {
  width: 120px !important;
}
.s-title {
  padding: 15px 15px 15px 25px;
  background: #F9F9F9;
  display: flex;
  align-items: center;
  position: relative;
  ::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #aaaaaa;
    border-radius: 6px;
    // display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.miniMenu {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #409EFF;
  cursor: pointer;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;
  i {
    color: white;
  }
}
.menu {
  position: fixed;
  right: 40px;
  bottom: 40px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 15px 0;
  z-index: 999;
  div {
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
      color: #409EFF;
      background: #F2F6FC;
    }
  }
  .scrollActive {
    color: #409EFF;
    background: #F2F6FC;
  }
}
.c-theme {
  color: #409EFF;
}
.disabled {
  opacity: 0.4;
}
</style>
